import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { BsDownload, BsPaperclip, BsChevronDown, BsChevronUp } from 'react-icons/bs';
import styles from './Conversations.module.css';

const ConversationAttachments = ({ attachments }) => {
	const [showAttachments, setShowAttachments] = useState(false);
	return (
		attachments.length > 0 &&
		<>
			<Button variant='link' className={styles.attachmentsLink} onClick={() => { setShowAttachments(!showAttachments) }}>
				<BsPaperclip />{attachments.length} Attachment{attachments.length > 1 ? 's' : ''}  {showAttachments ? <BsChevronUp /> : <BsChevronDown />}
			</Button>
			{showAttachments &&
				<div className={styles.attachments}>
					{attachments.map((attachment) => (
						<a className={styles.attachment} key={attachment.id} title={attachment.name}  href={attachment.public_url} target="_blank" rel="noopener noreferrer">
							{
								attachment.mime_type.includes('image/') ?
									(<img src={attachment.public_url} alt={attachment.name} style={{ maxWidth: 100, maxHeight: 100 }} />) :
									(<div className={styles.file}><BsDownload />{attachment.name}<span>{attachment.size}</span></div>)
							}
						</a>
					))}
				</div>
			}
		</>
	)
}

export default ConversationAttachments
