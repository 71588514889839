import React, { useState, useRef } from 'react'
import { Button, Form, Modal, Alert, Spinner } from 'react-bootstrap';
import { BsTrash3, BsPencilSquare } from 'react-icons/bs';

import styles from './Comment.module.css';
import CustomEditor from '../../../Editor';
import Attachments from '../../../Attachments';
import { showDeleteConfirm } from '../../../ItemDelete'
import { baseURL } from "../../../../helpers/baseURL";
import { headers } from '../../../../helpers/Requests';
import successAlert from "../../../../helpers/successAlert";
import { useSelector } from 'react-redux';

const NoteActions = ({ note, onChange }) => {
	const editor = useRef('');
	const [showModal, setShowModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isInvalid, setIsInvalid] = useState(false);
	const [attachments, setAttachments] = useState(note?.attachments ?? []);

    const userSelector = useSelector(state => state?.userReducer?.user);

	const closeModal = () => {
		setIsLoading(false);
		setShowModal(false);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (editor.current.getText().length === 0) {
			setIsInvalid(true);
			return
		}
		setIsInvalid(false);
		setIsLoading(true);
		setErrorMessage('');
		const data = {
			type: 'note',
			content: editor?.current?.getHTML(),
			attachments: attachments,
		};
		try {
			const response = await axios.patch(`${baseURL}comment-update/${note.id}`, data, headers);
            if (response.data.success){
                successAlert(response?.data?.message ?? 'Note saved successfully!');
                onChange();
                closeModal();
            }else{
                setErrorMessage(response?.data?.message ?? 'Failed to save note!');
            }
		} catch (error) {
			setErrorMessage(error?.response?.data?.message ?? 'Failed to save note!');
		}
	}

	const handleDelete = () => {
		showDeleteConfirm(note, 'note', 'comment', () => { }, () => {
			onChange();
			closeModal();
		})
	};


	return (
		<>
			<div className={styles.noteActions}>
			    {userSelector?.id == note?.user?.id && <Button variant="link" onClick={() => setShowModal(true)}><BsPencilSquare /></Button>}
                {(userSelector?.id == note?.user?.id || userSelector?.isAdmin) && <Button variant="link" onClick={handleDelete}><BsTrash3 color='#dc3545' /></Button>}
			</div>
			<Form onSubmit={handleSubmit}>
				<Modal show={showModal} onHide={closeModal}>
					<Modal.Header closeButton>
						<Modal.Title>Edit Note</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
						<CustomEditor defaultValue={note?.content} isInvalid={isInvalid} id={'editor-edit-note'} placeholder="Please add your note here" ref={editor} name="content" />
					</Modal.Body>
					<Modal.Footer>
						<div className='mr-auto'>
							<Attachments attachments={attachments} setAttachments={setAttachments} showAll={false} />
						</div>
						<Button variant="secondary" onClick={closeModal} disabled={isLoading}>
							Cancel
						</Button>
						<Button variant="primary" type="submit" disabled={isLoading} onClick={handleSubmit}>
							{isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Save'}
						</Button>
					</Modal.Footer>
				</Modal>
			</Form>
		</>
	)
}

export default NoteActions
