
import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";
import serializeForm from 'form-serialize'
import { Col, Container, Row } from "react-bootstrap";
import { ModalContext, ModalProvider, ModalRoot } from "react-multi-modal";
import { baseURL } from "../../../helpers/baseURL";
import { fetchUser } from "../../../redux/actions";
import ProfileUpload from "./Profile-upload";
import ChangePassword from "../../../modals/ChangePasswordModal";
import successAlert from "../../../helpers/successAlert";
import failAlert from "../../../helpers/failAlert";

const ProfileForm = ({ user }) => {
	const history = useHistory();
	const dispatch = useDispatch()
	const handleCancel = (e) => {
		if (history.length > 2) {
			history.goBack();
		} else {
			history.push('/dashboard');
		}
		e.preventDefault();
	};
	const changeInfo = (e) => {
		e.preventDefault();
		const values = serializeForm(e.target, { hash: true })
		axios.post(`${baseURL}profile/${user.id}`, values, {
			headers: {
				Authorization: `Bearer ${Cookies.get('access_token')}`
			}
		}).then(res => res.status === 200 ? dispatch(fetchUser()) : '').then(successAlert('Profile Settings changed')).catch(function (error) {
			if (error.response.status === 401) {
				localStorage.clear()
				window.location.href = '/login'
			}
		});
	}
	return (
		<div className="section-container">
			<Row className="account-settings-bt">
				<Col xs={12} lg={4} xl={3} className="mb-4 mb-md-0">
					<ProfileUpload user={user !== undefined ? user : ''} />
				</Col>
				<Col xs={12} lg={8} xl={9}>
					<div className="profile-bg-form">
						<form onSubmit={changeInfo}>
							<Row>
								<Col lg={12} xl={6} className="mb-4">
									<div className="personal-information-container">
										<label htmlFor="first_name">First Name</label>
										<input type="text" id="first_name" name="first_name" defaultValue={user === undefined ? '' : user.first_name} />
									</div>
								</Col>
								<Col lg={12} xl={6} className="mb-4">
									<div className="personal-information-container">
										<label htmlFor="last_name">Last Name</label>
										<input type="text" id="last_name" name="last_name" defaultValue={user === undefined ? '' : user.last_name} />
									</div>
								</Col>
							</Row>
							<Row>
								<Col lg={12} xl={6} className="mb-4">
									<div className="personal-information-container">
										<label htmlFor="email">Email Address</label>
										<input type="email" id="email" name="email" defaultValue={user === undefined ? '' : user.email} />
									</div>
								</Col>
								<Col lg={12} xl={6} className="mb-4">
									<div className="personal-information-container">
										<label htmlFor="phone_number">Phone Number</label>
										<input type="text" id="phone_number" name="phone_number" className="phone" maxLength="14" defaultValue={user === undefined ? '' : user.phone_number} />
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<div className="adm-prod">
										<button type="button" className="btn cancel-button" onClick={handleCancel}>Cancel</button>
										<button type="submit" className="btn save-button">Save</button>
									</div>
								</Col>
							</Row>
						</form >
						<hr />
						<Row>
							<Col sm={8} className="mb-2">
								<div className="account-settings-container">
									<h4>Update Your Password</h4>
									<p>Enhance Your Account Security</p>
								</div>
							</Col>
							<Col sm={4} className="d-flex align-items-end flex-column">
								<div className="changePassword-container">
									<ModalProvider>
										<ModalContext.Consumer>
											{({ showModal, hideModal }) => (
												<>
													<button className="btn btn-secondary" onClick={() => showModal({ component: ChangePassword })}>Change Password</button>
													<ModalRoot />
												</>
											)}
										</ModalContext.Consumer>
									</ModalProvider>
								</div>
							</Col>
						</Row>
						<hr />
						<Row>
							<Col sm={8} className="mb-2">
								<div className="account-settings-container">
									<h4>Download a copy of your data</h4>
									<p>Right to data Portability</p>
								</div>
							</Col>
							<Col sm={4} className="d-flex align-items-end flex-column">
								<button className="btn btn-secondary">Download Data</button>
							</Col>
						</Row>
						<hr />
						<Row>
							<Col sm={8} className="mb-2">
								<div className="account-settings-container">
									<h4>Delete my data permanently</h4>
									<p>Right to be Forgotten</p>
								</div>
							</Col>
							<Col sm={4} className="d-flex align-items-end flex-column">
								<button className="btn btn-danger">Delete Data</button>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</div>
	)
}
export default ProfileForm
