import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
import Cookies from 'js-cookie';


window._ = require('lodash');

try {
    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */


let pusherAppKey = process.env.MIX_PUSHER_APP_KEY;
let pusherCluster = process.env.MIX_PUSHER_APP_CLUSTER;

Pusher.logToConsole = process.env.MIX_PUSHER_APP_DEBUG;

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: pusherAppKey,
    wsHost: window.location.hostname,
    wsPort: 6001,
    wssPort: 6001,
    forceTLS: false,
    disableStats: true,
    cluster: pusherCluster,
    authEndpoint: '/api/broadcasting/auth',
    auth: {
        headers: {
            'Authorization': 'Bearer ' + Cookies.get('access_token'),
        },
    },
});

