export const uploadFileReducerLogo = (state = '', action) => {
    switch (action.type) {
        case 'GET_IMG_LOGO':
            return {
                ...state,
                file: action.payload,
                loading: action.loading ?? false
            }
        default:
            return state
    }
}

export const uploadFileReducerFavicon = (state = '', action) => {
    switch (action.type) {
        case 'GET_IMG_FAVICON':
            return {
                ...state,
                file: action.payload,
                loading: action.loading ?? false
            }
        default:
            return state
    }
}

