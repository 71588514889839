import React, { useState } from "react"
import axios from "axios";
import { baseURL } from "../../helpers/baseURL";
import { headers } from "../../helpers/Requests";
import { confirmAlert } from "react-confirm-alert";
import { Button, Badge } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FiAlertCircle, FiUser, FiLock, FiTrash2, FiLogIn, FiEdit, FiCheck } from "react-icons/fi";

const WorkSpaceRadio = ({ selected, onChange, onError, onRemove, editItem, id, name, plan, isAdmin, title, accepted, active }) => {
	const [isLoading, setIsLoading] = useState(false);

	const switchWorkspace = () => {
		setIsLoading(true);
		axios.get(`${baseURL}workspace-switch/${id}`, headers)
			.then(res => { onChange(id) })
			.catch(err => { onError(err.response?.data?.message || 'Failed to update workspace.') })
			.finally(() => setIsLoading(false));
	}

	const removeWorkspace = () => {
		setIsLoading(true);
		axios.delete(`${baseURL}workspace/${id}`, headers)
			.then(res => { onRemove() })
			.catch(err => { onError(err.response?.data?.message || 'Failed to remove workspace.') })
			.finally(() => setIsLoading(false));
	}

	const confirmSwitch = () => {
		popUpConfirm('Switch Workspace', `Are you sure you want to switch your workspace${!accepted ? " and accept the invitation" : ""}?`, switchWorkspace)
	}
	const confirmRemove = () => {
		popUpConfirm('Remove Workspace', `Are you sure you want to remove your workspace? You will not be able to recover it.`, removeWorkspace)
	}

	const popUpConfirm = (title, message, callback) => {
		if (id === selected || isLoading || !active) {
			return
		}
		confirmAlert({
			title: title,
			message: message,
			buttons: [
				{
					label: 'Yes',
					onClick: callback
				}, {
					label: 'No'
				}
			],
			overlayClassName: "confirm-overlay"
		})
	}

	const renderTooltip = (text) => (
		<Tooltip id="workspace-button-tooltip">{text}</Tooltip>
	);

	return (
		<div className={`workspace-item-container${id !== selected ? " unselected" : " selected"}`}>
			<div className={"workspace-item" + (active ? " active" : "") + (accepted ? " accepted" : "")}>
				<div className="workspace-name">{selected === id && <Badge className="text-success px-0 bg-transparent"><FiCheck /></Badge>} {name}</div>
				{ title && <div className="workspace-title">{title}</div> }
				{ plan && <div className="workspace-plan">{plan} Plan</div> }
				{ active ?
					<div className="workspace-actions">
						{selected !== id && <Button variant="light" onClick={confirmSwitch}><FiLogIn /></Button>}
						{isAdmin && <Button variant="primary" onClick={editItem}><FiEdit /></Button>}
						{selected !== id && isAdmin && <Button variant="danger" onClick={confirmRemove}><FiTrash2 /></Button>}
					</div> : ''
				}
				<div className="workspace-status">
					{isAdmin && <OverlayTrigger placement="left" overlay={renderTooltip("Admin Role")}><Badge className="text-success bg-transparent"><FiUser /></Badge></OverlayTrigger>}
					{!accepted && <OverlayTrigger placement="left" overlay={renderTooltip("Pending Invitation")}><Badge className="text-dark bg-transparent"><FiAlertCircle /></Badge></OverlayTrigger>}
					{!active && <OverlayTrigger placement="left" overlay={renderTooltip("Locked by Admin")}><Badge className="text-danger bg-transparent"><FiLock /></Badge></OverlayTrigger>}
				</div>
			</div>
		</div>
	)
}
export default WorkSpaceRadio