import React, { useState, useRef, useEffect } from "react"
import { Link, useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import arrow from "../../css/img/arrow-down-color.svg"
import axios from "axios";
import Cookies from "js-cookie";
import { baseURL } from "../helpers/baseURL";
import { confirmAlert } from 'react-confirm-alert';
import userImg from "../../css/img/d-user-icon.svg"
import billing from "../../css/img/d-billing-icon.svg"
import logout from "../../css/img/d-logout-icon.svg"


const MyDropdown = ({ user }) => {
	const [dropdown, setDropdown] = useState(false)
	const profileRef = useRef(null);
	const history = useHistory()

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [profileRef])

	const handleClickOutside = (event) => {
		if (profileRef && !profileRef?.current?.contains(event.target)) {
			setDropdown(false)
		}
	}

	const Logout = async () => {
		confirmAlert({
			title: 'Are you sure you want to logout?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => axios.post(`${baseURL}logout`, {}, {
						headers: {
							Authorization: `Bearer ${Cookies.get('access_token')}`
						}
					}).
						then(res => {
							Cookies.remove('access_token')
							Cookies.remove('mydev_token')
							localStorage.clear()
							history.push('/')
						})
						.catch(error => console.error(error))
				}, {
					label: 'No'
				}
			],
			overlayClassName: "confirm-overlay"
		})
	}

	const profileImg = user && user.profile_image ? <img src={`${user && user.profile_image}`} alt="" /> : <img src={"/images/avatar.png"} alt="" />;
	return (
		<Dropdown show={dropdown} ref={profileRef}>
			<Dropdown.Toggle id="dropdown-basic" className="dropdown-profile" onClick={() => setDropdown(!dropdown)}>
				<div className="profile-panel-header">
					<div className="profile-image-container">
						{profileImg}
					</div>
					<span className="userDataName">{user && user.name}</span>
					<span className="arrow-down"><img src={arrow} /></span>
				</div>
			</Dropdown.Toggle>
			<Dropdown.Menu className="profile-nav-dropdown">
				<div className="dropdown-item disabled user-image-name-d" href="#">
					<span className="user-image-left-d">
						{profileImg}
					</span>
					<div>
						<span className="user-name-adm">{user && user.name}</span>
						<span className="dropdown-item-email">{user && user.email}</span>
					</div>
				</div>
				<Link className="userData-dropdown-account" to="/profile" onClick={() => setDropdown(!dropdown)}>
					<img src={userImg} />
					<span>My Account</span>
				</Link>
				{
					user?.isAdmin &&
					<Link className="userData-dropdown-account" to="/subscription/plan" onClick={() => setDropdown(!dropdown)}>
						<img src={billing} />
						<span>My Subscription</span>
					</Link>
				}
				<div className="dropdown-divider"></div>
				<div className="userData-dropdown-logout pointer" onClick={() => Logout()}>
					<img src={logout} />
					<span>Logout</span>
				</div>
			</Dropdown.Menu>
		</Dropdown>
	)
}
export default MyDropdown
