import React, { useState, useMemo, } from 'react';
import { Table, Pagination, Spinner } from 'react-bootstrap';
import { FaCaretDown, FaCaretUp, FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import ColorBullet from './ColorBullet';

const TableView = ({ rows, columns, tableClass, isLoading }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage] = useState(10);
	const [sortConfig, setSortConfig] = useState({ by: null, direction: 'asc' });

	const sortedRows = useMemo(() => {
		let sortable = [...rows];
		if (sortConfig !== null) {
			sortable.sort((a, b) => {
				if (a[sortConfig.by] < b[sortConfig.by]) {
					return sortConfig.direction === 'asc' ? -1 : 1;
				}
				if (a[sortConfig.by] > b[sortConfig.by]) {
					return sortConfig.direction === 'asc' ? 1 : -1;
				}
				return 0;
			});
		}
		return sortable;
	}, [rows, sortConfig]);

	const indexOfLastRow = currentPage * rowsPerPage;
	const indexOfFirstRow = indexOfLastRow - rowsPerPage;
	const currentPageRows = sortedRows.slice(indexOfFirstRow, indexOfLastRow);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const requestSort = (by) => {
		let direction = 'asc';
		if (sortConfig.by === by && sortConfig.direction === 'asc') {
			direction = 'desc';
		}
		setSortConfig({ by, direction });
	};

	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(rows.length / rowsPerPage); i++) {
		pageNumbers.push(
			<Pagination.Item key={i} active={i === currentPage} onClick={() => paginate(i)} activeLabel="">
				{i}
			</Pagination.Item>
		);
	}

	const setSortIcon = (by) => {
		return sortConfig.by === by ? (sortConfig.direction === 'asc' ? <FaCaretUp /> : <FaCaretDown />) : null;
	}

	return (
		isLoading ? (
			<div className="labels-container-div justify-content-center">
				<Spinner as="span" animation="border" size="md" role="status" aria-hidden="true" />
			</div>
		) :
			currentPageRows.length === 0 ? (
				<div className="labels-container-div justify-content-center">No Items Found</div>
			) : (
				<Table className={tableClass} hover>
					<thead>
						<tr>
							{
								columns && columns.map((column, index) => {
									return column.key !== '__actions__' ? (
										<th key={index} className='cursor-pointer' onClick={() => requestSort(column.key)}>{column.label}{setSortIcon(column.key)}</th>
									) : (
										<th key={index}>{column.label}</th>
									)
								})
							}
						</tr>
					</thead>
					<tbody>
						{
							currentPageRows.map((row, row_index) => (
								<tr key={row_index}>
									{
										columns?.map((column, index) => {
											let content, className;
											if (column.key === '__actions__') {
												content = row[column.key]();
												className = 'actions-column';
											} else if (column.key === 'color') {
												content = <ColorBullet color={row[column.key]} />;
											} else if (column?.mode === 'badge') {
												content = <ColorBullet color={row[column.key]['color']} text={row[column.key]['name']} />;
											} else if (column?.mode === 'badges') {
												content = row[column.key].map(label => <ColorBullet color={label['color']} text={label['name']} />);
											} else if (column?.mode === 'check') {
												content = row[column.key]
													?
													<FaCheckCircle className='text-success' />
													:
													<FaExclamationCircle className='text-danger' />
											} else {
												content = row[column.key];
											}
											return (
												<td key={column.key + index} className={className}>
													{content}
												</td>
											);
										})
									}
								</tr>
							))
						}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan={columns.length}>
								<div className="d-flex justify-content-between">
									<Pagination>{pageNumbers}</Pagination>
									<span>Showing {currentPageRows.length} of {rows.length}</span>
								</div>
							</td>
						</tr>
					</tfoot>
				</Table>
			)
	)
}

export default TableView
