// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q\\+yNcAQRQYmHSytAdpUMyA\\=\\={background:#f8f9fa;border-radius:6px;box-shadow:0 1px 2px rgba(0,0,0,.1);display:inline-block;margin:0 0 4px 8px;padding:2px 4px 2px 8px}.Q\\+yNcAQRQYmHSytAdpUMyA\\=\\= button{font-size:12px;height:20px;margin-left:3px;padding:0;width:20px}._326NobEsvqhg2mDkg6gcOg\\=\\={align-items:flex-start!important}", "",{"version":3,"sources":["webpack://./resources/js/components/Attachments.module.css"],"names":[],"mappings":"AAAA,6BAEI,kBAAmB,CAEnB,iBAAkB,CAElB,mCAAuC,CALvC,oBAAqB,CAIrB,kBAAmB,CAFnB,uBAIJ,CAEA,oCAEI,cAAe,CAEf,WAAY,CACZ,eAAgB,CAJhB,SAAU,CAEV,UAGJ,CAEA,6BACI,gCACJ","sourcesContent":[".item {\r\n    display: inline-block;\r\n    background: #f8f9fa;\r\n    padding: 2px 4px 2px 8px;\r\n    border-radius: 6px;\r\n    margin: 0 0 4px 8px;\r\n    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);\r\n}\r\n\r\n.item button {\r\n    padding: 0;\r\n    font-size: 12px;\r\n    width: 20px;\r\n    height: 20px;\r\n    margin-left: 3px;\r\n}\r\n\r\n.container {\r\n    align-items: flex-start !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "Q+yNcAQRQYmHSytAdpUMyA==",
	"container": "_326NobEsvqhg2mDkg6gcOg=="
};
export default ___CSS_LOADER_EXPORT___;
