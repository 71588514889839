import React, { useEffect, useState } from "react"
import axios from "axios";
import Cookies from "js-cookie";
import { baseURL } from "../js/helpers/baseURL";
import { Link } from "react-router-dom";
import fb from "../main/images/fb.svg"
import ins from "../main/images/in.svg"
import tw from "../main/images/tw.png"
import linkedin from '../main/images/social-linkedin.svg'
import youtube from '../main/images/social-youtube.svg'
import medium from "../main/images/medium.svg"
import ClaritickAlert from "./alert";
import { Col, Row } from "react-bootstrap";

const Footer = () => {
    const SITE_KEY = process.env.MIX_APP_RECAPTCHA_V3_SITE_KEY;
    const [email, setEmail] = useState()
    const [message, setMessage] = useState()
    const [type, setType] = useState()
    const [title, setTitle] = useState()
    const [messagetext, setMessagetext] = useState()
    const [shown, setShown] = useState(0)

    const reCaptchaStyleP = {
        fontSize: 8,
        marginTop: 8
    }

    const reCaptchaStyleA = {
        backgroundColor: 'transparent',
        textDecoration: 'none',
        color: '#0087e2',
        padding: 0
    }

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
            // console.log("Script loaded!");
        });

    }, []);

    const SendMessage = e => {
        e.preventDefault();
        // setLoading(true);
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action: 'contact_us' }).then(token => {
                submitData(token);
            });
        });
    }

    const submitData = (token) => {
        axios.post(`${baseURL}contacts`,
            {
                "email": email,
                "message": message,
                "g-recaptcha-response": token
            },
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('access_token')}`
                }
            })
            .then((res) => {
                setMessagetext('Message sent succesfully')
                setTitle('Our staff will contact you as soon as possible')
                setType('success')
                setShown(1)
                setTimeout(() => {
                    setShown(0)
                    // setEmail('')
                    // setMessage('')
                }, 3000)
            })
            .catch((err) => {
                // console.log(err)
                // err.response.data.message
                var errorMessages = '';
                for (let key in err.response.data.errors) {
                    errorMessages += err.response.data.errors[key][0];
                }

                setTitle(err.response.data.message)
                setMessagetext(errorMessages)
                setType('error')
                setShown(1)
                setTimeout(() => {
                    setShown(0)
                }, 3000)
            })
    }

    return (
        <footer className="main-footer">
            <ClaritickAlert type={type} title={title} message={messagetext} shown={shown} setShown={setShown} />
            <div className="container">
                <Row className="justify-content-center">
                    <Col lg xs={6} className="footer-info">
                        <div>
                            <h4>Product</h4>
                            <Link to="/features"><p>Features</p></Link>
                            <Link to="/pricing"><p>Pricing</p></Link>
                            <Link to="/blog"><p>Blog</p></Link>
                        </div>
                    </Col>
                    <Col lg xs={6} className="footer-info">
                        <div>
                            <h4>Visit</h4>
                            <p>Kore.co LLC Inc.</p>
                            <p>161 N Clark St</p>
                            <p>16th Floor</p>
                            <p>Chicago, IL 60601</p>
                            <p>hello@claritick.com</p>
                            <p>(224) 279-1076</p>
                        </div>
                    </Col>

                    <Col lg xs={6} className="footer-info">
                        <div>
                            <h4>Legal</h4>
                            <p>
                                <a href="https://mydev.com/privacy" target="_blank">
                                    Privacy
                                </a>
                            </p>
                            <p>
                                <a href="https://mydev.com/terms" target="_blank">
                                    Terms
                                </a>
                            </p>
                            <p>
                                <a href="https://mydev.com/refund" target="_blank">
                                    Refunds
                                </a>
                            </p>
                        </div>
                    </Col>

                    <Col lg xs={6} className="footer-info">
                        <div>
                        <h4>Social Media</h4>
                        <p>
                            <a target="_blank" href="https://www.facebook.com/Claritick-105524795322767">
                                <img src={fb} />
                                Facebook
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="https://www.instagram.com/claritickofficial">
                                <img src={ins} />
                                Instagram
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="https://www.twitter.com/claritick">
                                <img src={tw} />
                                Twitter
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="https://claritick.medium.com/">
                                <img src={medium} />
                                Medium
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="https://www.linkedin.com/company/claritick">
                                <img src={linkedin} />
                                Linkedin
                            </a>
                        </p>
                        <p>
                            <a target="_blank" href="https://www.youtube.com/channel/UCHuoIDHYD5KBNopxUFsZT2w">
                                <img src={youtube} />
                                Youtube
                            </a>
                        </p>
                        </div>

                    </Col>
                    <Col lg xs={6} className="footer-info">
                        <div>
                            <h4>Contact</h4>
                            <p>Send us a message</p>
                            <form>
                                <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
                                <textarea placeholder="Message" onChange={(e) => setMessage(e.target.value)} value={message} />
                                <a type="button" onClick={(e) => SendMessage(e)}>Send</a>
                                <p style={reCaptchaStyleP}>
                                    This site is protected by reCAPTCHA and the Google
                                    <a style={reCaptchaStyleA} target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and

                                    <a style={reCaptchaStyleA} target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.
                                </p>
                            </form>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="footer-logo">

                <p>Claritick of Kore.co Company | © copyright <span>{new Date().getFullYear()}</span></p>
            </div>
        </footer>
    )
}
export default Footer
