import React, { forwardRef, useImperativeHandle, useState, useRef } from 'react';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import { EditorState } from 'draft-js';
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import {
	ItalicButton,
	BoldButton,
	UnderlineButton,
	UnorderedListButton,
	OrderedListButton,
	BlockquoteButton,
	CodeBlockButton,
} from '@draft-js-plugins/buttons';
import editorStyles from './Editor.module.css';

const CustomEditor = forwardRef((props, ref) => {
    const [editorState, setEditorState] = useState(() => {
        if (props.defaultValue) {
            const contentState = stateFromHTML(props.defaultValue);
            return EditorState.createWithContent(contentState);
        }
        return EditorState.createEmpty();
    });
	const editorRef = useRef();
	const [{ plugins, Toolbar }] = useState(() => {
		const toolbarPlugin = createToolbarPlugin();
		const { Toolbar } = toolbarPlugin;
		const plugins = [toolbarPlugin];
		return {
			plugins,
			Toolbar
		};
	});

	useImperativeHandle(ref, () => ({
		getText: () => {
			return editorState.getCurrentContent().getPlainText();
		},
		getHTML: () => {
			return stateToHTML(editorState.getCurrentContent())
		},
		reset: () => {
			setEditorState(createEditorStateWithText(''))
		}
	}));

	const onChange = (editorState) => {
		setEditorState(editorState);
	};

	const focus = () => {
		editorRef.current.focus();
	};

	const isEmpty = editorState.getCurrentContent().hasText();

	return (
		<div className={editorStyles.editor} onClick={focus} data-invalid={props.isInvalid}>
			{!isEmpty && <div className={editorStyles.placeholder}>{props.placeholder}</div>}
			<Editor
				editorState={editorState}
				onChange={onChange}
				plugins={plugins}
				ref={editorRef}
			/>
			<div className={editorStyles.toolbar}>
				<Toolbar>
					{
						(externalProps) => (
							<div className={editorStyles.buttonsContainer}>
								<BoldButton {...externalProps} />
								<ItalicButton {...externalProps} />
								<UnderlineButton {...externalProps} />
								<UnorderedListButton {...externalProps} />
								<OrderedListButton {...externalProps} />
								<BlockquoteButton {...externalProps} />
								<CodeBlockButton {...externalProps} />
							</div>
						)
					}
				</Toolbar>
			</div>
		</div>
	);
});

export default CustomEditor;