import Modal from "../modals/Modal"
import React, {useState} from "react";
import InputTypePassword from '../helpers/inputTypePassword'
import {IoEyeOffOutline, IoEyeSharp} from "react-icons/io5";
import axios from "axios";
import {baseURL} from "../helpers/baseURL";
import Cookies from "js-cookie";
import {useHistory} from "react-router-dom";


const ChangePassword = ({isOpen, hideModal}) => {
	const [type, setType] = useState('password')
	const [current, setCurrent] = useState()
	const [newPassword, setNewPassword] = useState()
	const [confirmPassword, setConfirmPassword] = useState()
	const [confirm, setConfirm] = useState()
	const [currentMsg, setCurrentMsg] = useState()
	const history = useHistory()

	const handleChangePassword = () => {
		if(newPassword !== confirmPassword){
			setConfirm("Password and Password Confirmation are not matching")
			return false
		}
		let oldPassword = current
		 axios.post(`${baseURL}user/change`, {oldPassword, newPassword, confirmPassword}, {headers:{Authorization: `Bearer ${Cookies.get('access_token')}`}})
			.then(res =>  {
				Cookies.remove("access_token")
				localStorage.clear()
				history.push('/login')
			})
			.catch(err => {
				if(err.response.data.data){
					setCurrentMsg("Wrong Password")
				}
			})
	}

	return (
		isOpen && (
			<Modal>
				<div className="modal d-block changePasswordDialog" onClick={hideModal}>
					<div className="modal-dialog  modal-dialog-centered" onClick={(e) => e.stopPropagation()}>
						<div className="modal-content">
							<div className="modal-header userPaddingLR">
								<div className="px-1 py-2">
									<h3>Change Password</h3>
								</div>
							</div>
							<div className="modal-body userPaddingLR py-3">
								<div className="position-relative mb-3">
									<h4>Current Password</h4>
									<div className="position-relative">
										<input type={type} className="Password_eye" onKeyUp={(e) => setCurrent(e.target.value)}/>
									</div>
									<div style={{color: 'red'}}>{currentMsg}</div>
								</div>
								<div className="position-relative mb-3">
									<h4>New Password</h4>
									<div className="position-relative">
										<input type={type} className="Password_eye" onKeyUp={(e) => setNewPassword(e.target.value)}/>
									</div>
								</div>
								<div className="position-relative">
									<h4>Confirm New Password</h4>
									<div className="position-relative">
										<input type={type} className="Password_eye" onKeyUp={(e) => setConfirmPassword(e.target.value)}/>
									</div>
								</div>
								<div style={{color: "red"}}>
									{confirm}
								</div>
							</div>
							<div className="modal-footer userPaddingLR">
								<button type="button" className="btn cancel-button" onClick={hideModal}>Cancel</button>
								<button type="button" className="btn save-button" onClick={() => handleChangePassword()}>Save</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		)
	)
}
export default ChangePassword
