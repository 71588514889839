import React, { useEffect, useState } from "react"
import { Col, Row, Container, Button } from "react-bootstrap";
import { BsPencil, BsTrash3 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { fetchDepartments, fetchUsers, fetchUser } from "../../redux/actions";
import MainContainer from "../MainContainer";
import TableView from '../TableView';
import ItemModal from "../ItemModal";
import { showDeleteConfirm } from "../ItemDelete";
import LimitedNewButton from "../LimitedNewButton";

const Users = () => {

	const dispatch = useDispatch()
	const [showItemModal, setShowItemModal] = useState(false)
	const [formData, setFormData] = useState({});

	const single = 'User';
	const itemType = 'users';
	const prefixAPI = 'users';

	const defaultFormInputs = {
		id: null,
		name: '',
		title: '',
		email: '',
		role: 'agent',
		departments: []
	}

	const columns = [
		{ key: 'name', label: 'Name' },
		{ key: 'title', label: 'Title' },
		{ key: 'email', label: 'Email' },
		{ key: 'role', label: 'Role' },
		{ key: 'accepted', label: 'Accepted', mode: 'check' },
		{ key: 'created_at', label: 'Joined Since' },
		{ key: '__actions__', label: '' }
	];

	const processRows = (rows) => {
		if (rows === undefined) {
			return [];
		}
		return rows.map(row => ({
			...row,
			__actions__: () => <>
				<Button onClick={() => showFormModal('Edit', row)} variant='tertiary' className='small-icon-button me-2 normal'><BsPencil /></Button>
				<Button onClick={() => showDeleteConfirm(row, single, prefixAPI, reduxRefresh)} variant='tertiary' className='small-icon-button danger'><BsTrash3 /></Button>
			</>
		}));
	};

	const reduxRefresh = () => {
		dispatch(fetchDepartments());
		dispatch(fetchUsers());
		dispatch(fetchUser());
	};

	useEffect(() => {
		reduxRefresh();
	}, []);

	const isLoading = useSelector(state => state.usersReducer.loading);
	const rows = useSelector(state => processRows(state.usersReducer.users));

	const showFormModal = (title, item = defaultFormInputs) => {
		setFormData({ title, item })
		setShowItemModal(true);
	};

	return (
		<MainContainer>
			<Container fluid="md">
				<Row className='pt-3 pb-3'>
					<Col sm={6} className='text-align-left'>
						<h5>Users</h5>
					</Col>
					<Col sm={6}>
						<LimitedNewButton isLoading={isLoading} showFormModal={() => showFormModal('Add')} single={single} reducerKey={itemType} />
					</Col>
				</Row>
				<Row>
					<Col className="overflow-x-auto">
						<TableView rows={rows} columns={columns} tableClass={'horizontal-line-table bordered-table'} isLoading={isLoading} />
					</Col>
				</Row>
			</Container>
			{
				showItemModal && <ItemModal currentState={formData} itemType={itemType} single={single} prefixAPI={prefixAPI} reduxRefresh={reduxRefresh} closeModal={() => setShowItemModal(false)} />
			}
		</MainContainer>
	);
}
export default Users
