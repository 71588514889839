import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import MainPages from "../MainPages";
// import '../../../node_modules/font-awesome/css/font-awesome.min.css'; 

import { fetchBlog } from "../../js/redux/actions";
import { useParams } from "react-router";

import facebook from "../../main/images/social-icons/fb.svg"
import twitter from "../../main/images/social-icons/tw.svg"
import linkedin from "../../main/images/social-icons/linkedin.svg"
import reddit from '../../main/images/social-icons/reddit.svg'
import whatsapp from '../../main/images/social-icons/whatsapp.svg'
import telegram from '../../main/images/social-icons/telegram.svg'

import noimg from "../Blog/noprev.svg"

const BlogDetails = (props) => {

    const { id } = props.location.state
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchBlog(id))
    }, [])

    const blog = useSelector(state => state.blogDetailsReducer.blog)

    useEffect(() => {
        console.log(blog)
    }, [blog])

    return (
        <MainPages>{blog && blog.data &&
            <>
                <div className="single-post-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12">
                                <div className="single-post-main-image">
                                    <img src={blog.data.image_original ? blog.data.image_original : noimg} />
                                </div>
                                <div className="single-post-content-container">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="single-post-category">
                                            {blog.data.categories && blog.data.categories.length > 0 ? blog.data.categories.map(category => (
                                                <a href="#" className="blog-category" key={`cat${category.id}`}>{category.name}</a>
                                            )) : ''}
                                        </div>
                                        <div className="single-post-date-published">{blog.data.date}</div>
                                    </div>
                                    <h1 className="single-post-title">{blog.data.title}</h1>
                                    <div className="single-post-text-wrapper">
                                        <div className="single-post-text-desc" dangerouslySetInnerHTML={{ __html: blog.data.description }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {blog.data.related_articles.length > 0 ? <div className="related-posts-blog">
                    <div className="container">
                        <div className="related-post-main-title">
                            <h4>Related Posts</h4>
                        </div>
                        <div className="articles-holder">
                            {
                                blog.data.related_articles.map(blog => {
                                    return (
                                        <div className="blog-post-holder">
                                            <Link to={{ pathname: `blog/${blog.data.slug}`, state: { id: blog.data.id } }} >
                                                <div className="post-image">
                                                    <img src={blog.data.image} title="" alt="" />
                                                </div>
                                                <div className="blog-post-details">
                                                    <div className="post-category-date">
                                                        <div className="post-category">{blog.data.categories && blog.data.categories.length > 0 ? blog.data.categories.map(category => (
                                                            <a href="#" className="blog-category" key={`cat${category.id}`}>{category.name}</a>
                                                        )) : ''}</div>
                                                    </div>
                                                    <h4 className="post-title">{blog.data.title}</h4>
                                                    <div className="post-short-description">
                                                        <p>{blog.data.short_description}</p>
                                                    </div>
                                                    <div className="post-read-more">
                                                        Read <span className="mydevicon-dropdown-arrow-default"></span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div> : ''}
            </>}
        </MainPages>
    )
}
export default BlogDetails