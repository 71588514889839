import React, {useEffect, useState} from "react"
import Modal from "../Modal";
import {Col, Row} from "react-bootstrap"
import { IoIosClose } from "react-icons/io";
import AppCarousel from "./appCarousel";
import claritask from "../../../css/img/claritask2000 px.svg"
import ClaritaskLogin from "./ClaritaskLogin";
import AllApps from "./AllApps";
import AppDetails from "./App-Detail";
import AppSettings from "./App-Settings";
import {ModalContext, ModalProvider, ModalRoot} from "react-multi-modal";

const ConnectionModal = ({ isOpen, hideModal, currentState}) => {

    const [all, change] = useState('all')
    const [detailed, setDetailed] = useState()
    const [settings, setSettings] = useState()
    const [root, setRoot] = useState('')

    useEffect(() => {
        if(currentState.title === "Edit"){
            change("")
            setDetailed("")
            setSettings(true)
            setRoot(`${currentState.connection.name}`)
        }
    },[])

    const linked = currentState && currentState.user.claritask_linked

    const modalClose = () => {
        hideModal()
        if(currentState.title === "Create"){
            setDetailed("")
            change("all")
            setRoot("")
        }

    }

    return (
        isOpen && (
            <Modal>
                <div className="modal d-block" onClick={hideModal}>
                    <section className="connections_modal" onClick={(e) => e.stopPropagation()}>
                        <div className="position-relative">
                            <div className="modal-header">
                                <div className="d-flex align-items-center" style={{pointerEvents: currentState.title === "Edit" ? "none" : "all"}}>
                                    <h3 onClick={() => {
                                        change("all")
                                        setRoot("")
                                    }}>
                                        App Market
                                    </h3>
                                    {root && <span className={"px-2"}> &gt; </span>}
                                    {settings ? root && <>
                                        <span className="app-nested-route" >{root}</span>
                                        <span className={"px-2"}> &gt; </span>
                                        <span className="app-inner-root">Settings</span>
                                    </> : root && <span className="app-inner-root">{root}</span>}

                                </div>
                                <input type="search" className="customSearch-tables mr-0" placeholder="Search apps"/>
                            </div>
                            <div onClick={() => modalClose()} className="hide-connection-mod"><IoIosClose/></div>
                        </div>
                        {all && <AllApps setDetailed={setDetailed} change={change} setRoot={setRoot}/>}
                        {detailed && <AppDetails detailed={detailed} linked={linked}/>}
                        {settings && <AppSettings app={currentState.connection} hideModal={hideModal} user={currentState.user}/>}
                    </section>
                </div>
            </Modal>
        )
    );
}
export default ConnectionModal
