const initialState = {
	departments: [],
};
export const departmentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_DEPARTMENTS':
			return {
				...state,
				departments: action.payload,
				loading: action.loading ?? false
			}
		default:
			return state;
	}
}
