const initialState = {
    types: [],
};
export const typesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TYPES':
            return {
                ...state,
                types: action.payload,
                loading: action.loading ?? false
            }
        default:
            return state
    }
}
