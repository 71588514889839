export const teamsReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_TEAMS':
            return{
                ...state,
                teams: action.payload,
                loading: action.loading ?? false
            }
        default:
            return state
    }
}
