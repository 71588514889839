import React from 'react';
import {useHistory} from "react-router-dom";
import serializeForm from "form-serialize";
import axios from "axios";
import {baseURL} from "../../helpers/baseURL";
import failAlert from "../../helpers/failAlert";

const ConfirmPassword = (props) => {
    const history = useHistory()
    const handleSubmit = async e => {
        e.preventDefault();
        const path = props.location.pathname.replace(/\//,'');
        const password = serializeForm(e.target, {hash: true})

        await axios.post(`${baseURL}${path}`, password)
            .then(res =>  {
                history.push('/login')
            }).catch(err => failAlert("Something went wrong"))
    }
    return(
        <div className="container">
            <h2>Change Password</h2>
            <form className="w-50 mx-auto mt-5" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1" className="text-left w-100">Password</label>
                    <input type="password" name="password" className="form-control" id="exampleInputPassword1"  placeholder="Password"/>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    )
}
export default ConfirmPassword
