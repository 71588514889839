import React, { useState } from "react"
import "./alert.css"
import info from "./images/info-icon.svg"
import remove from "./images/remove-box.svg"

const ClaritickAlert = ({type, message, title, shown, setShown}) => {
    return(
        <div  className={`notif-club-hidden notif-club ${type}-club`} style={{opacity: shown}}>
            <div className="notif-header-club">
                <span className="notif-icons">
                    <img src={info} />
                </span>
                <span onClick={() => setShown(0)} className="notif-icons notif-club-close-x" style={{cursor: 'pointer'}}>
                    <img src={remove}/>
                </span>
            </div>
            <div className="notif-content-club">
                <span className="notif-content-header">{title}</span>
                <span className="notif-content-text">{message}</span>
            </div>
        </div>
    )
}
export default ClaritickAlert