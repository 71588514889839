import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchTickets, fetchUser, fetchStatuses, fetchUsers, fetchDepartments, getPriorities, getTags, getTypes } from "../../../redux/actions";
import MainContainer from "../../MainContainer";
import TicketsAction from './TicketsAction';
import TicketsExport from "./TicketsExport";
import TicketsFilter from './TicketsFilter';
import TicketsTable from './TicketsTable';
import TicketModal from './TicketModal';
import TicketsLayout from "./TicketsLayout";
import TicketCategoryInfo from "./TicketCategoryInfo";
import LimitedNewButton from '../../LimitedNewButton';

const Tickets = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { category } = useParams();

	const user = useSelector(state => state?.userReducer?.user);
	const ticketsLoading = useSelector(state => state.ticketsReducer.loading);
	const tickets = useSelector(state => state.ticketsReducer.tickets);
	const total = useSelector(state => state.ticketsReducer.total);

	const [showTicketModal, setShowTicketModal] = useState(false)
	const [isLoading, setIsLoading] = useState(true);
	const [params, setParams] = useState('');
	const [selection, setSelection] = useState([])
	const [columns, setColumns] = useState(() => {
		const savedColumns = localStorage.getItem('ticketTableColumns');
		return savedColumns ? JSON.parse(savedColumns) : {
			title: { visible: true, label: 'Ticket' },
			status: { visible: true, label: 'Status' },
			department: { visible: true, label: 'Department' },
			type: { visible: false, label: 'Type' },
			priority: { visible: true, label: 'Priority' },
			tags: { visible: true, label: 'Tags' },
			user: { visible: true, label: 'Assignee' },
			replies: { visible: false, label: 'Replies' },
			replied_at: { visible: true, label: 'Replied' },
			due_date: { visible: true, label: 'Due' },
			created_at: { visible: true, label: 'Created' },
		};
	});
	const [filters, setFilters] = useState({});
	const [sort, setSort] = useState({ by: 'created_at', direction: 'desc' });
	const [pagination, setPagination] = useState({ page: 1, perPage: 10 });

	const data = {}
	const loading = {}
	const dataFetchers = {
		statuses: fetchStatuses,
		departments: fetchDepartments,
		priorities: getPriorities,
		types: getTypes,
		tags: getTags,
		users: fetchUsers,
		user: fetchUser,
	}
	for (const dataKey of Object.keys(dataFetchers)) {
		data[dataKey] = useSelector(state => state[dataKey + 'Reducer'][dataKey]);
		loading[dataKey] = useSelector(state => state[dataKey + 'Reducer']['loading']);
	}

	const cleanParams = (params) => {
		return Object.entries(params).reduce((param, [key, value]) => {
			if (value !== null && value !== '' && !(Array.isArray(value) && value.length === 0)) {
				param[key] = value;
			}
			return param;
		}, {});
	}

	const handleFilterChange = (newFilters) => {
		setFilters(newFilters);
	};

	const handleSortChange = (column) => {
		setSort({
			by: column,
			direction: sort.by === column && sort.direction === 'asc' ? 'desc' : 'asc',
		});
	};

	const handlePageChange = (page, perPage) => {
		setPagination({ page, perPage });
	};

	const handleColumnVisibilityChange = (column) => {
		const updatedColumns = {
			...columns,
			[column]: {
				...columns[column],
				visible: !columns[column]['visible']
			},
		};
		setColumns(updatedColumns);
		localStorage.setItem('ticketTableColumns', JSON.stringify(updatedColumns));
	};

	const parseParam = (param, type) => {
		if (type === 'id') {
			return param ? param.split(',').map(id => parseInt(id)) : [];
		} else if (type === 'bool') {
			return param === undefined || param === null ? null : param === 'true';
		} else {
			return param ? param.split(',') : [];
		}
	};

	const getPathname = (newParams) => {
		const isCategoryConflict = (
			(newParams.deleted !== undefined && (category === 'deleted' && newParams.deleted === false)) ||
			(newParams.assigned !== undefined && (category === 'assigned' && newParams.assigned !== true)) ||
			(newParams.assigned !== undefined && (category === 'unassigned' && newParams.assigned !== false)) ||
			(newParams.assignee !== undefined && (category === 'my' && (newParams.assignee.length !== 1 || newParams.assignee[0] !== user.id))) ||
			(newParams.status !== undefined && (['open', 'active', 'pending', 'closed', 'spam'].includes(category) && (newParams.status !== category)))
		);
		return isCategoryConflict ? '/tickets' : `/tickets/${category}`;
	};

	useEffect(() => {
		if (!loading.user && !loading.statuses) {
			const queryParams = new URLSearchParams(window.location.search);
			setFilters({
				text: queryParams.get('text') ?? '',
				tag: parseParam(queryParams.get('tag'), 'id'),
				type: parseParam(queryParams.get('type'), 'id'),
				priority: parseParam(queryParams.get('priority'), 'id'),
				department: parseParam(queryParams.get('department'), 'id'),
				assignee: queryParams.get('assignee') ? parseParam(queryParams.get('assignee'), 'id') : (category === 'my' ? [data.user.id] : []),
				assigned: parseParam(queryParams.get('assigned'), 'bool') ?? (category === 'assigned' ? true : category === 'unassigned' ? false : null),
				status: queryParams.get('status') ?? (data.statuses.some(status => status.name.toLowerCase() === category) ? category : ''),
				replied: parseParam(queryParams.get('replied'), 'bool') ?? null,
				due_date: queryParams.get('due_date') ?? '',
				created_at: queryParams.get('created_at') ?? '',
				due_date_range: parseParam(queryParams.get('due_date_range'), 'date'),
				created_at_range: parseParam(queryParams.get('created_at_range'), 'date'),
				deleted: parseParam(queryParams.get('deleted'), 'bool') ?? (category === 'deleted' ? true : null),
			});
		}
	}, [data.user, data.statuses, category]);

	useEffect(() => {
		if (Object.keys(filters).length === 0) {
			return;
		}
		const cleanedParams = cleanParams({
			...filters,
			sort_by: sort.by,
			sort_direction: sort.direction,
			page: pagination.page,
			per_page: pagination.perPage,
		});
		const newParams = new URLSearchParams(cleanedParams).toString();
		const newHistory = {
			search: newParams,
			...(category !== undefined && { pathname: getPathname(cleanedParams) })
		};
		history.replace(newHistory);
		setParams(newParams);
	}, [filters, sort, pagination]);

	useEffect(() => {
		setIsLoading(Object.values(loading).some(loading => loading) || ticketsLoading);
	}, [ticketsLoading, ...Object.values(loading)]);

	useEffect(() => {
		for (const dataKey of Object.keys(dataFetchers)) {
			if ((data[dataKey] === undefined || data[dataKey].length === 0) && !loading[dataKey]) {
				dispatch(dataFetchers[dataKey]());
			}
		}
		setSelection([]);
	}, [filters]);

	useEffect(() => {
		if (Object.keys(filters).length > 0) {
			dispatch(fetchTickets(params));
		}
	}, [params]);

	const reduxRefresh = () => {
		dispatch(fetchTickets(params));
		dispatch(fetchUser());
	};

	const actionStarted = () => {
		setIsLoading(true);
	}

	const actionCompleted = () => {
        dispatch(fetchUser());
		setSelection([]);
		dispatch(fetchTickets(params));
	}

	const handleSelectionChange = (IDs, checked) => {
		const newSelection = checked
			? Array.from(new Set([...selection, ...IDs]))
			: selection.filter(id => !IDs.includes(id));
		setSelection(newSelection);
	};

	return (
		<MainContainer>
			<Container fluid>
				<Row className='pt-3 pb-3'>
					<Col className='table-page-header'>
						<h5 className="flex-grow-1 m-0 text-align-left"><TicketCategoryInfo category={category} /></h5>
						<TicketsAction isLoading={isLoading} data={data} selection={selection} onActionStarted={actionStarted} onActionCompleted={actionCompleted} />
						<TicketsLayout columns={columns} onColumnVisibilityChange={handleColumnVisibilityChange} />
						<TicketsFilter isLoading={isLoading} data={data} filters={filters} onFilterChange={handleFilterChange} />
						<TicketsExport columns={columns} />
						<LimitedNewButton isLoading={isLoading} showFormModal={() => setShowTicketModal(true)} single={'Ticket'} reducerKey='tickets' />
					</Col>
				</Row>
				<Row>
					<Col className="overflow-x-auto">
						<TicketsTable
							tickets={tickets}
							total={total}
							columns={columns}
							sort={sort}
							pagination={pagination}
							selection={selection}
							isLoading={isLoading}
							onSortChange={handleSortChange}
							onPaginationChange={handlePageChange}
							onSelectionChange={handleSelectionChange}
						/>
					</Col>
				</Row>
			</Container>
			{
				showTicketModal && <TicketModal auxiliary={data} reduxRefresh={reduxRefresh} closeModal={() => setShowTicketModal(false)} />
			}
		</MainContainer>
	)
}

export default Tickets
