import React from 'react';
import {useHistory, useLocation} from "react-router-dom";
import serializeForm from "form-serialize";
import axios from "axios";
import {baseURL} from "../../helpers/baseURL";
import failAlert from "../../helpers/failAlert";
import {Link} from "react-router-dom"
import logo from "../../../css/img/claritick_tm_dark.svg";

const ResetPassword = (props) => {
    const history = useHistory()
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    let emailValue =  query.get('email')

    const handleSubmit = async (e) => {
        e.preventDefault()
        const values = serializeForm(e.target, {hash: true})
        const path = props.location.search.replace(/\//,'');

        let email = emailValue
        let password = values.password
        let password_confirmation = values.password_confirmation


        await axios.post(`${baseURL}password/reset${path}`, {email, password, password_confirmation})
            .then(res =>  {
                history.push('/login')
            })
            .catch(err => err.message === "Request failed with status code 422" ? failAlert("Password Confirmation Is not matching") : failAlert("Something went wrong"))
    }

    return(
        <form className="w-50 mx-auto mt-5" onSubmit={(e) => handleSubmit(e)}>
            <section className="center-div">
                <div className="form-box">
                    <img src={logo} className="new-logo"/>
                    <p className="registration-tx"><strong>Reset your password </strong></p>
                    <p className="registration-tx">Your new password should be different from previous used passwords</p>
                    <input type="email" name="email"  value={emailValue} disabled/>
                    <input type="password" name="password" placeholder="New Password"/>
                        <input type="password" name="password_confirmation" placeholder="Confirm Password Confirmation" />
                        <button type="submit" className="register-btn w-100">Submit</button>
                        <p className="register-tx">Go back to <Link to="/login">Log In </Link></p>
                </div>
            </section>
        </form>
    )
}
export default ResetPassword
