import React, { useEffect, useState } from "react"
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import nodata from "../../../../css/img/nodata.svg"
import axios from "axios";
import Cookies from "js-cookie";
import { baseURL } from "../../../helpers/baseURL";
import ColorBullet from "../../ColorBullet";
import UserBullet from "../../UserBullet";

const TicketsSummary = (props) => {

    const [tickets, setTickets] = useState();
    
    useEffect(() => {
        setTickets(props.tickets.ticketsOverview)
    }, [props.tickets.ticketsOverview])

    const changeTicketsShown = (e) => {
        let value = e.target.value
        axios.get(`${baseURL}tickets-summary/${value}`, {
            headers: {
                Authorization: `Bearer ${Cookies.get('access_token')}`
            }
        }).then(res => setTickets(res.data))
    }

    return (
        <div className="header-panel-dashboard panelContainer p-4 bg-white after-top">
            <select defaultValue="1" className="tickets-select-changer w-100" onChange={(e) => changeTicketsShown(e)}>
                <option value="new">New Tickets</option>
                <option value="my">My In Progress Tickets</option>
                <option value="recent">Recently Replied Tickets</option>
                <option value="active">Long-standing Active Tickets</option>
                <option value="overdue">Overdue Tickets</option>
                <option value="unassigned">Unassigned Tickets</option>
            </select>
            {
                tickets && tickets.length > 0 ?
                    <Table responsive className="dashboardTable mb-0" style={{ maxHeight: '100%' }}>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Status</th>
                                <th>Department</th>
                                <th>Assignee</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tickets.map(ticket => {
                                return (
                                    <tr key={ticket.id}>
                                        <td>
                                            <Link to={`/ticket/${ticket.id}`}>{ticket.title ? ticket.title : "Untitled"}</Link>
                                        </td>
                                        <td>
                                            { ticket.status && <ColorBullet color={ticket.status.color} text={ticket.status.name} /> }
                                        </td>
                                        <td>
                                            { ticket.department && <ColorBullet color={ticket.department.color} text={ticket.department.name} /> }
                                        </td>
                                        <td><UserBullet user={ticket.user} /></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table> : <div className="noData-holder pt-5"><img src={nodata} alt="" /></div>
                }
        </div>
    )
}
export default TicketsSummary
