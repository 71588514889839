import React, { useState, useRef, useEffect } from 'react';
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import MyDropdown from "./Dropdown"
import { ModalContext, ModalProvider, ModalRoot } from 'react-multi-modal';
import { connect } from "react-redux";
import * as actionCreators from "../redux/actions/index"
import { withRouter, Link } from "react-router-dom";
import bellIcon from "../../css/img/bell.svg";
import showsys from "../../css/img/show_sys_logo.svg"
import sendbat from "../../css/img/sendbat.svg"
import tools from "../../css/img/tools.svg"
import arrow from "../../css/img/arrow-down-color.svg"
import { useSelector, useDispatch } from 'react-redux';
import { fetchUser, fetchNotificationTicket, searchTickets, fetchDetailTicket } from '../redux/actions/index';
// modals
import Notification from "./Pages/Notification";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Cookies from "js-cookie";

const ListPlatformsStatic = [
    {
        name: 'CRM',
        description: 'Customer Relationship Management',
        icon: '/images/platforms/crm.svg',
        youtube: 'https://www.youtube.com/watch?v=T-I4HYCz9yE'
    },
    {
        name: 'Sendbat',
        description: 'SMS and Email Automation',
        icon: '/images/platforms/sendbat.svg',
        youtube: 'https://www.youtube.com/watch?v=zYasXlSmyKI'
    },
    {
        name: 'Urless',
        description: 'Short URL & QR Code Generator',
        icon: '/images/platforms/urless.svg',
        youtube: null
    },
    {
        name: 'Morsix',
        description: 'Inventory Management',
        icon: '/images/platforms/morsix.svg',
        youtube: 'https://www.youtube.com/watch?v=OEImRnCyFM0'
    },
    {
        name: 'Claritask',
        description: 'Project Management',
        icon: '/images/platforms/claritask.svg',
        youtube: 'https://www.youtube.com/watch?v=4cJ25YK9W6k'
    },
    {
        name: 'Claritick',
        description: 'Ticketing System',
        icon: '/images/platforms/claritick.svg',
        youtube: 'https://www.youtube.com/watch?v=xITyoLiGEb4'
    },
    {
        name: 'Convosio',
        description: 'Chat System',
        icon: '/images/platforms/convosio.svg',
        youtube: 'https://www.youtube.com/watch?v=fw5Xz3h9Clg'
    },
    {
        name: 'Zuitte',
        description: 'Business Solution',
        icon: '/images/platforms/zuitte.svg',
        youtube: null
    },
    {
        name: 'Ipaymer',
        description: 'Payment Processing System',
        icon: '/images/platforms/ipaymer.svg',
        youtube: null
    },
    {
        name: 'Azalytics',
        description: 'Web Analytics',
        icon: '/images/platforms/azalytics.svg',
        youtube: null
    },
];


const NavBar = ({ isLogged, collapsed }) => {
    const dispatch = useDispatch()
    const [navbarState, setNavbarState] = useState({
        dropdown: false,
        sys: false,
        clicked: false,
        showOnMob: false,
        open: false,
    })
    const sysRef = useRef();
    const navRef = useRef();
    const searchRef = useRef();
    const searchInputRef = useRef();

    const tickets = useSelector(state => state.ticketsReducer.searched?.tickets ?? [])
    const user = useSelector(state => state.userReducer.user)
    const new_tickets = useSelector(state => state.newTickets.new_tickets)

    useEffect(() => {
        if (isLogged) {
            dispatch(fetchUser())
            dispatch(fetchNotificationTicket());
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(()=>{
        // .dropdown-open
        if(navbarState.sys){
            document.body.classList.add('dropdown-open');
        }else{
            document.body.classList.remove('dropdown-open');
        }
    },[navbarState?.sys]);

    const handleClickOutside = (event) => {
        // console.log('clicked outside of your element');
        if (sysRef && !sysRef.current.contains(event.target)) {
            setNavbarState(prevObj => ({ ...prevObj, sys: false }))
            setTimeout(() => {
                if (event.target.classList.contains('searchbox-adm-icon')) {
                    searchInputRef?.current?.focus();
                }
            }, 100);
        }

        if (sysRef && !navRef.current.contains(event.target)) {
            setNavbarState(prevObj => ({ ...prevObj, showOnMob: false }))
        }

        if (sysRef && !searchRef.current.contains(event.target)) {
            setNavbarState(prevObj => ({ ...prevObj, open: false }))
        }
    }



    const handleSearch = (e) => {
        dispatch(searchTickets(e.target.value, { signalId: "tick" }))
        setNavbarState(prevObj => ({ ...prevObj, open: true }))
    }
    const goToTicket = (id) => {
        dispatch(fetchDetailTicket(id))
        setNavbarState(prevObj => ({ ...prevObj, open: false }))
    }

    const handleBlur = (e) => {
        if (e.target.value.trim() === '') {
            setNavbarState(prevObj => ({ ...prevObj, clicked: false }))
        }
    }
    return (
        <div className="adminHolderNav" ref={navRef}>
            <img src={tools} alt="nav" className='toggleTools' onClick={() => setNavbarState(prevObj => ({ ...prevObj, showOnMob: !navbarState.showOnMob }))} />
            <Navbar className={`nav-menu ${navbarState.showOnMob ? "showOnMob" : ''} ${collapsed ? "collapsed" : ''}`}>
                <Container fluid>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="b-0" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="w-100 d-flex flex-wrap justify-content-end">
                            <div id="nav-sentry-proxy">
                            </div>
                            <ul className="pre_nav_links p-0 ml-auto my-0 align-items-center">
                                <li className={`searchbox-adm ${navbarState.clicked ? 'searchbox-adm-open' : ''}`} ref={searchRef}>
                                    <input ref={searchInputRef} type="text" className='searchbox-adm-input' onKeyUp={(e) => handleSearch(e)} onBlur={(e) => handleBlur(e)} />
                                    <span className="searchbox-adm-icon" onClick={() => setNavbarState(prevObj => ({ ...prevObj, clicked: !navbarState.clicked }))}></span>
                                    <div className={`search-admin-results ${navbarState.open ? 'search-results-open' : ''}`}>
                                        <ul>
                                            <div>
                                                <h6 className='dropdown-header'>Searching through</h6>
                                                {tickets && tickets?.length > 4 ? <span><Link to="/tickets">Find more results...</Link></span> : ''}
                                            </div>
                                            {(tickets && tickets.length > 0) ? tickets.map((ticket) => {
                                                return (
                                                    <li key={`nav-${ticket.id}`} onClick={() => goToTicket(ticket.id)}>
                                                        <Link to={`/ticket/${ticket.id}`}>Ticket {ticket.ticket_id}
                                                            <span style={{ display: "block" }}>{ticket.title}</span>
                                                        </Link>
                                                    </li>
                                                )
                                            }) : <li className='p-2'>No data found</li>}
                                        </ul>
                                    </div>
                                </li>
                                <li className='flex-1'>

                                    <Dropdown className={`systems-holder-dropdown`} show={navbarState.sys} onClick={() => setNavbarState(prevObj => ({ ...prevObj, sys: !navbarState.sys }))} ref={sysRef}>
                                        <Dropdown.Toggle className="dropdown-profile">
                                            <img src={showsys} alet="systems" className='toggle-systems-icon' />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="sys-ticketing-body">
                                            <div className='switch-dropdown-holder'>
                                                <div className="title-platform-dropdown">
                                                    Platforms
                                                </div>
                                                {ListPlatformsStatic.map((platform, index) => {
                                                    return (
                                                        <a key={index} target="_blank" href={platform?.youtube ?? '#'} style={
                                                            {
                                                                "--platform-straggle-multiplayer": index
                                                            }
                                                        } className="platform-holder-icon access_accept">
                                                            <img src={platform?.icon} />
                                                            <div className="flex-grow-1 ps-2">
                                                                <div className="title">{platform?.name}</div>
                                                                <p className="paragraph">
                                                                    {platform?.description}
                                                                </p>
                                                            </div>
                                                                <img src="/images/platforms/logo-youtube.svg" alt="youtube"
                                                                className="platform-holder-icon-youtube" />
                                                        </a>
                                                    )
                                                })}


                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className='flex-1'>
                                    <NotificationsBar />
                                </li>
                                <li className='flex-1'><MyDropdown user={user} /></li>
                            </ul>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>

    );

}


function NotificationsBar() {
    const userSelector = useSelector(state => state.userReducer.user);
    const [notificationsCounts, setNotificationsCounts] = useState({
        "allNotificationsCount": 0,
        "unReadNotificationsCount": 0
    });

    useEffect(() => {
        let currentWorkspaceId = userSelector?.current_workspace;
        if (!currentWorkspaceId) return;
        window.Echo
            .private('user.' + userSelector.id)
            .listen('.Notification', handleListenSocket);
        return () => {
            window.Echo.leave('user.' + userSelector.id);
        }
    }, [userSelector?.current_workspace]);

    useEffect(() => {
        handleFetchNotificationsCount();
    }, []);


    const showModalNotify = (showModal) => {
        showModal({
            component: Notification,
            modalProps: {
                handleFetchNotificationsCount: handleFetchNotificationsCount
            }
        })
    }

    function handleListenSocket(e){
        // console.log("Received Data from socket: ",e);
        setNotificationsCounts(prev => {
            return {
                "allNotificationsCount": prev.allNotificationsCount + 1,
                "unReadNotificationsCount": prev.unReadNotificationsCount + 1
            }
        });
    }


    function handleFetchNotificationsCount(){
        fetchNotifications({ countOnly: true }).then((data) => {
            setNotificationsCounts(data);
        });
    }

    return <>
        <ModalProvider>
            <ModalContext.Consumer>
                {({ showModal, hideModal }) => (
                    <>
                        <div className="position-relative notification-header-panel" onClick={() => showModalNotify(showModal)}>
                            <img src={bellIcon} alt="notification" />
                            {notificationsCounts?.unReadNotificationsCount > 0 &&<span className='svgColor'>{notificationsCounts?.unReadNotificationsCount}</span>}
                        </div>
                        <ModalRoot />
                    </>
                )}
            </ModalContext.Consumer>
        </ModalProvider>
    </>
}


async function fetchNotifications({ countOnly = false }) {
    const param = new URLSearchParams();
    countOnly && param.append("countOnly", true);
    const response = await axios.get("/api/notifications?" + param.toString(), {
        headers: {
            'Authorization': 'Bearer ' + Cookies.get('access_token')
        }
    });
    return response.data;
}


export default NavBar
