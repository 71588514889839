import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { IoAddOutline, IoNotificationsOutline, IoPencil, IoTrashOutline, IoCheckmarkDoneOutline, IoCloseOutline } from "react-icons/io5";
// import {AiOutlineClose} from 'react-icons/ai';
import React, { useEffect, useState } from "react";
import { ModalContext } from "react-multi-modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTickets, fetchNotificationTicket } from "../../redux/actions"
import { baseURL } from "../../helpers/baseURL";
import axios from "axios";
import Cookies from "js-cookie";
import Modal from "../../modals/Modal";
import actionButtonsToggle from "../../helpers/actionButtonsToggle";
import { VscLoading } from "react-icons/vsc";
import { FaCheckDouble } from "react-icons/fa6";
import { useHistory } from "react-router";


window.addEventListener("click", function (e) {
	e.stopPropagation()
	let icon = document.getElementsByClassName('dotsNoty')
	if (icon) {
		actionButtonsToggle(e, ...icon)
	}
})


const Notification = ({ isOpen, hideModal , handleFetchNotificationsCount }) => {
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingReadAll, setIsLoadingReadAll] = useState(false);
    const userSelector = useSelector(state => state.userReducer);

    useEffect(() => {
        if(!userSelector?.user) return;
        if(isOpen){
            setIsLoading(true);
            handleFetchNotifications();
        }
    },[isOpen,userSelector?.user]);

    function handleFetchNotifications(){
        setNotifications([]);
        fetchNotifications().then((data) => {
            let tmpNotificationsList = data.allNotifications;
            setNotifications(tmpNotificationsList);
            setIsLoading(false);
        });
    }


    const handleClickOnlyBackdrop = (event) => {
		if (event.target.classList.contains('modal-b3')) {
			event.preventDefault();
			event.stopPropagation();
			hideModal();
            handleFetchNotificationsCount && handleFetchNotificationsCount();
		}
	}

    const markAllRead = async (e) => {
        setIsLoadingReadAll(true);
        axios.get('/api/notifications/mark-read',{ headers: { Authorization: `Bearer ${Cookies.get('access_token')}` } } ).then(()=>{
            handleFetchNotifications();
            handleFetchNotificationsCount && handleFetchNotificationsCount();
            setIsLoadingReadAll(false);
            hideModal();
        });
    }

    return (
		isOpen && (
			<Modal>
				<div onClick={handleClickOnlyBackdrop} className="modal d-block modal-b3">
					<div className="notification-modal">
						<div className="modal-body p-0">
							<nav>
								<div className="d-flex justify-content-between" id="notification-tab" role="tablist">
									<h5>Notifications</h5>
									<button type="button" className="btn-primary-menus me-4" onClick={(e) => markAllRead(e)}>
                                        Mark all as Read
                                        {isLoadingReadAll ? <VscLoading size={18} className="ms-1 spin-loading" /> : <FaCheckDouble className="ms-1" size={18} />}
                                    </button>
									<IoCloseOutline onClick={hideModal} className="hidemodal-notify" />
								</div>
							</nav>
							<div className="tab-content" id="nav-tabContent">

                                {isLoading && <div className=" text-center my-4">
                                    <div className="spinner-border"></div>
                                    <div>Fetching<div className="loading-dots"></div></div>
                                </div>}

                                {notifications.length > 0 && notifications.map((notification, index) => {
                                    switch(notification.type){
                                        case 'new-ticket':
                                            return <NotificationNewTicket key={index} {...notification} />
                                        default:
                                            return <div key={index}></div>
                                            // console.log('Unknown Notification Type:',notification);
                                    }
                                })}

                                {notifications.length == 0 && !isLoading && (<div className="text-center my-4">No Notifications</div>)}
							</div>
						</div>
					</div>
				</div>
			</Modal>
		)
	)
}

// handle type 'event:new-ticket'
function NotificationNewTicket(notification){
    const [isNew,setIsNew] = useState(notification.read_at === null);
    const navigate = useHistory();

    function goToTicket(){
        setIsNew(false);
        axios.get('/api/notifications/mark-read?id='+notification.id,{ headers: { Authorization: `Bearer ${Cookies.get('access_token')}` } } );

        let ticketId = notification?.ticket?.id
        ticketId ? navigate.push(`/ticket/${notification.ticket.id}`) : console.log('Ticket ID not found in notification data.');
    }
    function dismiss(){
        setIsNew(false);
        axios.get('/api/notifications/mark-read?id='+notification.id,{ headers: { Authorization: `Bearer ${Cookies.get('access_token')}` } } );
    }

    return (
        <div className={`d-flex align-items-center p-2 ${isNew ? 'bg-info-subtle' : ''}`}>
            <img src={"https://ui-avatars.com/api/?name="+(notification?.user?.name ?? notification?.user?.email ?? '00')} className="rounded img-thumbnail" style={{"--bs-border-radius": "50%"}} />
            <div className="flex-1 px-1">
                <p className="mb-0"><b>{notification?.user?.name ?? 'System'}</b> created new ticket </p>
                <p className="mb-0">Ticket: "{notification?.ticket?.title}"</p>
            </div>
            <div>
                <button onClick={goToTicket} className="btn btn-primary">View</button>
                <button onClick={dismiss} className="btn btn-secondary">Dismiss</button>
            </div>
        </div>
    );
}



async function fetchNotifications(offset = null,limit = null){
    const token = Cookies.get('access_token');
    const params = new URLSearchParams();
    if(offset) params.append('offset', offset);
    if(limit) params.append('limit', limit);

    const response = await axios.get(`/api/notifications?${params.toString()}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response?.data || [];
}



export default Notification
