export const blogReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_BLOGS':
            return{
                ...state,
                blogs: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}

export const blogDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_BLOG':
            return{
                ...state,
                blog: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}

export const blogCategoriesReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_BLOG_CATEGORIES':
            return{
                ...state,
                categories: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}


export const blogLatest = (state = [], action) => {
    switch (action.type) {
        case 'GET_LATEST_BLOGS':
            return{
                ...state,
                latest_blog: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}