import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const TicketsCount = ({ ticketsCount, useSampleData }) => {

    const menu = {
        open: {
            name: "Open",
            link: '/tickets/open'
        },
        active: {
            name: "Active",
            link: '/tickets/active'
        },
        MyTickets: {
            name: "My Tickets",
            link: '/tickets/my'
        },
        pending: {
            name: "Pending",
            link: '/tickets/pending'
        },
    }


    return (
        <Row>
            {Object.keys(menu).map((key) => (
                ticketsCount && ticketsCount[key] !== undefined &&
                <Col xs={12} sm={6} md key={key} className="p-0">
                    <div className={`dashboard-ticket-types mb-2 bg-white ticket-types-${key.toLowerCase()}`}>
                        <Link to={menu[key].link}>
                            <div>
                                <h3>{menu[key].name}</h3>
                                <span>{ticketsCount[key] !== undefined ? ticketsCount[key] : "-"}</span>
                            </div>
                        </Link>
                    </div>
                </Col>
            ))}
        </Row>
    );
}
export default TicketsCount
