import React from "react"

const Partners = () => {
    return(
        <div className="our-partners">
            <p className="w-100 text-center">Partners and Collaborators</p>
            <div className="our-partners__images-wrapper">
                <img alt="Accredited Business" loading="lazy" src="/images/optspot-logo.png" className="accredited-business"/>
                <img alt="Accredited Business" loading="lazy" src="/images/BBB-logo-new.svg" className="accredited-business"/>
                <img alt="Forbes" loading="lazy" src="/images/forbees.svg" className="forbes"/>
                <img alt="IFA" loading="lazy" src="/images/IFA-Supplier-Forum-new.svg" className="ifa-supplier-form"/>
                <img alt="IFA Member" loading="lazy" src="/images/IFA-Member-Logo-new.svg" className="ifa-member"/>
                <img alt="Google Cloud" loading="lazy" src="/images/google-cloud-new.svg" className="google-cloud"/>
                <img alt="doordash" loading="lazy" src="/images/doordash-logo-new.svg" className="doordash"/>
                <img alt="ick" loading="lazy" src="/images/ick-new.svg" className="ick"/>
                <img alt="campaign registry" loading="lazy" src="/images/campaing-regestry.svg" className="campaign-registry"/>
                <img alt="denny's" loading="lazy" src="/images/dennys.svg" className="dennys"/>
                <img alt="Asian Chamber of Commerce" loading="lazy" src="/images/asian-chamber-of-commerce.svg" className="dennys"/>
            </div>
        </div>
    )
}
export default Partners