import React from "react";
import MainContainer from "../../../MainContainer";
import LabelsView from "../../../LabelsView";

const Priority = () => {
	return (
		<MainContainer>
			<LabelsView itemType='priorities' />
		</MainContainer>
	)
}
export default Priority