import React, { useEffect } from 'react'
import { Container, Spinner, Row, Col } from 'react-bootstrap';
import MainContainer from '../../components/MainContainer';
import { useHistory } from "react-router-dom";

const SwitchWorkspace = () => {
    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            history.push(`/dashboard`);
        }, 2000);
    }, []);

    return (
        <MainContainer>
            <Container>
                <Row>
                    <Col className='mt-3'>
                        <Spinner animation="border" role="status" />
                        <h3>Switching Workspace...</h3>
                        <p>Please wait. If you are not redirected in 2 seconds, please click <a href="/dashboard">here</a>.</p>
                    </Col>
                </Row>
            </Container>
        </MainContainer>
    )
}

export default SwitchWorkspace
