// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VS-G1\\+nHfDsdwip3gC2v5g\\=\\={border-radius:10px;box-shadow:0 0 20px rgba(0,0,0,.1);color:#103254;margin:50px auto;max-width:540px;min-width:460px;padding:50px;text-align:center;width:70%}@media only screen and (max-width:480px){.VS-G1\\+nHfDsdwip3gC2v5g\\=\\={margin:0 auto;min-width:auto;padding:30px;width:100%}}.f8cjfOL50ZhuMBaksuzHWg\\=\\={font-weight:400;margin-top:20px}.GmG3HSCEdbafXDB62GZIJw\\=\\={line-height:1.2;margin:40px 0 30px}._3xq\\+a7XtH2Xv5Hrw6blvMg\\=\\={border-top:1px solid #eee}", "",{"version":3,"sources":["webpack://./resources/js/components/Pages/VerifyEmail.module.css"],"names":[],"mappings":"AAAA,6BAQI,kBAAmB,CADnB,kCAAsC,CAEtC,aAAc,CAJd,gBAAiB,CAHjB,eAAgB,CAChB,eAAgB,CAChB,YAAa,CAEb,iBAAkB,CALlB,SASJ,CAEA,yCACI,6BAII,aAAc,CAFd,cAAkB,CAClB,YAAa,CAFb,UAIJ,CACJ,CAEA,4BACI,eAAgB,CAChB,eACJ,CAEA,4BAEI,eAAgB,CADhB,kBAEJ,CAEA,8BACI,yBACJ","sourcesContent":[".container {\r\n    width: 70%;\r\n    max-width: 540px;\r\n    min-width: 460px;\r\n    padding: 50px;\r\n    margin: 50px auto;\r\n    text-align: center;\r\n    box-shadow: 0 0 20px rgba(0, 0, 0, .1);\r\n    border-radius: 10px;\r\n    color: #103254;\r\n}\r\n\r\n@media only screen and (max-width: 480px) {\r\n    .container {\r\n        width: 100%;\r\n        min-width: initial;\r\n        padding: 30px;\r\n        margin: 0 auto;\r\n    }\r\n}\r\n\r\n.paragraph {\r\n    font-weight: 400;\r\n    margin-top: 20px;\r\n}\r\n\r\n.header {\r\n    margin: 40px 0 30px 0;\r\n    line-height: 1.2;\r\n}\r\n\r\n.footer {\r\n    border-top: 1px solid #eee;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "VS-G1+nHfDsdwip3gC2v5g==",
	"paragraph": "f8cjfOL50ZhuMBaksuzHWg==",
	"header": "GmG3HSCEdbafXDB62GZIJw==",
	"footer": "_3xq+a7XtH2Xv5Hrw6blvMg=="
};
export default ___CSS_LOADER_EXPORT___;
