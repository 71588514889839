import React, { useEffect, useState } from "react"
import Modal from "../Modal";
import { IoAddOutline } from "react-icons/io5";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "../../helpers/DatePicker";
import Button from 'react-bootstrap/Button';

//Images
import timer from "../../../css/img/timer-log.svg"
import axios from "axios";
import { baseURL } from "../../helpers/baseURL";
import Cookies from "js-cookie";

function convertTimeToISOString (date, periodTime){
    let tmpTime = new Date(date);
        tmpTime.setHours(periodTime.split(':')[0]);
        tmpTime.setMinutes(periodTime.split(':')[1])
    return tmpTime.toISOString()
}

function calculateTimeSpent(date_start, startTime, date_end, endTime){ // 
   
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    date_start.setHours(startHours, startMinutes);
    date_end.setHours(endHours, endMinutes);

    const timeDifference = date_end.getTime() - date_start.getTime();

    const remainingMilliseconds = timeDifference % (1000 * 60 * 60);
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.round(remainingMilliseconds / (1000 * 60));

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return formattedHours + ":" + formattedMinutes;
}

const TimerModal = ({ isOpen, hideModal, currentState }) => {
    const [date_start, setDateStart] = useState(currentState.date_start)
    const [date_end, setDateEnd] = useState(currentState.date_end)
    const [extra_note, setNote] = useState("")
    const [startTime, setStart] = useState(currentState.startTimeConverted)
    const [endTime, setEnd] = useState(currentState.endTimeConverted)
    const [diff, setDiff] = useState();
    const [formError, setFormError] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [selectedAgent, setSelectedAgent] = useState(currentState.user_id);

    const users =  useSelector(state => state.usersReducer.users);

    const agentAssign = users?.map(user => {
        return { value: user.id, label: user?.name + ' ' + (user?.last_name || "") + ' - (' + user?.email + ')' }
    }) ?? -1;


    useEffect(() => {
        if (startTime && endTime) {
            setDiff(calculateTimeSpent(date_start, startTime, date_end, endTime))
        }else{
            setDiff();
        }
    }, [endTime, startTime, date_start, date_end]);

    useEffect(()=>{
        currentState?.endTimeConverted && setEnd(currentState.endTimeConverted);
        currentState?.startTimeConverted && setStart(currentState?.startTimeConverted);

    },[currentState?.endTimeConverted,currentState?.startTimeConverted]);

    const handleStartTimeChange = (e) => {
        const newStartTime = e.target.value;
        setStart(newStartTime);
        if (endTime < newStartTime) {
            setEnd(newStartTime);
        }
    }
    const handleEndTimeChange = (e) => {
        const newEndTime = e.target.value;
        setEnd(newEndTime);
        if (newEndTime < startTime) {
            setStart(newEndTime);
        }
    }

    const logTime = (e) => {
        e.preventDefault()
        let ticket_id = currentState.id;
        let user_id = selectedAgent;
        let start_time = startTime;
        let end_time = endTime;

        var tmpErrors = {};
        if (!user_id) {
            tmpErrors["user_id"] = "Agent is required";
        }
        if (!date_start || !date_end) {
            tmpErrors["date"] = "Date is required";
        }
        if (!start_time || start_time === "Invalid date") {
            tmpErrors["start_time"] = "Start time is required";
        }
        if (!date_end || date_end === "Invalid date" || (date_end < date_start)) {
            tmpErrors["end_time"] = "End time is required, and must be greater than start time";
        }
        // if (!end_time || end_time === "Invalid date" || moment(start_time, 'HH:mm').isAfter(moment(end_time, 'HH:mm'))) {
        //     tmpErrors["end_time"] = "End time is required, and must be greater than start time";
        // }

        setFormError(tmpErrors);
        if (Object.keys(tmpErrors).length > 0) {
            return;
        }
        setIsLoading(true);
        axios.post(`${baseURL}log-time`, {
            user_id,
            ticket_id,
            extra_note,
            start_time: convertTimeToISOString(date_start, start_time),
            end_time: convertTimeToISOString(date_end, end_time),
        }, { headers: { Authorization: `Bearer ${Cookies.get('access_token')}` } })
            .then(async (res) => {
                await new Promise(r => setTimeout(r, 5000));
                if (res?.data?.success) {
                    let newTicketTimer = res.data.ticket.ticket_timer;
                    let newTicketTotalTimer = res.data.ticket.total_ticket_timer;
                    currentState.setTicket(prevObj => ({...prevObj, ticket_timer: newTicketTimer, total_ticket_timer:newTicketTotalTimer}))
                    hideModal();
                } else {
                    setFormError({
                        'general': res.data.message
                    });
                }
                currentState.resetState()
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setIsLoading(false);
            });
    }

    const editTime = (e) => {
        e.preventDefault()
        let user_id = selectedAgent
        let ticket_id = currentState.id
        let timer_id = currentState.time.id
        let start_time = startTime;
        let end_time = endTime;
        setIsLoading(true);
        axios.post(`${baseURL}ticket-timer-update/${timer_id}`, { 
            user_id, 
            extra_note, 
            ticket_id,
            start_time: convertTimeToISOString(date_start, start_time),
            end_time: convertTimeToISOString(date_end, end_time),
        }, { headers: { Authorization: `Bearer ${Cookies.get('access_token')}` } })
            .then(res => {
                let editedTicketTimer = res.data.ticket.ticket_timer;
                let editedTicketTotalTimer = res.data.ticket.total_ticket_timer;
                currentState.setTicket(prevObj => ({...prevObj, ticket_timer: editedTicketTimer, total_ticket_timer:editedTicketTotalTimer}))
           
                hideModal()
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }

    return (
        isOpen && (
            <Modal>
                <div className="modal d-block" onClick={hideModal}>
                    <div className="timer-modal" onClick={(e) => e.stopPropagation()}>
                        <form onClick={() => setFormError({})} className="h-100 d-flex flex-column" onSubmit={(e) => currentState.title === 'Create' ? logTime(e) : editTime(e)}>
                            <div className="modal-header px-0 modal-header-timer align-items-center">
                                <div className="d-flex">
                                    <img src={timer} alt="clock" />
                                    <h5>Log Time</h5>
                                </div>
                                <IoAddOutline onClick={hideModal} className="modal-header-timer-x" />
                            </div>
                            <div className="modal-body modal-header-body p-0">
                                <Row>
                                    <Col sm={12} className="pl-0" style={{ marginTop: 10, marginBottom: 30 }}>
                                        {formError['general'] && <p className="text-danger">{formError['general']}</p>}
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: 20 }}>
                                    <Col sm={12} md={6}>
                                        <div>
                                            <p className="mb-1 font_main_p">Start Date <span className="text-danger">*</span></p>
                                                <DatePicker
                                                    handleDateChange={(value) => setDateStart(value)}
                                                    selected={date_start}
                                                />
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <div>
                                            <p className="mb-1 font_main_p">Start Time <span className="text-danger">*</span></p>
                                            <input 
                                                style={{ height: 37 }} 
                                                onChange={(e) => handleStartTimeChange(e)} 
                                                value={startTime ? startTime : '00:00'} 
                                                type="time" 
                                                className="form-control" 
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: 20 }}>
                                    <Col sm={12} md={6}>
                                        <div>
                                            <p className="mb-1 font_main_p">End Date <span className="text-danger">*</span></p>
                                                <DatePicker
                                                    handleDateChange={(value) => setDateEnd(value)}
                                                    selected={date_end}
                                                />
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <div>
                                            <p className="mb-1 font_main_p">End Time <span className="text-danger">*</span></p>
                                            <input 
                                                style={{ height: 37 }} 
                                                onChange={(e) => handleEndTimeChange(e)} 
                                                type="time" 
                                                className="form-control"  
                                                value={(endTime ? endTime : '00:00')}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} className="pl-0">
                                        <div className="d-flex align-items-start justify-content-between">
                                            <div>
                                                <p className="mb-1 font_main_p">Time Spent</p>
                                                <div className="d-flex">
                                                    <div>
                                                        <div className="timer-hour-spent">
                                                            {diff !== undefined && `${diff.split(":")[0]}h`}
                                                        </div>
                                                        <span className="timer-spent-span">hours</span>
                                                    </div>
                                                    <div>
                                                        <div className="timer-minutes-spent">
                                                            {diff !== undefined && `${diff.split(":")[1]}`}
                                                        </div>
                                                        <span className="timer-spent-span">minutes</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {formError['start_time'] && <p className="text-danger">{formError['start_time']}</p>}
                                            {formError['end_time'] && <p className="text-danger">{formError['end_time']}</p>}
                                        </div>
                                    </Col>
                                    <Col sm={12} className="pl-0" >
                                        <div className="mt-3 mb-3">
                                            <p className="mb-1 font_main_p">Agent <span className="text-danger">*</span></p>
                                            {agentAssign != -1 ? 
                                            <Select
                                                name="Agent"
                                                id="ticketSelect"
                                                value={selectedAgent ? {value: selectedAgent, label: agentAssign.find(us => us.value == selectedAgent)?.label} : " "}
                                                options={agentAssign}
                                                onChange={(e) => {
                                                    setFormError((prev) => ({ ...prev, user_id: '' }));
                                                    setSelectedAgent(e.value)
                                                }}

                                            /> : <>
                                                <div>
                                                    <span className="spinner-border spinner-border-sm text-secondary" role="status"></span>
                                                    <span className="ms-2">Loading...</span>
                                                </div>
                                            </>}
                                            {formError['user_id'] && <p className="text-danger">{formError['user_id']}</p>}
                                        </div>
                                    </Col>
                                    <Col sm={12} className="pl-0">
                                        <div style={{ marginBottom: 30 }}>
                                            <p className="mb-1 font_main_p">Note</p>
                                            <textarea className="timer-note" defaultValue={extra_note} onChange={(e) => setNote(e.target.value)} rows={5}></textarea>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <div className="timer-footer-btn">
                                    <Button disabled={isLoading} className="btn cancelLog" 
                                    onClick={hideModal}>Cancel</Button>
                                    <Button disabled={isLoading} className="btn logTime-timer" type="submit">
                                        {isLoading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                                        <span>Log Time</span>
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        )
    )
}
export default TimerModal
