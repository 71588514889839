import React from "react";
import MainContainer from "../../../MainContainer";
import LabelsView from "../../../LabelsView";

const Types = () => {
	return (
		<MainContainer>
			<LabelsView itemType='types' />
		</MainContainer>
	)
}
export default Types