export const StatusFilter = (state = {}, action) => {
    switch (action.type) {
        case 'GET_REPORT_STATUS' :
            return{
                ...state,
                data: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}

export const AgentsFilter = (state = {}, action) => {
    switch (action.type) {
        case 'GET_REPORT_AGENTS' :
            return{
                ...state,
                data: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}
export const PrioritiesFilter = (state = {}, action) => {
    switch (action.type) {
        case 'GET_REPORT_PRIORITIES' :
            return{
                ...state,
                data: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}

export const TagsFilter = (state = {}, action) => {
    switch (action.type) {
        case 'GET_REPORT_TAGS' :
            return{
                ...state,
                data: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}
