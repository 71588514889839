import React, {useEffect, useState} from "react"
import Modal from "../Modal";
import logo from "../../../main/images/logo.svg";
import {Link} from "react-router-dom";
import CompanyConnection from "./company-connection";
import img from "../../../css/img/claritask2000 px.svg"
import connect from "../../../css/img/connect.svg"
import {IoIosInformationCircleOutline} from "react-icons/io";
import axios from "axios";
import {baseURL} from "../../helpers/baseURL";
import Cookies from "js-cookie";
import { Tooltip, OverlayTrigger } from "react-bootstrap";


const ClaritaskLogin  = ({ isOpen, hideModal, currentState}) => {
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [companies, setCompanies] = useState()

    const [login, setLogin] = useState(true)
    const [terms, setTerms] = useState(false)
    const [company, setCompany] = useState(false)

    const [message, setMessage] = useState()

    useEffect(() => {
        document.addEventListener("keydown", function(e){
            if(e.keyCode === 27){
                hideModal()
            }
        })
    }, [])

    const termsAndCond = [
        {id: 1, description: "Access of companies", tooltip: "access"},
        {id: 2, description: "Nulla felis scelerisque nisi semper risus ac ullamcorper.", tooltip: "dummy"},
        {id: 3, description: "Ut ac, est volutpat interdum turpis odio cras lobortis consectetur. Cum porta imperdiet arcu, cras purus id sem venenatis enim..", tooltip: "dummy2"},
    ]
    const submitForm = (e) => {
        e.preventDefault()
        axios.post(`${baseURL}link`,{email, password},{headers:{Authorization: `Bearer ${Cookies.get('access_token')}`}})
            .then(res => {
                setLogin(false)
                setTerms(true)
                setCompanies(res.data.data.check_company)
            })
            .catch((err) => {
                if(err.response.status === 401){
                    setMessage(err.response.data.data.data)
                }
            })

    }
    const allowTerms = () => {
        setTerms(false)
        setCompany(true)
    }
    return(
        isOpen && (
            <Modal>
                <div className="modal d-block" onClick={hideModal}>
                    <section className="modal-main LoginToClaritask" onClick={(e) => e.stopPropagation()}>
                        {login && <form className="h-100" onSubmit={(e) => submitForm(e)}>
                            <div className="form-box custom-form-box">
                                <img src={img} className="new-logo"/>
                                <a href="https://claritask.com/" className="goToClaritask">claritask.com</a>
                                <p className="credentials">Enter your credentials to log in:</p>
                                <label>
                                    <input type="email" placeholder="Email" name="email" onChange={(e) => setEmail(e.target.value)}/>
                                </label>
                                <label>
                                    <input type='password' placeholder="Password" name="password"  onChange={(e) => setPassword(e.target.value)}/>
                                </label>
                                <span style={{color: "red", fontSize: "12px", marginRight: "auto"}}>{message}</span>

                                <a href="https://claritask.com/a/reset_password" className="forget-password">Forgot your password?</a>
                                <button type="submit"  className="register-btn w-100">Sign in</button>
                            </div>
                        </form>}
                        {terms &&
                            <div className="custom-form-box-terms">
                                <div className="modal-header flex-column">
                                    <img className="m-auto" src={connect} alt="connect"/>
                                    <h5 className="app-name-hold"><span>Claritick</span> wants to access your Claritask Account and it will allow to:</h5>
                                </div>
                                <ul className="terms-conditions">
                                    {termsAndCond.map(term => (
                                        <li key={`term${term.id}`}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <p>{term.description}</p>
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip id="button-tooltip">
                                                           {term.tooltip}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <button style={{padding: 0, border: 0}}><IoIosInformationCircleOutline/></button>
                                                </OverlayTrigger>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <div className="modal-footer pt-4">
                                    <button type="button" className="btn cancel-button" onClick={hideModal}>Cancel</button>
                                    <button className="btn save-button createCustomerCard" onClick={() => allowTerms()}>Allow</button>
                                </div>
                            </div>
                        }
                        {company && <CompanyConnection
                            hideModal={hideModal}
                            companies={companies}
                        />}
                    </section>
                </div>
            </Modal>
        )
    )
}
export default ClaritaskLogin
