
const initialState = {
    changedColumns: {},
    message: '',
    changedBy: ''
}
export const UpdatesTaskReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGED_COLUMNS':
            return {
                ...state,
                changedColumns: Object.assign({}, state.changedColumns, action.payload),
                changedBy: action.changedBy
            }
        case 'RESET_CHANGED_COLUMNS':
            return {
                ...initialState
            }
        case 'REMOVE_CHANGED_COLUMN':
            const { [action.payload]: _, ...rest } = state.changedColumns;
            return {
                ...state,
                changedColumns: rest,
                changedBy: ''
            }
        default:
            return state
    }
}
