import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseURL } from '../../helpers/baseURL';
import { headers } from '../../helpers/Requests';
import styles from './VerifyEmail.module.css';
import { Spinner, Alert } from 'react-bootstrap';

const VerifyEmail = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
	const [redirect, setRedirect] = useState(null);

	const requestVerifyEmail = async () => {
		try {
			setLoading(true);
			const response = await axios.get(`${baseURL}verify-email`, headers);
			if (response.status === 200) {
				setSuccess('Please check your email for a verification link.');
			} else if (response.status === 201) {
				setSuccess('Your email is already verified. You will be redirected to the dashboard in a few seconds.');
				setRedirect(setTimeout(() => {
					window.location.href = '/dashboard';
				}, 2000));
			}
		} catch (error) {
			if (error.response) {
				const status = error.response.status;
				if (status === 429) {
					setError('Too many requests. Please try again later.');
				} else {
					setError('Something went wrong. Please check your email and try again.');
				}
			} else {
				setError('Unable to connect. Please check your internet connection and try again.');
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (loading) return;
		requestVerifyEmail();
		return () => {
			clearTimeout(redirect);
		};
	}, []);

	const handleRedirect = () => {
		window.location.href = '/dashboard';
	};

	return (
		<div className={styles.container}>
			<h1 className={styles.headers}>Verify Email</h1>
			{success && <Alert variant="success" className={styles.paragraph}>{success}</Alert>}
			{error && <Alert variant="danger" className={styles.paragraph}>{error}</Alert>}
			{loading ? (
				<Spinner animation="border" role="status" className='mt-3 mb-3'>
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			) : redirect ? (
				<div className={styles.footer}>
					<p className={styles.paragraph}>
						If you are not redirected automatically, please click the link below.
					</p>
					<button className='btn btn-primary mt-3' onClick={handleRedirect}>Go to Dashboard</button>
				</div>
			) : ("")}
		</div>
	);
};

export default VerifyEmail;