import React from 'react'
import { Button, Dropdown } from 'react-bootstrap';
import { BsPersonFillCheck, BsFillTrash3Fill } from 'react-icons/bs';
import styles from './TicketsAction.module.css';
import UserBullet from '../../UserBullet';
import { showMultipleDeleteConfirm } from '../../ItemDelete';
import { postRequestCallback } from '../../../helpers/Requests';

const TicketsAction = ({ data, selection, onActionStarted, onActionCompleted }) => {
	const handleDelete = () => {
		showMultipleDeleteConfirm(selection, 'ticket', 'tickets', 'tickets', () => {}, onActionCompleted)
	};

	const handleChangeUser = (userId) => {
		onActionStarted();
		postRequestCallback('tickets-assign', {
			items: selection,
			userId: userId
		}, onActionCompleted);
	};

	return (
		selection.length > 0 &&
		<div className={styles.container}>
			<Button onClick={handleDelete} variant='danger'><BsFillTrash3Fill /></Button>
			<Dropdown>
				<Dropdown.Toggle>
					<BsPersonFillCheck />
				</Dropdown.Toggle>
				<Dropdown.Menu style={{ fontSize: '14px' }}>
					<Dropdown.Header>
						Assign <u>{selection.length}</u> tickets to:
					</Dropdown.Header>
					{data.users.map(user => (
						<Dropdown.Item key={user.id} onClick={() => handleChangeUser(user.id)}>
							<UserBullet user={user} size={25} tooltip={false} />
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

export default TicketsAction;