import React from "react"
import yellowimg from "../../public/images/Pending_Hover.svg"
import blueimg from "../../public/images/New_Hover.svg"
import greyimg from "../../public/images/Grey_Hover.svg"

const WhyUs = () => {
    return(
        <>
            <section className="why-us">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="why-us-div">
                                <h4>Why us?</h4>
                                <h3>The easiest and most efficient ticketing system</h3>
                                <p>Claritick is the easiest and most efficient ticketing system which will help your costumers and clients to document, and monitor requests related to different issues while also managing and organizing the flow of incoming requests.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="why-us-box why-us-first-box">
                                <div className="why-us-icon">
                                    <img src={blueimg} alt=""/>
                                </div>
                                <h3>Organization</h3>
                                <p>Organizes all incoming customer emails by categorizing all types of concerns sent by customers, helps you manage your services and support request processes and catalogues customer service requests.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="why-us-box why-us-second-box">
                                <div className="why-us-icon">
                                    <img src={yellowimg} alt=""/>
                                </div>
                                <h3>Categorization</h3>
                                <p>Manages requests by delegating them to the appropriate expertise of the support team.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="why-us-box">
                                <div className="why-us-icon">
                                    <img src={greyimg} alt=""/>
                                </div>
                                <h3>Team Collaboration</h3>
                                <p>Promotes better team collaboration. It helps you save more time and effort for both sides, the client and the agent. Helps you build a strong relationship and trust with your client.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default WhyUs
