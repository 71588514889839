const initialState = {
    email: {},
};
export const emailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_EMAIL':
            return {
                ...state,
                email: action.payload,
                loading: action.loading ?? false
            }
        default:
            return state
    }
}
