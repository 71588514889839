import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom"
import MainPages from "../MainPages";
import { fetchBlogs, fetchBlogCategories } from "../../js/redux/actions";
import LatestBlog from "./Latest_Blog";
import noimg from "../Blog/noprev.svg"
import "./blog.css"

const Blog = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchBlogs(0, 10, null))
        dispatch(fetchBlogCategories())
    }, [])
    const blogs = useSelector(state => state.blogReducer.blogs)
    const categories = useSelector(state => state.blogCategoriesReducer.categories)

    const filterArticles = (id) => {
        let id_parsed = parseInt(id)
        dispatch(fetchBlogs(0, 10, id_parsed))
    }

    const loadMore = () => {
        let newElements = blogs && blogs.data.length + 3
        dispatch(fetchBlogs(0, newElements, null))
    }
    let lastElement = blogs && blogs.data && blogs.data[blogs.data.length - 1]

    return (
        <MainPages>
            {lastElement &&
                <div className="featured-article">
                    <img src={lastElement.image_original ? lastElement.image_original : noimg} title="" alt="" />
                    <div className="featured-article-title">
                        <div className="post-category-date">
                            {lastElement.categories.length > 0 ? <div className="post-category">{lastElement.categories.name}</div> : ''}
                        </div>
                        <h1>{lastElement.title}</h1>
                        <p>{lastElement.short_description}</p>
                        <div className="post-read-more">
                            <Link to={{ pathname: `blog/${lastElement.slug}`, state: { id: lastElement.id } }} >Read <span className="mydevicon-dropdown-arrow-default"></span></Link>
                        </div>
                    </div>
                </div>
            }

            <LatestBlog />
            <div className="blog-category-articles">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3">
                            <div className="sticky-top">
                                <div className="category-articles-title">Categories</div>
                                <div className="categories-list">
                                    <ul>
                                        <li onClick={() => filterArticles()}>All</li>
                                        {categories && categories.length > 0 && categories.map(category => {
                                            return (
                                                <li onClick={() => filterArticles(category.category_id)} key={`cat${category.category_id}`}>{category.category_name}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9">
                            <div className="articles-holder">
                                <div className="row">
                                    {blogs && blogs.data && blogs.data.map(blog => {
                                        return (
                                            <div className="col-12 col-lg-6 col-xl-4" key={`key${blog.id}`}>
                                                <div className="blog-post-holder">
                                                    <Link to={{ pathname: `blog/${blog.slug}`, state: { id: blog.id } }} >
                                                        <div className="post-image">
                                                            <img src={blog.image ? blog.image : noimg} title="image" alt="image" />
                                                        </div>
                                                        <div className="blog-post-details">
                                                            <div className="post-category-date">
                                                                <div className="post-category">{blog.categories.length == 1 ? blog.categories.map(category => (
                                                                    <span>{category.name}</span>
                                                                )) : blog.categories.length > 1 ? <span>{category.name},</span> : ''}</div>
                                                            </div>
                                                            <h4 className="post-title">{blog.title}</h4>
                                                            <div className="post-short-description">
                                                                <p>{blog.short_description}</p>
                                                            </div>
                                                            <div className="post-read-more">
                                                                Read <span className="mydevicon-dropdown-arrow-default"></span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {blogs && blogs.load_more &&
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="load-more-posts">
                                                <button className="btn btn-load-posts" onClick={() => loadMore()}>Load more articles <i className="mydevicon-long-arrow-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainPages>
    )
}
export default Blog
