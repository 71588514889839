import React from "react"
import FilterComponent from "../Filter/FilterComponent";
import { Col, Row } from "react-bootstrap";
import TimeLogged from "./TimeLogged";
import MostExpensiveTickets from "./MostExpensiveTickets";
import MainReport from "../MainReport";
import { useSelector } from "react-redux";
import { fetchTimeReport } from "../../../../redux/actions";


const Time = () => {
    const dates = useSelector(state => state.getDatesReducer.dates);
    const report = useSelector(state => state.TimeReducerReport.data);
    // console.log(report, 'report', dates, 'dates');
    // return <div>Artan</div>
    return <MainReport title="Timelog Activity" reqFrom="time">
        <Row>
            <Col xs={12} sm={9}>
                <Row>
                    <Col xs={12}>
                        <TimeLogged report={report} dates={dates}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <MostExpensiveTickets report={report}/>
                    </Col>
                    {/*<Col xs={12} md={6} sm={5}>*/}
                    {/*    <MostExpensiveCustomers/>*/}
                    {/*</Col>*/}
                </Row>
            </Col>
            <Col xs={12} sm={3}>
                <FilterComponent url={fetchTimeReport} />
            </Col>
        </Row>
    </MainReport>
}
export default Time
