import React, { useState, useEffect } from 'react';
import Chart from "react-google-charts";

const Tags = (props) => {
    const [tagsData, setTagsData] = useState([['Tag', 'Tickets']]);
    const [slicesColors, setSlicesColors] = useState({});

    useEffect(() => {
        let newTagsData = [['Tag', 'Tickets']];
        let slicesColors = {};
        if (props.tags.tags !== undefined) {
            props.tags.tags.forEach(item => newTagsData.push([item.name, item.tickets_count]));
            props.tags.tags.forEach((item, index) => {
                slicesColors[index] = { color: item.color };
            });
        }
        setTagsData(newTagsData);
        setSlicesColors(slicesColors);
    }, [props.tags]);

    return(
        <div className="header-panel-dashboard bg-white after-top">
            <div className="dashboard-box-chart">
                <Chart
                    width={'100%'}
                    height={270}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={tagsData}
                    fontName={'Montserrat'}
                    options={{
                        title: 'Tags Tickets',
                        titleTextStyle: {color: '#25313C', fontSize: 14, bold: false, fontName: 'Poppins'},
                        slices: slicesColors,
                        chartArea: {
                            left: 30,
                            right: 30,
                            top: 25,
                            bottom: 10
                        },
                    }}
                />
            </div>
        </div>
    )
}
export default Tags
