const initialState = {
    dashboard: {},
    ticketsCount: {},
    ticketsSummary: [],
    loading: false,
};
export const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DASHBOARD':
            return {
                ...state,
                dashboard: action.payload || state.dashboard,
                loading: action.loading ?? false
            }
        case 'GET_TICKETS_COUNT':
            return {
                ...state,
                ticketsCount: action.payload || state.ticketsCount,
                loading: action.loading ?? false
            }
        case 'GET_TICKETS_SUMMARY':
            return {
                ...state,
                ticketsSummary: action.payload || state.ticketsSummary,
                loading: action.loading ?? false
            }
        default:
            return state
    }
}
