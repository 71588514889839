import React from "react";
import MainContainer from "../../../MainContainer";
import LabelsView from "../../../LabelsView";

const Tags = () => {
	return (
		<MainContainer>
			<LabelsView itemType='tags' />
		</MainContainer>
	)
}
export default Tags