import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import styles from './MediaLibrary.module.css';
import { FaCloudUploadAlt, FaEdit, FaTrashAlt, FaRegFolder, FaFolderPlus, FaTimes } from 'react-icons/fa';
import Spinner from 'react-bootstrap/Spinner';
import { baseURL } from "../helpers/baseURL";
import { headers } from '../helpers/Requests';
import { confirmAlert } from "react-confirm-alert";
import moment from 'moment';

const MediaLibrary = ({ show, onClose, onFilesSelected, initialSelectedFiles }) => {

	const fileInputRef = useRef(null);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [filesList, setFilesList] = useState([]);
	const [foldersList, setFoldersList] = useState([]);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [currentFolders, setCurrentFolders] = useState([{ id: 0, name: 'Home' }])
	const [defaultName, setDefaultName] = useState('');

	const handleSave = () => {
		onFilesSelected(selectedFiles);
		onClose();
	};

	const showError = (message) => {
		setError(message);
		setTimeout(() => {
			setError(false);
		}, 3000);
	};

	const selectFile = (fileId) => {
		const isAnyFileRenaming = filesList.some(file => file.rename);
		if (isAnyFileRenaming) {
			return;
		}
		setSelectedFiles(prevState => {
			if (prevState.find(file => file.id === fileId)) {
				return prevState.filter(file => file.id !== fileId);
			} else {
				return [...prevState, filesList.find(file => file.id === fileId)];
			}
		});
	};

	const selectFolder = (folderId) => {
		const isAnyFolderRenaming = foldersList.some(folder => folder.rename);
		if (isAnyFolderRenaming) {
			return;
		}
		const currentFolderIndex = currentFolders.findIndex(folder => folder.id === folderId);
		if (currentFolderIndex === currentFolders.length - 1) {
			return;
		}
		setCurrentFolders(prevState => {
			if (currentFolderIndex !== -1) {
				return prevState.slice(0, currentFolderIndex + 1);
			} else {
				const folder = foldersList.find(folder => folder.id === folderId);
				return folder ? [...prevState, folder] : prevState;
			}
		});
	};

	const handleClose = () => onClose();

	const fetchList = async (currentFolderId) => {
		try {
			setLoading(true);
			const response = await axios.get(`${baseURL}media-library/folders/${currentFolderId}`, headers);
			setFilesList(response.data['files'] ?? [])
			setFoldersList(response.data['child_folders'] ?? [])
			setLoading(false);
		} catch (error) {
			showError(error?.response?.data?.message ?? 'Error fetching files');
			console.error('Error fetching files', error);
			setLoading(false);
		}
	};

	const createFolder = async (folderId, name) => {
		try {
			setLoading(true);
			const response = await axios.post(`${baseURL}media-library/folders`, { folderId, name }, headers);
			toggleEditFolder(0, true, response.data)
		} catch (error) {
			showError(error?.response?.data?.message ?? 'Error fetching files');
			console.error('Error fetching files', error);
			removeEmptyFolder()
		} finally {
			setLoading(false);
		}
	};
	const uploadFile = async (file) => {
		try {
			setLoading(true);
			const folder = currentFolders[currentFolders.length - 1]
			const response = await axios.post(`${baseURL}media-library/files/${folder.id}`, file, headers);
			setFilesList(prevState => [...prevState, response.data.file]);
		} catch (error) {
			showError(error?.response?.data?.message ?? 'Error fetching files');
			console.error('Error fetching files', error);
			removeEmptyFolder()
		} finally {
			setLoading(false);
		}
	};
	const renameItem = async (type, id, name) => {
		setLoading(true);
		try {
			const response = await axios.put(`${baseURL}media-library/${type}/${id}`, { name }, headers);
			if (type == 'folder') {
				toggleEditFolder(id, true, response.data)
			} else {
				toggleEditFile(id, true, response.data)
				changeSelectedFileName(id, name)
			}
		} catch (error) {
			showError(error?.response?.data?.message ?? 'Error renaming files');
			console.error('Error renaming files', error);
		} finally {
			setLoading(false);
		}
	};
	const changeSelectedFileName = (id, name) => {
		setSelectedFiles(prevState => {
			return prevState.map(file => {
				if (file.id === id) {
					file.name = name
				}
				return file
			});
		});
	}
	const deleteItem = async (type, id) => {
		setLoading(true);
		try {
			const response = await axios.delete(`${baseURL}media-library/${type}/${id}`, headers);
			removeFromList(type, id);
			removeFromSelectedFiles(type, response.data.map(Number));
		} catch (error) {
			showError(error?.response?.data?.message ?? 'Error deleting file');
			console.error('Error deleting', error);
		} finally {
			setLoading(false);
		}
	};

	const removeFromList = (type, id) => {
		if (type === 'file') {
			setFilesList(prevState => {
				return prevState.filter(file => file.id !== id);
			});
		} else if (type === 'folder') {
			setFoldersList(prevState => {
				return prevState.filter(folder => folder.id !== id);
			});
		}
	};
	const removeFromSelectedFiles = (type, ids) => {
		if (type === 'file') {
			setSelectedFiles(prevState => {
				return prevState.filter(file => ids.indexOf(file.id) === -1);
			});
		} else if (type === 'folder') {
			setSelectedFiles(prevState => {
				return prevState.filter(file => ids.indexOf(file.folder_id) === -1);
			});
		}
	}

	const handleUploadClick = () => {
		fileInputRef.current.click();
	};
	const handleUploadChange = (event) => {
		let fileInput = event.target;
		let file = fileInput.files[0];
		let file_data = new FormData();
		file_data.append('file', file);
		if (!file) { return; }
		uploadFile(file_data)
		fileInput.value = '';
	}
	const handleCreateFolder = () => {
		setFoldersList(prevState => {
			if (prevState.some(folder => folder.id === 0)) {
				return prevState;
			}
			return [...prevState, {
				id: 0,
				name: '',
				dateCreated: '',
				rename: true
			}];
		});
	}
	const removeEmptyFolder = () => {
		setFoldersList(prevState => {
			return prevState.filter(folder => folder.id !== 0);
		});
	}
	const toggleEditFolder = (folderId, forceOff = false, newData = false) => {
		setFoldersList(prevState => {
			return prevState.map(folder => {
				if (folder.id === +folderId) {
					folder.rename = forceOff ? false : !folder.rename;
					if (newData) {
						folder.id = newData.id;
						folder.name = newData.name;
						folder.updated_at = newData.updated_at;
						folder.created_at = newData.created_at;
						folder.parent_folder_id = newData.parent_folder_id;
					}
				}
				return folder;
			});
		});
	}
	const toggleEditFile = (fileId, forceOff = false, newData = false) => {
		setFilesList(prevState => {
			return prevState.map(file => {
				if (file.id === +fileId) {
					file.rename = forceOff ? false : !file.rename;
					if (newData) {
						file.name = newData.name;
						file.updated_at = newData.updated_at;
					}
				}
				return file;
			});
		});
	}
	const handleDelete = (type, id, name) => {
		confirmAlert({
			title: `Delete ${type} "${name}"?`,
			message: (type == 'folder') ? 'Files in this folder will be permanently lost.' : 'It will be permanently lost.',
			buttons: [
				{
					label: 'Yes',
					onClick: () => deleteItem(type, id)
				},
				{
					label: 'No'
				}
			],
			overlayClassName: "confirm-overlay"
		})
	}
	const handleInputKey = (event) => {
		if (event.key === 'Enter' || event.key === 'Tab' || event.key == "Escape") {
			event.target.blur();
		}
	}
	const handleInputBlur = (event) => {
		let inputId = event.target.id.split('-')[2]
		let inputType = event.target.id.split('-')[0]
		let currentFolder = currentFolders[currentFolders.length - 1]
		let inputValue = event.target.value ?? ((inputType == 'folder') ? 'New Folder' : 'Untitled File');
		// abort edit
		if (inputValue === '') {
			if (defaultName === '') {
				removeEmptyFolder()
			} else {
				event.target.value = defaultName
				setFoldersList(prevState => prevState.map(folder => ({ ...folder, rename: false })));
				setFilesList(prevState => prevState.map(file => ({ ...file, rename: false })));
			}
			return
		}
		// perform action
		if (inputType == 'folder' && inputId == 0) {
			createFolder(currentFolder.id, inputValue)
		} else {
			renameItem(inputType, inputId, inputValue)
		}
	}

	useEffect(() => {
		setSelectedFiles(initialSelectedFiles);
	}, [show, initialSelectedFiles]);

	useEffect(() => {
		if (currentFolders.length > 0) {
			const currentFolder = currentFolders[currentFolders.length - 1];
			fetchList(currentFolder.id);
		}
	}, [currentFolders]);

	useEffect(() => {
		const folderToRename = foldersList.find(folder => folder.rename === true);
		if (folderToRename) {
			const inputElement = document.getElementById(`folder-rename-${folderToRename.id}`);
			if (inputElement) {
				setDefaultName(folderToRename.name)
				inputElement.focus();
			}
		}
	}, [foldersList]);

	useEffect(() => {
		const fileToRename = filesList.find(file => file.rename === true);
		if (fileToRename) {
			const inputElement = document.getElementById(`file-rename-${fileToRename.id}`);
			if (inputElement) {
				setDefaultName(fileToRename.name)
				inputElement.focus();
			}
		}
	}, [filesList]);

	return (
		<Modal show={show} onHide={handleClose} style={{ opacity: (show ? 1 : 0) }} className={styles.modal} centered>
			{error && <div className={styles.error}>{error}</div>}
			{loading && <div className={styles.loading}><Spinner animation="border" /></div>}
			<Modal.Header closeButton>
				<Breadcrumb className={styles.breadcrumb}><b className='me-2'>Media Library:</b>
					{currentFolders?.map((folder, index) => (
						<Breadcrumb.Item
							key={folder.id}
							onClick={() => selectFolder(folder.id)}
							active={index === currentFolders.length - 1}
						>
							{folder.name}
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
			</Modal.Header>
			<Modal.Body className={styles.body}>
				<Container className={styles.container}>
					<Row className={styles.containerRow}>
						<Col sx={12} md={selectedFiles.length > 0 ? 9 : 12} className={styles.tableContainer}>
							<Table className={styles.table} striped hover>
								<thead>
									<tr>
										<th className={styles.tableMinimal}></th>
										<th className={styles.tableMaximal}>Name</th>
										<th className={styles.tableMinimal}>Size</th>
										<th className={styles.tableMinimal}>Modified</th>
										<th className={styles.tableMinimal}>Created</th>
									</tr>
								</thead>
								<tbody>
									{foldersList?.map(folder => (
										<tr key={folder.id}>
											<td onClick={() => selectFolder(folder.id)}><FaRegFolder /></td>
											<td onClick={() => selectFolder(folder.id)} colSpan={2}>
												<input maxLength={48} type='text' id={`folder-rename-${folder.id}`} readOnly={!folder.rename} defaultValue={folder.name} onBlur={handleInputBlur} onKeyDown={handleInputKey} placeholder='Type folder name...' />
											</td>
											<td onClick={() => selectFolder(folder.id)}>{moment(folder.updated_at).format('DD MMM, HH:mm')}</td>
											<td onClick={() => selectFolder(folder.id)} className={styles.actions}>{moment(folder.created_at).format('DD MMM, HH:mm')}
												<div className={styles.actions}>
													<Button variant="dark" onClick={() => toggleEditFolder(folder.id)} ><FaEdit /></Button>
													<Button variant="danger" onClick={() => handleDelete('folder', folder.id, folder.name)} ><FaTrashAlt /></Button>
												</div>
											</td>
										</tr>
									))}
									{filesList?.map(file => (
										<tr key={file.id}>
											<td onClick={() => selectFile(file.id)}>
												<input maxLength={48} type="checkbox" readOnly checked={selectedFiles.some(selectedFile => selectedFile.id === file.id)} />
											</td>
											<td onClick={() => selectFile(file.id)}>
												{ file.thumbnail_path && <img className={styles.thumbnail} src={file.thumbnail_path} alt={file.name} /> }
												<input maxLength={48} type='text' id={`file-rename-${file.id}`} readOnly={!file.rename} defaultValue={file.name} onBlur={handleInputBlur} onKeyDown={handleInputKey} placeholder='Type file name...' />
											</td>
											<td onClick={() => selectFile(file.id)}>{file.size}</td>
											<td onClick={() => selectFile(file.id)}>{moment(file.modified_at).format('DD MMM, HH:mm')}</td>
											<td onClick={() => selectFile(file.id)} className={styles.actions}>{moment(file.created_at).format('DD MMM, HH:mm')}
												<div>
													<Button variant="dark" onClick={() => toggleEditFile(file.id)} ><FaEdit /></Button>
													<Button variant="danger" onClick={() => handleDelete('file', file.id, file.name)} ><FaTrashAlt /></Button>
												</div>
											</td>
										</tr>
									))}
									{foldersList?.length === 0 && filesList?.length === 0 && (
										<tr>
											<td colSpan={6} align='center'>No files found</td>
										</tr>
									)}
								</tbody>
							</Table>
						</Col>
						{
							selectedFiles.length > 0 && (
								<Col sx={12} md={3} className={styles.selected}>
									<div>Selected Files:</div>
									<ul>
										{selectedFiles.map((attachment, index) => (
											<li key={index}>
												<span>{attachment.name}</span>
												<Button variant="light" onClick={() => selectFile(attachment.id)}><FaTimes /></Button>
											</li>
										))}
									</ul>
								</Col>
							)
						}
					</Row>
				</Container>
			</Modal.Body>
			<Modal.Footer className={styles.footerActions}>
				<div>
					<Button variant="light" onClick={() => handleCreateFolder()}>
						<FaFolderPlus />
						New Folder
					</Button>
					<Button variant="light" className={styles['ml-1']} onClick={handleUploadClick}>
						<FaCloudUploadAlt />
						Upload File
						<input
							ref={fileInputRef}
							onChange={handleUploadChange}
							type="file"
							hidden
						/>
					</Button>
				</div>
				<Button variant="primary" onClick={handleSave}>Save Selection</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default MediaLibrary;
