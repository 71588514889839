import React from "react"
import MainPages from "./MainPages";
import feature1 from "../css/img/s1.png"
import feature2 from "../css/img/s2.png"
import feature3 from "../css/img/s3.png"
import feature4 from "../css/img/s4.png"
import feature5 from "../css/img/s5.png"
import feature6 from "../css/img/s6.png"
import SignUp from "./SignUp";
import "./features.css"

const Features = () => {
    return(
        <MainPages>
            <section className="services single-page-features">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="services-box">
                                <div className="services-single-icon-background">
                                    <img src={feature2} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 center-items">
                            <div className="services-details">
                                <h3><span>Ticket Creation </span>through Email</h3>
                                <p>You can connect any mailbox with the ticketing system. This will enable you better
                                    organization and easier communication.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 center-items integrate-account">
                            <div className="services-details text-right">
                                <h3>By department</h3>
                                <p>Each incident will be delegated to the relevant department, this will ensure faster
                                    and more effective support service, at the same time it serves as a great way to
                                    consolidate support requests in one easy-to-manage location.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="services-box integrate-image">
                                <div className="services-single-icon-background">
                                    <img src={feature3}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="services-box">
                                <div className="services-single-icon-background">
                                    <img src={feature1}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 center-items">
                            <div className="services-details">
                                <h3><span>Dynamic Statuses </span>and Tags</h3>
                                <p>You can understand qualitative data and categorize requests by using tags, by
                                    understanding better your customers’ issues you can take your services to the next
                                    level. Dynamic tags are macro-enabled tags that give you access to Facebook-provided
                                    click and impression tags to dynamically receive information on all your marketing
                                    campaign.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 center-items integrate-account">
                            <div className="services-details text-right">
                                <h3>Reporting</h3>
                                <p>Reporting Software that connects with data sources, gathers informationand provides
                                    insights in the form of graphs and charts based on the input data so the user can
                                    find useful information in a fraction of a standard reporting processing time.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="services-box integrate-image">
                                <div className="services-single-icon-background">
                                    <img src={feature4}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="services-box">
                                <div className="services-single-icon-background">
                                    <img src={feature5}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 center-items">
                            <div className="services-details">
                                <h3><span>Integrated with </span>Claritask (PM System)</h3>
                                <p>Integrated project management is the collection of processes that ensure various
                                    elements of projects are properly coordinated. It establishes and manages the
                                    involvement of all relevant stakeholders and resources, according to defined
                                    processes devised from your organization's set of standard processes you can create
                                    your own project management tools in accordance to your needs.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 center-items integrate-account">
                            <div className="services-details text-right">
                                <h3>Timer</h3>
                                <p>Is a tool that helps the support team to monitor their effectiveness. Set a timer for
                                    solving an issue, and see if you can break that record.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="services-box integrate-image">
                                <div className="services-single-icon-background">
                                    <img src={feature6}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SignUp/>
        </MainPages>
    )
}
export  default Features
