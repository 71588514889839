import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import SidebarMenu from './SidebarMenu'
import "./sidebar.css"
import { FaBars } from "react-icons/fa"
import logo from "../../../css/img/claritick_tm_white.svg"
import arrow from "../../../css/img/arrow_down_filter.svg"
import {ModalContext, ModalProvider, ModalRoot} from "react-multi-modal";
import {useDispatch, useSelector} from "react-redux";
import {fetchUser} from "../../redux/actions";
import Workspaces from "../../modals/Workspaces/Workspaces";
import circles from "../../../css/img/window-circles.svg"


const Sidebar = ({ toggleSidebar, isCollapsed }) => {
    const [toggle, setToggle] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchUser())
    }, []);

	const user = useSelector(state => state.userReducer.user);
    const current_workspace = user?.workspaces?.find(workspace => workspace.id === user.current_workspace)

    const showModalWorkspaces = (showModal) => {
        showModal({
            component: Workspaces
        })
    }

    if (!user) {
        return null
    }

    return(
        <>
            <button className="btn toggle-sidemenu-mobile" onClick={() => setToggle(!toggle)}>
                <FaBars/>
            </button>
            <div className={`sidemenu ${isCollapsed ? 'collapsed' : 'expanded'}`}>
                <div className="sidebar-top">
                    <Link className='home-button' to="/dashboard">
                        <img src={logo} alt="logo"/>
                    </Link>
                    <button className='btn toggle-sidemenu' onClick={toggleSidebar}><span></span></button>
                </div>
                <div className={`sidebar-main-elements ${toggle ? 'active' : ''}`}>
                    <div className="sidebar-workspace">
                        <div className="sidebar-workspace-img">
                            <img src={circles} alt="window"/>
                        </div>
                        <ModalProvider>
                            <ModalContext.Consumer>
                                {({ showModal, hideModal }) => (
                                    <>
                                        <div className="workspace-info" onClick={() => showModalWorkspaces(showModal)}>
                                            <h4>{current_workspace?.name || 'N/A'}</h4>
                                            <span>{current_workspace?.title}</span>
                                        </div>
                                        <ModalRoot />
                                    </>
                                )}
                            </ModalContext.Consumer>
                        </ModalProvider>
                    </div>
                    <div className="sidebar-item-list">
                        <SidebarMenu />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Sidebar;
