import React from "react"
import img from "../../../css/img/avatar.png";
import moment from "moment";
import {ModalContext, ModalProvider, ModalRoot} from "react-multi-modal";
import TimerModal from "../../modals/Timer/Timer";
import {confirmAlert} from "react-confirm-alert";
import axios from "axios";
import {baseURL} from "../../helpers/baseURL";
import Cookies from "js-cookie";
import successAlert from "../../helpers/successAlert";
import failAlert from "../../helpers/failAlert";
import {useDispatch} from "react-redux";


function getTimeString(date) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}

const Time = ({time, setTicket, details, hideDelete}) => {
    const dispatch = useDispatch()
    const showModalLog = (showModal, time) => {
        showModal({
            component: TimerModal,
            modalProps: {
                currentState: {
                    id: details.id,
                    title: 'Edit',
                    date_start: new Date(time.date_start),
                    date_end: new Date(time.date_end),
                    startTime: time.start_time ? new Date(time.start_time) : null, 
                    endTime: time.end_time ? new Date(time.end_time) : null,
                    startTimeConverted: time.start_time ? getTimeString(new Date(time.start_time)) : null,
                    endTimeConverted: time.end_time ? getTimeString(new Date(time.end_time)) : null,
                    user_id: time.user_id ? time.user_id : null,
                    time,
                    setTicket
                },
            }
        })
    }
    const deleteTimer = (id) => {
        confirmAlert({
            title: 'Are you sure you want to delete this timer',
            message: '',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios.post(`${baseURL}ticket-timer-delete/${id}`, {}, {headers:{
                                Authorization: `Bearer ${Cookies.get('access_token')}`
                            }}).then(res => {
                                let newTicketTimer = res.data.ticket.ticket_timer;
                                let newTicketTotalTimer = res.data.ticket.total_ticket_timer;
                                setTicket(prevObj => ({...prevObj, ticket_timer: newTicketTimer, total_ticket_timer:newTicketTotalTimer}))
                            }).then(successAlert('Timer deleted')).catch(failAlert)
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ],
            overlayClassName: "confirm-overlay"
        })
    }
    return(
        <div className="each-timer position-relative">
            <div className="time-actions">
                
                    <ModalProvider>
                        <ModalContext.Consumer>
                            {({ showModal, hideModal}) => (
                                <>
                                    <button className="btn" onClick={() => showModalLog(showModal, time, details)}>Edit</button>
                                    <ModalRoot />
                                </>
                            )}
                        </ModalContext.Consumer>
                    </ModalProvider>
               
                {!hideDelete && <button className="btn" onClick={(e) => deleteTimer(time.id)}>Delete</button>}
            </div>
            <div className="d-flex">
                <img src={time.profile_image} alt=""/>
                <div className="timer-user">
                    <h3>{time.name}</h3>
                    <span>{moment(time.date).format('DD MMM, YYYY')}</span>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between start-timer-end">
                <span>Started at {getTimeString(new Date(time.start_time))}</span>
                <span>Ended at {getTimeString(new Date(time.end_time))}</span>
            </div>
            <p className="timer-note-added">{time.extra_note ? time.extra_note : 'No Notes'}</p>
            <div className="timer-duration-ticket">
                <span>{time.duration}</span>
            </div>
        </div>
    )
}
export default Time
