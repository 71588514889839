import React, {useEffect, useState} from "react"
import {getPriorities, getReportPriorities} from "../../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {IoSearchOutline} from "react-icons/io5";

const Priorities = () => {
    const [values, setValue] = useState([])

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getPriorities())
    },[])
    useEffect(() => {
        dispatch(getReportPriorities(values.toString()))
    },[values])

    const priorities = useSelector(state => state.prioritiesReducer.priorities)

    const statsPriority = priorities && priorities.map(priority => {
        return priority.id
    })

    const handleCheckAll = () => {
        if(values.length < priorities.length){
            setValue(statsPriority)
        } else {
            setValue([])
        }
    }

    const handleChange = (id) => {
        if(values.includes(id)){
            setValue(values.filter(value => value != id))
        } else {
            setValue([...values, id])
        }
    };

    return(
        <div>
            {
                priorities &&
                <>
                   <div className="custom-checkbox-wrapper">
                        <div className="custom-checkbox-item">
                            <input type="checkbox" id="check-all-priorities" onChange={handleCheckAll} checked={values.length == priorities.length}/>
                            <label htmlFor="check-all-priorities">
                                <span>{values.length == priorities.length ? "Deselect All" : "Select All"}</span>
                            </label>
                        </div>
                        {priorities.map((priority,index) => {
                            return(
                                <div key={index} className="custom-checkbox-item">
                                    <input type="checkbox"
                                        id={'priority-'+priority.id}
                                        value={priority.id}
                                        checked={values.includes(priority.id)}
                                        onChange={() => handleChange(priority.id)}
                                    />
                                    <label htmlFor={'priority-'+priority.id}>
                                        <span>{priority.name}</span>
                                    </label>
                                </div>
                            )
                        })}
                   </div>
                </>
            }
        </div>
    )
}
export default Priorities
