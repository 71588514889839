import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Link} from "react-router-dom"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { fetchBlogs } from '../../js/redux/actions';
import noimg from "../Blog/noprev.svg"

const LatestBlog = () => {
	const dispatch  = useDispatch()
	useEffect(() => {
		dispatch(fetchBlogs(0, 10, null))
	}, [])
	const { latest_blog } = useSelector(state => state.blogLatest);

	return(
		<div className="blog-recent-articles">
			<div className="container">
				<div className="row">
					<div className="col-12 col-lg-3">
						<div className="recent-articles-title">Recent Articles</div>
					</div>
					<div className="col-12 col-lg-9">
						<div className="articles-holder">
							{latest_blog && 
							<OwlCarousel 
								className='owl-theme' 
								loop margin={10} 
								nav
								dots={false}
							>
								{latest_blog.map(blog => {
									return(
										<div className="blog-post-holder" key={`blog-latest-${blog.id}`}>
											<Link to={{ pathname: `blog/${blog.slug}`, state: { id: blog.id} }} >
												<div className="post-image">
													<img src={blog.image ? blog.image : noimg} title="image" alt="image" />
												</div>
												<div className="blog-post-details">
													<div className="post-category-date">
														<div className="post-category">
															{blog.categories.map((category, index) => (
																<span key={category.id}>{category.name}{index < blog.categories.length - 1 ? ',' : ''}</span>
															))}
														</div>
													</div>
													<h4 className="post-title">{blog.title}</h4>
													<div className="post-short-description">
														<p>{blog.short_description}</p>
													</div>
													<div className="post-read-more">
														Read <span className="mydevicon-dropdown-arrow-default"></span>
													</div>
												</div>
											</Link>
										</div> 
									)
								})}
							</OwlCarousel>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default LatestBlog