import React from "react"
import { Link } from "react-router-dom";
import shape from  "../main/images/shape.svg"
import shape2 from "../main/images/shape2.png"

const FirstSection = () => {
    return(
        <>
            <section className="first-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="first-section-div">
                                <h4 className="h4-main">EXCELLENT <span>SUPPORT</span></h4>
                                <h3 className="h3-main mt-0"><span>Happy</span> Customers</h3>
                                <p className="p-main">Claritick is a ticketing system that enables you to capture and manage request from your customers. By using this platform, you will detect and handle problems faster and more efficiently.</p>
                                <label className="mt-4">
                                    <input type="email" placeholder="Enter your email..."/>
                                        <Link to="/signup">Start your free trial</Link>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                    <img src={shape} className="shape-1" alt="claritick-ilustration"/>
                    <img src={shape2} className="shape-2" alt="claritick-ilustration"/>
            </section>
        </>
    )
}
export default FirstSection
