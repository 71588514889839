import React, { useEffect, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { ucFirst } from '../../../helpers/generic';
import { colorOption, colorValue, colorValueSingle, prefixedValueSingle, imageOption } from '../../SelectComponents';
import DateRange from '../../../helpers/DateRange';

const TicketsFilterItem = ({ filter, value, data, onFilterUpdate, setShowDropdown }) => {
	const dataKey = {
		'status': 'statuses',
		'department': 'departments',
		'type': 'types',
		'priority': 'priorities',
		'tag': 'tags',
		'assignee': 'users',
	}

	const getLabeledOptions = (filter, value) => {
		const options = data[dataKey[filter]]?.map(row => {
			let option = {
				value: filter === 'status' ? row.name : row.id,
				label: ucFirst(row.name),
			};
			if (filter === 'assignee') {
				option.image = row.profile_image;
				option.description = row.email;
			} else {
				option.color = row.color;
			}
			return option;
		}) || [];
		const selected = options.filter(row => {
			if (filter === 'status') {
				return row.value === value;
			} else {
				return Array.isArray(value) ? value.includes(row.value) : row.value === value;
			}
		});
		return { options, selected };
	};

	const parseDateRange = (value) => {
		return value.map(v => {
			const date = new Date(v);
			const year = date.getFullYear();
			const month = ('0' + (date.getMonth() + 1)).slice(-2);
			const day = ('0' + date.getDate()).slice(-2);
			return { value : `${year}-${month}-${day}` };
		});
	}

	const openDropDown = () => {
		setTimeout(() => {
			setShowDropdown(true)
		}, 5);
	}

	let options = [];
	let selected = [];

	switch (filter) {
		case 'text':
			return <Form.Control type="text" value={value || ''} onChange={(e) => onFilterUpdate(filter, e.target.value)} placeholder="Keywords" />;
		case 'status':
		case 'department':
		case 'type':
		case 'priority':
		case 'tag':
			({ options, selected } = getLabeledOptions(filter, value));
			const isMulti = filter !== 'status';
			const selectComponents = isMulti
				? { Option: colorOption, MultiValueLabel: colorValue }
				: { Option: colorOption, SingleValue: colorValueSingle };
			return (
				<Select
					isMulti={isMulti}
					isClearable={true}
					options={options}
					defaultValue={selected}
					components={selectComponents}
					placeholder={`Any ${ucFirst(filter)}`}
					onChange={(value) => onFilterUpdate(filter, value)}
					classNamePrefix="select-component"
					className="flex-grow-1"
				/>
			);
		case 'replied':
		case 'assigned':
		case 'deleted':
			options = filter === 'deleted'
				? [{ value: true, label: 'Yes' }]
				: [{ value: true, label: 'Yes' }, { value: false, label: 'No' }];
			selected = options.find(row => row.value === value);
			return (
				<Select
					isClearable={true}
					options={options}
					defaultValue={selected}
					components={{ SingleValue: prefixedValueSingle }}
					selectedPrefix={`Is ${ucFirst(filter)}?`}
					placeholder={filter === 'deleted' ? 'Is Deleted? No' : `Is ${ucFirst(filter)}? Both`}
					onChange={(selectedOption) => onFilterUpdate(filter, selectedOption ? selectedOption.value : null)}
					classNamePrefix="select-component"
					className="flex-grow-1"
				/>
			);
		case 'assignee':
			({ options, selected } = getLabeledOptions(filter, value));
			return (
				<Select
					isMulti={true}
					isClearable={true}
					options={options}
					defaultValue={selected}
					components={{ Option: imageOption }}
					placeholder={`Any ${ucFirst(filter)}`}
					onChange={(value) => onFilterUpdate(filter, value)}
					classNamePrefix="select-component"
					className="flex-grow-1"
				/>
			);
		case 'due_date': // today, this_week, overdue, range
		case 'created_at': // today, this_week, this_month, range
			options = filter === 'due_date'
				? [{ value: 'today', label: 'Today' }, { value: 'this_week', label: 'This Week' }, { value: 'overdue', label: 'Overdue' }, { value: 'range', label: 'Range' }]
				: [{ value: 'today', label: 'Today' }, { value: 'this_week', label: 'This Week' }, { value: 'this_month', label: 'This Month' }, { value: 'range', label: 'Range' }];
			selected = options.find(row => row.value === value);
			return (
				<Select
					isClearable={true}
					options={options}
					defaultValue={selected}
					components={{ SingleValue: prefixedValueSingle }}
					selectedPrefix={filter === 'due_date' ? 'Due:' : 'Created:'}
					placeholder={filter === 'due_date' ? 'Due Date: Any' : 'Created Date: Any'}
					onChange={(value) => onFilterUpdate(filter, value)}
					classNamePrefix="select-component"
					className="flex-grow-1"
				/>
			);
		case 'due_date_range':
		case 'created_at_range':
			selected = value ? value.map(v => new Date(v + "T00:00:00")) : [];
			return (
				<>
					<DateRange
						handleDateChange={(value) => {
							onFilterUpdate(filter, parseDateRange(value))
						}}
						startDate={selected?.length > 1 ? selected[0] : null}
						endDate={selected?.length > 1 ? selected[1] : null}
					/>
				</>
			)
		default:
			return null;
	}
}

export default TicketsFilterItem