import React, { useState, useEffect, useRef } from 'react';
import { FaCheckSquare, FaEllipsisH, FaPenSquare, FaTrashAlt, FaPencilAlt } from "react-icons/fa";
import { Button, Modal, ListGroup, ListGroupItem, Spinner, Badge, Container, Dropdown, Row, Col, Form, Alert } from 'react-bootstrap';
import { confirmAlert } from "react-confirm-alert";
import styles from "./Cards.module.css"
import plus from "../../../../css/img/plus.svg"
import axios from 'axios';
import { headers } from '../../../helpers/Requests';
import { baseURL } from '../../../helpers/baseURL';

const Cards = ({ viewTitle, viewMode, onSelect, showCardModal, newCard, onLoadingChange }) => {

	const [paymentMethods, setPaymentMethods] = useState([]);
	const [showExtras, setShowExtras] = useState(-1);
	const [isLoading, setIsLoading] = useState(false);
	const [rowLoading, setRowLoading] = useState([]);
	const [actionMessage, setActionMessage] = useState('');
	const [selectedCard, setSelectedCard] = useState('');

	const extrasRefs = useRef({});

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		changeLoadingStatus(true);
		axios.get(`${baseURL}payment-methods`, headers).then(response => {
			setPaymentMethods(processCardsObject(response.data))
		}).catch(error => console.error(error)).finally(() => changeLoadingStatus(false));
	}, [newCard]);

	const changeLoadingStatus = (loadingStatus) => {
		setIsLoading(loadingStatus);
		if (typeof onLoadingChange === 'function') {
			onLoadingChange(loadingStatus);
		}
	}

	useEffect(() => {
		if (viewMode === 'select' && paymentMethods.length === 1) {
			selectMethod(paymentMethods[0]);
		}
	}, [paymentMethods]);

	const processCardsObject = (cards) => {
		return cards.map((card) => {
			card.expiration_date = `${card.expiration_month}/${card.expiration_year.slice(-2)}`;
			if ('state' in card && typeof card.state === 'string') {
				const stateParts = card.state.split(', ');
				card.state = stateParts[0].trim();
				card.country = stateParts[1].trim();
			}
			if ('card_number' in card) {
				card.last_four_numbers = card.card_number.slice(-4).toString();
			}
			if (card.is_primary) {
				selectMethod(card);
			}
			return card;
		});
	};

	const selectMethod = (method) => {
		setSelectedCard(method);
		if (typeof onSelect === 'function') {
			onSelect(method);
		}
	};

	const deletePaymentMethod = (method) => {
		confirmAlert({
			title: `Delete Payment Method`,
			message: `****-****-****-${method.last_four_numbers} will be deleted.`,
			buttons: [
				{
					label: 'Yes',
					onClick: () => {
						clickShowExtras(-1)
						setRowLoading([...rowLoading, method.id]);
						axios.delete(`${baseURL}payment-methods/${method.id}`, headers)
							.then(() => setPaymentMethods(paymentMethods.filter(m => m.id !== method.id)))
							.catch(error => {
								if (error.response && error.response.data && error.response.data.message) {
									setActionMessage(error.response.data.message);
								} else {
									console.error(error);
									setActionMessage('Something went wrong. Please try again later.');
								}
							}).finally(() => setRowLoading(rowLoading.filter(id => id !== method.id)));
					}
				},
				{
					label: 'No'
				}
			],
			overlayClassName: "confirm-overlay"
		})
	};

	const setDefaultPaymentMethod = (method) => {
		clickShowExtras(-1)
		setRowLoading([...rowLoading, method.id]);
		axios.patch(`${baseURL}payment-methods/${method.id}`, {}, headers)
			.then(() => setPaymentMethods(paymentMethods.map(m => m.id === method.id ? { ...m, is_primary: true } : { ...m, is_primary: false })))
			.catch(error => console.error(error)).finally(() => setRowLoading(rowLoading.filter(id => id !== method.id)));
	};

	const clickShowExtras = (index) => {
		setShowExtras(showExtras === index ? -1 : index);
	};

	const handleClickOutside = (event) => {
		const isOutside = Object.keys(extrasRefs.current).every((key) => {
			return extrasRefs.current[key] && !extrasRefs.current[key].contains(event.target);
		});
		if (isOutside) {
			setShowExtras(null);
		}
	};

	return (
		<>
			<Row className='pt-3 pb-3'>
				<Col sm={9} className='text-align-left'>
					<h5>{viewTitle ? viewTitle : "Payment Methods"}</h5>
				</Col>
				<Col sm={3}>
					<Button variant='primary' className='float-end' onClick={() => showCardModal()}>
						<img src={plus} />
						<span className='ms-1'>Add New</span>
					</Button>
				</Col>
			</Row>
			<Row>
				<Col>
					{actionMessage && <Alert variant="danger">{actionMessage}</Alert>}
					<ListGroup>
						{isLoading ? (
							<ListGroup.Item>
								<Spinner animation="border" role="status">
									<span className="visually-hidden">Loading...</span>
								</Spinner>
							</ListGroup.Item>
						) : paymentMethods.length === 0 ? (
							<ListGroupItem>No cards added yet.</ListGroupItem>
						) : (
							paymentMethods.map((method, index) => (
								<ListGroupItem key={index} className={`d-flex justify-content-between align-items-start ${viewMode === 'select' ? styles.selectable : ''} ${method.id === selectedCard.id ? styles.selected : ''}`} onClick={() => selectMethod(method)}>
									<div className='text-align-left'>
										{viewMode == 'select' &&
											<input readOnly type="radio" name="paymentMethod" value={method.id} checked={method.id === selectedCard.id} />
										}
										<div><b>{method.name_on_card}</b> <span>****-****-****-{method.last_four_numbers}</span>{method.is_primary ? <span className='badge badge-pill ms-1 bg-success'><FaCheckSquare /> Default Method</span> : ''}</div>
										<div>Expires: {method.expiration_month}/{method.expiration_year}</div>
									</div>
									{viewMode == 'list' &&
										<div className="position-relative">
											{rowLoading.indexOf(method.id) > -1 ? (
												<Spinner animation="border" role="status"></Spinner>
											) : (
												<button onClick={() => clickShowExtras(index)}>
													<FaEllipsisH />
												</button>
											)}
											<div ref={el => extrasRefs.current[index] = el} className={`extra-actions right ${showExtras == index ? 'd-block' : 'd-none'}`}>
												<Button variant="secondary" className="btn" onClick={() => setDefaultPaymentMethod(method)}>
													<FaCheckSquare /> Set Default
												</Button>
												<Button variant="secondary" className="btn" onClick={() => deletePaymentMethod(method)}>
													<FaTrashAlt /> Delete
												</Button>
											</div>
										</div>
									}
								</ListGroupItem>
							))
						)}
					</ListGroup>
				</Col>
			</Row>
		</>
	)
}

export default Cards
