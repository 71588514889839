import React, { useEffect, useState } from "react"
import { IoAddOutline } from "react-icons/io5";
import Select from "react-select";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { useDispatch } from "react-redux";
import DatePicker from "../../../../helpers/DatePicker";
import { Button } from "react-bootstrap";

import { fetchTasks } from "../../../../redux/actions";


import Modal from "../../../../modals/Modal";
import { baseURL } from "../../../../helpers/baseURL";
import successAlert from "../../../../helpers/successAlert";


const CreateTask = ({ isOpen, hideModal, currentState }) => {
    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [results, setRes] = useState()
    const [workspace, setWorkSpace] = useState()
    const [users, setUsers] = useState()
    const [projects, setProjects] = useState()
    const [isLoading, setLoading] = useState(false);
    let [date_created, setDateCreated] = useState()

    useEffect(() => {
        axios.post(`${baseURL}claritask-tasks`, {}, {
            headers: {
                Authorization: `Bearer ${Cookies.get('access_token')}`
            }
        }).then(res => setRes(res.data.data.company_info))
    }, [])


    const workspaces = results && results.map(workspace => {
        return { value: workspace.workspace_id, label: workspace.workspace_name }
    })

    let workSpaceSelected = results && results.find(work_space => work_space.workspace_id == workspace)

    const work_projects = workSpaceSelected && workSpaceSelected.projects && workSpaceSelected.projects.map(project => {
        return { value: project.project_id, label: project.project_name }
    })

    let selectedProject = workSpaceSelected && workSpaceSelected.projects.find(pro => pro.project_id == projects)

    const claritask_users = selectedProject && selectedProject.users && selectedProject.users.map(user => {
        return { value: user.user_id, label: `${user.first_name} ${user.last_name}` }
    })


    const changeWorkSpace = (e) => {
        setWorkSpace(e.value)
    }
    const changeProjects = (e) => {
        setProjects(e.value)
    }

    const changeUsers = (e) => {
        let us = e.map(user => {
            return {
                user_id: user.value
            }
        })
        setUsers(us)
    }

    const CreateTaskClaritask = (e) => {
        e.preventDefault()
        let workspace_id = workspace
        let project_id = projects
        let due_date = moment(date_created).format("DD-MM-YYYY")
        let id = currentState.details.id

        // console.log({name, users, workspace_id, project_id, due_date})
        setLoading(true)
        axios.post(`${baseURL}store-task/${id}`, { name, users, workspace_id, project_id, due_date }, { headers: { Authorization: `Bearer ${Cookies.get('access_token')}` } })
            .then(res => {
                dispatch(fetchTasks(id))
                hideModal()
                successAlert("Task created successfully")
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            })
    }

    return (
        isOpen && (
            <Modal>
                <div className="modal d-block" onClick={hideModal}>
                    <section className="create-task-modal" onClick={(e) => e.stopPropagation()}>
                        <form onSubmit={(e) => CreateTaskClaritask(e)}>
                            <div className="px-0 modal-header modal-header-claritask align-items-center">
                                <div className="d-block text-center modal-header-claritask-header">
                                    <h5 className="ml-0">Create task</h5>
                                </div>
                                <IoAddOutline onClick={hideModal} className="modal-header-claritask-x" />
                            </div>
                            <div className="modal-body px-0">
                                <div className="claritask-create-div">
                                    <p className="mb-1 font_main_p">Task Name</p>
                                    <textarea onChange={(e) => setName(e.target.value)}></textarea>
                                </div>
                                <div className="claritask-create-div">
                                    <p className="mb-1 font_main_p">Choose workspace</p>
                                    <Select
                                        options={workspaces}
                                        name=""
                                        id="ticketSelect"
                                        onChange={(e) => changeWorkSpace(e)}
                                    />
                                </div>
                                <div className="claritask-create-div">
                                    <p className="mb-1 font_main_p">Choose project</p>
                                    <Select
                                        options={work_projects}
                                        name=""
                                        onChange={(e) => changeProjects(e)}
                                        id="ticketSelect"
                                    />
                                </div>
                                <div className="claritask-create-div">
                                    <p className="mb-1 font_main_p">Assign this task to</p>
                                    <Select
                                        isMulti
                                        id="ticketSelect"
                                        options={claritask_users}
                                        onChange={(e) => changeUsers(e)}
                                    />
                                </div>
                                <div className="claritask-create-div">
                                    <p className="mb-1 font_main_p">Date</p>
                                    <DatePicker
                                        handleDateChange={(value) => setDateCreated(value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="timer-footer-btn" style={{right: 25}}>
                                    <Button className="btn cancelLog" onClick={hideModal}>
                                        Cancel
                                    </Button>
                                    <Button 
                                        className="btn logTime-timer" 
                                        type="submit"
                                        disabled={isLoading}
                                        onClick={!isLoading ? CreateTaskClaritask : null}
                                    >
                                        {isLoading ? 'Creating...' : 'Create Task'}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </Modal>
        )
    )
}
export default CreateTask
