import React, {useState} from "react"
import img from "../../../css/img/claritask2000 px.svg";
import connect from "../../../css/img/connect.svg";
import axios from "axios";
import {baseURL} from "../../helpers/baseURL";
import Cookies from "js-cookie";
import {fetchUser, getApps} from "../../redux/actions";
import {useDispatch} from "react-redux";

const CompanyConnection = ({hideModal, companies}) => {
    const dispatch = useDispatch()
    const [selected, setSelected] = useState(null)

   const submitId = () => {
       let claritask_company_id = selected

       let btn = document.querySelector(".hide-connection-mod")
       axios.post(`${baseURL}sync-link`,{ claritask_company_id},{headers:{Authorization: `Bearer ${Cookies.get('access_token')}`}})
           .then(res => {
               hideModal()
               dispatch(fetchUser())
               dispatch(getApps())
               btn && btn.click()
           })
           .catch((err) => console.log(err))
   }
    return(
        <div className="custom-form-box-terms">
            <div className="modal-header flex-column">
                <img className="m-auto" src={connect} alt="connect"/>
                <h5 className="app-name-hold">Please select which Claritask company do you want to link in <span>Claritick:</span></h5>
            </div>
            <h6>Company:</h6>
            {companies && companies.map(company => (
                <div key={`check-company${company.company_id}`} className="company-list-check" style={{border: selected === company.company_id && "1px solid #18AEE3"}}>
                    <label className="checkRole mb-0">
                        <input className="input-var" name="company" type="radio" value={company.company_id} onChange={() => setSelected(company.company_id)}/>
                        <span className="check-role"></span>
                    </label>
                    <p>{company.company_name}</p>
                </div>
            ))}
            <div className="modal-footer pt-4">
                <button type="button" className="btn cancel-button" onClick={hideModal}>Cancel</button>
                <button disabled={!selected} className="btn save-button createCustomerCard" onClick={() => submitId()}>Finish</button>
            </div>
        </div>
    )
}
export default CompanyConnection
