import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import unassigned from '../../css/img/unassigned.png';

const UserBullet = ({ user, size = 35, tooltip = true, ...props }) => {
    const users = Array.isArray(user) ? user : [user];
    const displayedUsers = users.slice(0, 3);
    const remainingUsers = users.length > 3 ? users.length - 3 : 0;

    const containerStyle = {
        whiteSpace: 'nowrap',
        fontSize: '.9em',
        display: 'flex',
        alignItems: 'center',
    };

    const imageStyle = {
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '5px',
    };

    const labelStyle = {
        paddingLeft: '5px',
    };

    return (
        <div style={containerStyle} {...props}>
            {displayedUsers.map((u, index) => {
                const name = (u === undefined || u === null) ? 'unassigned' : u.name;
                const image =
                    u === undefined || u === null
                        ? unassigned
                        : u.profile_image
                        ? u.profile_image
                        : 'https://ui-avatars.com/api/?name=' + encodeURIComponent(u.name);
                return tooltip ? (
                    <OverlayTrigger key={index} overlay={<Tooltip style={labelStyle}>{name}</Tooltip>}>
                        <img src={image} style={imageStyle} />
                    </OverlayTrigger>
                ) : (
                    <span key={index}>
                        <img src={image} style={imageStyle} />
                        <span style={labelStyle}>{name}</span>
                    </span>
                );
            })}
            {remainingUsers > 0 && (
                <span style={labelStyle}>
                    <img src={'https://ui-avatars.com/api/?name=%2B' + remainingUsers} style={imageStyle} />
                </span>
            )}
        </div>
    );
};

export default UserBullet;
