import React, {useState} from "react"
import {Row, Col} from "react-bootstrap"
import CustomToggle from "../../helpers/CustomToggle";
import {IoTrashOutline} from "react-icons/io5";
import axios from "axios";
import {baseURL} from "../../helpers/baseURL";
import Cookies from "js-cookie";
import {fetchUser, getApps} from "../../redux/actions";
import {useDispatch} from "react-redux";

const AppSettings = ({app, hideModal, user}) => {
    if(app === "No company found from this hash."){
        return <div>Please retry connection, prev connection is lost</div>
    }
    const dispatch = useDispatch()
    const [selected, setSelected] = useState(app && app.company_details[0].company_id)
    const [active, setActive] = useState(user.claritask_active === 1 ? true : false)

    const onChangeToggle = () => {
        setActive(!active)
    }
    const handleUpdate = (e) => {
        e.preventDefault()
        const active_app = active ? 1 : 0
        const claritask_company_id = selected

        if(active_app !== user.claritask_active){
            axios.post(`${baseURL}claritask-active`,{active_app},{headers:{Authorization: `Bearer ${Cookies.get('access_token')}`}})
                .then(res => {
                    dispatch(fetchUser())
                    hideModal()
                })
                .catch((err) => console.log(err))
        }
        axios.post(`${baseURL}sync-link`,{claritask_company_id},{headers:{Authorization: `Bearer ${Cookies.get('access_token')}`}})
            .then(res => {
                dispatch(getApps())
                hideModal()
            })
            .catch((err) => console.log(err))

    }

    const companies = app.company_details[0]
    console.log(companies)
    return(
        <Row>
            <Col xs={12}>
                <form onSubmit={(e) => handleUpdate(e)}>
                    <div className="settings-app-container">
                        <img src={app.logo} alt=""/>
                        <div className="settings-app-container-header">
                            <h1>Settings</h1>
                            <div className="d-flex align-items-center ">
                                <div className="settings-app-deactive">
                                    <input type="checkbox" id="deactiveApp2" name="active" className="custom-checkbox-toggle" onChange={() => onChangeToggle()} checked={active}/>
                                    <CustomToggle
                                        first={1}
                                        second={2}
                                        on={active ? "Deactive" : "Active"}
                                        id="deactiveApp2"
                                    />
                                </div>
                                <button className="btn delete-connection-btn">
                                    Delete
                                    <IoTrashOutline/>
                                </button>
                            </div>
                        </div>
                        <div>
                            <h6 style={{marginBottom: '30px'}}><span className="system-name">Claritick</span> is linked with {app.name} using <span className="system-email-connected">({companies.other_companies[0].user_email})</span> e-mail and is successfully connected to:</h6>
                            <h6>Company:</h6>
                            {companies.other_companies.map(company => (
                                <div key={`check-company${company.company_id}`} className="company-list-check" style={{border: selected === company.company_id && "1px solid #18AEE3"}}>
                                    <label className="checkRole mb-0">
                                        <input className="input-var" name="company" type="radio" value={company.company_id} onChange={() => setSelected(company.company_id)} checked={selected === company.company_id ? true : false}/>
                                        <span className="check-role"></span>
                                    </label>
                                    <p>{company.company_name}</p>
                                </div>
                            ))}
                        </div>
                        <div className="modal-footer pt-4 justify-content-start">
                            <button type="submit" className="btn save-button createCustomerCard">Save</button>
                            <button type="button" className="btn cancel-button" onClick={hideModal}>Cancel</button>
                        </div>
                    </div>
                </form>
            </Col>
        </Row>
    )
}
export default AppSettings
