import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function PrivateRoute({ component: Component, authed, userRole, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => {
				if (!authed) {
					return <Redirect to={{ pathname: process.env.MIX_APP_MYDEV_REDIRECT_URL }} />;
				}
				if (userRole === false) {
					return <Redirect to="/dashboard" />;
				}
				return <Component {...props} {...rest} />;
			}}
		/>
	);
}

export default PrivateRoute;