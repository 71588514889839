const initialState = {
    priorities: [],
};
export const prioritiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PRIORITIES':
            return {
                ...state,
                priorities: action.payload,
                loading: action.loading ?? false
            }
        default:
            return state
    }
}
