import React from "react"
import leftshape from "../main/images/leftshape.svg"
import rightshape from "../main/images/rightshape.svg"
import {Link} from "react-router-dom"

const SignUp = () => {
    return(
        <>
            <section className="sign-up">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sign-up-div">
                                <h3>Wait no longer!</h3>
                                <p>Help your company record and manage cases by providing adequate support and solutions..</p>
                                <a className="login-btn" href={process.env.MIX_APP_MYDEV_REDIRECT_URL}>
                                    Sign In
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={leftshape} className="left-shape"/>
                    <img src={rightshape} className="right-shape"/>
            </section>
        </>
    )
}
export default SignUp
