import React from "react"
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import plus from "../../css/img/plus.svg"
import { FaLock } from "react-icons/fa";

const LimitedNewButton = ({ isLoading, showFormModal, single, reducerKey }) => {

    const isUserLoading = useSelector(state => state.userReducer.loading);
    const user = useSelector(state => state.userReducer.user)
    const history = useHistory()

    const moveToPlan = () => {
        if (!user?.isAdmin) return
        history.push("/subscription/plan")
    }

    return (
        isLoading || isUserLoading ? (
            <Button variant='secondary' className='float-end' disabled>
                <Spinner animation="border" role="status" style={{ width: '1rem', height: '1rem' }} />
            </Button>
        ) : user.subscription?.features[reducerKey]?.used >= user.subscription?.features[reducerKey]?.limit ? (
            <Button variant='secondary' className='float-end' onClick={moveToPlan} disabled={!user?.isAdmin}>
                <FaLock /> <span className='ms-1'>Plan Limit Reached</span>
            </Button>
        ) : (
            <Button variant='primary' className='float-end' onClick={showFormModal}>
                <img src={plus} /> <span className='ms-1'>Add {single}</span>
            </Button>
        )
    )
}

export default LimitedNewButton
