import React, { useState } from 'react';
import MediaLibrary from './MediaLibrary';
import styles from './Attachments.module.css';
import { FaPaperclip, FaTimes } from 'react-icons/fa';
import { Badge, Button } from 'react-bootstrap';

const Attachments = ({ attachments, setAttachments, showAll = true }) => {

	const [showModal, setShowModal] = useState(false);

	const handleFilesSelected = (files) => {
		setAttachments([...files]);
	};

	const handleRemoveAttachment = (indexToRemove) => {
		setAttachments(attachments.filter((_, index) => index !== indexToRemove));
	};

	return (
		<div className={styles.container}>
			<Button variant="secondary" onClick={() => setShowModal(true)}><FaPaperclip /></Button>
			{showModal && <MediaLibrary
				show={showModal}
				onClose={() => setShowModal(false)}
				onFilesSelected={handleFilesSelected}
				initialSelectedFiles={attachments}
			/>}
			{!showAll && attachments.length > 0 && (
				<Badge className='ms-2' bg="info">
					{attachments.length} Attachment{attachments.length > 1 ? 's' : ''}
				</Badge>
			)}
			{showAll && attachments.length > 0 && attachments.map((attachment, index) => (
				<div className={styles.item} key={index}>
					<a href={attachment.public_url} target='_blank'>{attachment.name}</a>
					<Button variant="light" onClick={() => handleRemoveAttachment(index)}><FaTimes /></Button>
				</div>
			))}
		</div>
	);
};

export default Attachments;
