import {companiesReducer} from "./companies";
import {loggedReducer} from "./isLogged";
import {usersReducer} from "./users";
import {teamsReducer} from "./teams";
import {departmentsReducer} from "./departments";
import {ticketsReducer, newTickets, detailsTicket, newComments} from "./Tickets";
import {combineReducers} from "redux";
import {userReducer} from "./user";
import {uploadFileReducerLogo, uploadFileReducerFavicon} from "./uploadImg";
import {uploadFileReducerProfile} from "./profile";
import {generalReducer} from "./settings";
import {typesReducer} from "./types";
import {prioritiesReducer} from "./priorities";
import {tagsReducer} from "./tags";
import {emailsReducer} from "./emails";
import {dashboardReducer} from "./Dashboard";
import {reportsReducer} from "./reports";
import {allTasks} from "./tasks"
import {statusesReducer} from "./statuses";
import {AppsReducer} from "./Apps";
import {OverviewReducer, ActivityReducer, TicketsReducerReport, TeamReducerReport, TimeReducerReport} from "./reports";
import {getDatesReducer} from "./getDates";
import {StatusFilter, TagsFilter, PrioritiesFilter, AgentsFilter} from "./LeftFilter";
import {subscriptionReducer} from "./subscription";
import {imagesReducer} from "./getImages";
import {blogReducer, blogDetailsReducer, blogCategoriesReducer, blogLatest} from "./blogReducer";
import { UpdatesTaskReducer } from "./UpdatesTaskReducer";

const allReducers = combineReducers({
    companiesReducer,
    loggedReducer,
    usersReducer,
    userReducer,
    teamsReducer,
    departmentsReducer,
    ticketsReducer,
    detailsTicket,
    newTickets,
    uploadFileReducerLogo,
    uploadFileReducerFavicon,
    uploadFileReducerProfile,
    generalReducer,
    newComments,
    typesReducer,
    prioritiesReducer,
    tagsReducer,
    statusesReducer,
    emailsReducer,
    dashboardReducer,
    reportsReducer,
    allTasks,
    AppsReducer,
    getDatesReducer,
    OverviewReducer,
    ActivityReducer,
    TicketsReducerReport,
    TeamReducerReport,
    TimeReducerReport,
    StatusFilter,
    AgentsFilter,
    TagsFilter,
    PrioritiesFilter,
    subscriptionReducer,
    imagesReducer,
    blogReducer,
    blogDetailsReducer,
    blogCategoriesReducer,
    blogLatest,
    UpdatesTaskReducer
})
export default allReducers
