import React, {useEffect} from "react"
import FirstSection from "./FirstSection";
import WhyUs from "./WhyUs";
import ServicesMain from "./ServicesMain";
import HappyCustomers from "./HappyCustomers";
import SignUp from "./SignUp";
import "./homepage.css"
import MainPages from "./MainPages";
import Partners from "./partners";

const HomePage = (props) => {
	useEffect(() => {
		
		const params = props.location.search;
		const query = new URLSearchParams(params);
		let param = query.get("pl");
		
		const scrollFunction = () => {
			let nav = document.getElementById('navbar');
			if(nav){
				if (window.pageYOffset > 100) {
					nav.classList.add("aftertx");
				} else {
					nav.classList.remove("aftertx");
				}
			}
		}
		window.removeEventListener('scroll', scrollFunction);
		window.addEventListener('scroll', scrollFunction, { passive: true });
		return () => window.removeEventListener('scroll', scrollFunction);
	},[])
	return(
		<>
			<MainPages>
				<FirstSection/>
				<Partners/>
				<WhyUs/>
				<HappyCustomers/>
				<ServicesMain/>
				<SignUp/>
			</MainPages>
		</>
	)
}
export default HomePage
