import React from 'react';

const ColorBullet = ({ color, text, size }) => {
	
	if (size === undefined) {
		size = 20;
	}

	const containerStyle = {
		whiteSpace: 'nowrap',
		fontSize: '.9em',
	};

	const bulletStyle = {
		backgroundColor: color,
		width: `${size}px`,
		height: `${size}px`,
		borderRadius: '50%',
		display: 'inline-block',
	};

	const labelStyle = {
		paddingLeft: '5px',
		verticalAlign: 'super',
	};

	return (
		<div style={containerStyle}>
			<span style={bulletStyle}></span>
			{text !== undefined && <span style={labelStyle}>{text}</span>}
		</div>
	);
}

export default ColorBullet;