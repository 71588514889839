import React, { Component } from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modal');


class Modal extends Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');

    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
        document.querySelector('body').style.overflow = 'hidden'
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
        document.querySelector('body').style.overflow = 'unset'
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el,
        );
    }
}

export default Modal;
