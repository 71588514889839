import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {fetchUsers, getReportAgents} from "../../../../redux/actions";
import Select from 'react-select';
import {IoIosClose}  from "react-icons/io";
import {IoSearchOutline} from "react-icons/io5";

const Agents = ({url}) => {
    const [selectedUsers, setSelected] = useState([])
    const [value, setValue] = useState([])

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchUsers())
    },[])
    useEffect(() => {
        dispatch(getReportAgents(value.toString()))
    },[value])

    const users = useSelector(state => state.usersReducer.users)


    let userSelect = users && users.map(user => {
        return  { value: user.id, label: user.name }
    })


    const selectUser = (e) => {
        let check = selectedUsers.some(user => user.value === e.value)
       if(check){
           return false
       }

        setSelected([...selectedUsers, e])
        setValue([...value, e.value])
    }

    const deSelect = (user) => {
        setSelected(selectedUsers.filter(userObj => userObj !== user))
        setValue(value.filter(userObj => userObj !== user.value))
    }

    const style = {
        ul:{
            listStyle: "none",
            padding: 0,
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap"
        },
        li: {
            background: "#E6F8FF",
            borderRadius: "5px",
            padding: "5px 10px",
            fontSize: "14px",
            fontWeight: 300,
            fontFamily: "Poppins",
            marginRight: "10px",
            marginBottom: "10px"
        },
        remove: {
            cursor: "pointer"
        }
    }


    return(
        <div>
            <ul style={style.ul}>
                {selectedUsers.length > 0 && selectedUsers.map(user => (
                    <li key={`agentFiler-${user.value}`} style={style.li}>
                        <IoIosClose style={style.remove} onClick={() => deSelect(user)}/>
                        {user.label}
                    </li>
                ))}
            </ul>
            <div className="position-relative search-input-style" style={{marginBottom: "15px"}}>
                <IoSearchOutline style={{zIndex: 1}}/>
                <Select
                    value={selectedUsers.length === 0 ? '' : selectedUsers}
                    placeholder="Search Agent"
                    name="user-select"
                    id="ticketSelect-agents"
                    options={userSelect}
                    onChange={(e) => selectUser(e)}
                />
            </div>
        </div>
    )
}
export default Agents
