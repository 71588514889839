import axios from 'axios';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Col, Row, Container, Tabs, Tab, Spinner, Stack, Accordion, Button } from 'react-bootstrap';
import { BsTags, BsClock, BsReply, BsChatText, BsFileEarmarkText, BsFileEarmarkExcel } from "react-icons/bs";

import Time from "../../Time";
import TimerWatch from './TicketTimer';

import MainContainer from "../../../MainContainer";
import Comment from './Comment';
import Conversations from './Conversations';
import ActivityModal from './ActivityModal';
import TicketProperties from './TicketProperties';
import { TicketActions } from './TicketActions';
import { baseURL } from "../../../../helpers/baseURL";
import { headers } from '../../../../helpers/Requests';
import useEchoUpdateTicket from '../customHooks/useEchoUpdateTicket';
import { useSelector, useDispatch } from 'react-redux';
import LinkTask from '../ticket-components/LinkTask';
import CreateTask from '../ticket-components/CreateTask';
import { Link } from 'react-router-dom';


import Tasks from '../../Tasks';
import { ModalProvider, ModalContext, ModalRoot } from 'react-multi-modal';
import { fetchTasks } from '../../../../redux/actions';


function TicketDetails() {
    useEchoUpdateTicket();
    const { id: ticketId } = useParams();
    const [ticket, setTicket] = useState(null);
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [ticketFound, setTicketFound] = useState(true);
    const [activeKey, setActiveKey] = useState('reply');
    const [key, setKey] = useState('note');
    const dispatch = useDispatch();

    const user = useSelector(state => state?.userReducer?.user);
    const tasks = useSelector(state => state.allTasks?.tasks);


    const fetchRequestsTicketDetails = async (id) => {
        const response = await axios.get(`${baseURL}ticket/${id}`, headers);
        return response?.data?.data;
    };

    const handleSelectTab = (key) => {
        setActiveKey(key); // forward, reply, note
        if(key == 'note') {
            setKey('note');
        }else{
            setKey('replies');
        }
    };

    useEffect(() => {
        handleFetchTicketDetails();
        dispatch(fetchTasks(ticketId));
    }, []);

    useEffect(() => {
        handleFetchTicketDetails();
    }, []);

    async function handleFetchTicketDetails(cb) {
        setIsLoading(true);
        try {
            const data = await fetchRequestsTicketDetails(ticketId);
            setTicket({
                ...data,
                clientUpdated: new Date().getTime(),
            });
        } catch (error) {
            console.error("Failed to fetch ticket details:", error);
            if (error.response && error.response.status === 404) {
                setTicketFound(false);
            }
        } finally {
            setIsLoading(false);
            if (cb) cb();
        }
    }

    const update = () => { };


    const showClaritaskCreate = (showModal, ticket) => {
        showModal({
            component: CreateTask,
            modalProps: {
                currentState: {
                    details: ticket,
                    update: update
                }
            }
        })
    }
    const showClaritaskLink = (showModal) => {
        showModal({
            component: LinkTask,
            modalProps: {
                currentState: {
                    details: ticket,
                    update: update
                }
            }
        })
    }

    if (!ticketFound) {
        return (
            <MainContainer>
                <div className="text-center mt-5">
                    <h1 className="text-danger">Ticket not found</h1>
                    <p>The ticket you are looking for does not exist or has been deleted.</p>
                    <BsFileEarmarkExcel size={50} className="mt-4 text-danger" />
                </div>
            </MainContainer>
        )
    }

    if (typeof tasks === 'undefined') {
        return <div>Undefined !</div>
    }
    const tmpTasks = tasks?.tasks ?? [];
    async function handleFetchTicketDetails(cb) {
        setIsLoading(true);
        try {
            const data = await fetchRequestsTicketDetails(ticketId);
            setTicket({
                ...data,
                clientUpdated: new Date().getTime(),
            });
        } catch (error) {
            console.error("Failed to fetch ticket details:", error);
            if (error.response && error.response.status === 404) {
                setTicketFound(false);
            }
        } finally {
            setIsLoading(false);
            if (cb) cb();
        }
    }

    if (!ticketFound) {
        return (
            <MainContainer>
                <div className="text-center mt-5">
                    <h1 className="text-danger">Ticket not found</h1>
                    <p>The ticket you are looking for does not exist or has been deleted.</p>
                    <BsFileEarmarkExcel size={50} className="mt-4 text-danger" />
                </div>
            </MainContainer>
        )
    }


    return (
        <MainContainer>
            {(isLoading && !ticket) ?
                <Spinner animation="border" role="status" className='mt-3 ms-auto me-auto' size='xlg' />
                :
                <Container fluid>
                    <Row className="py-3">
                        <Col sm={12} md={8} xxl={9}>
                            <div className="d-flex position-relative">
                                <Conversations ticket={ticket} type='ticket' />
                                <Stack direction="horizontal" className='align-items-start position-absolute top-0 end-0'>
                                    <ActivityModal ticket={ticket} />
                                    <TicketActions reports={reports} ticket={ticket} onComplete={handleFetchTicketDetails} />
                                </Stack>
                            </div>
                            <Tabs defaultActiveKey="reply" className='position-relative' onSelect={handleSelectTab}>
                                <Tab eventKey="reply" title={<><BsReply className="me-1 mb-1" size={15} /> Reply</>}>
                                    <Comment type="reply" activeKey={activeKey} ticket={ticket} onComplete={handleFetchTicketDetails} />
                                </Tab>
                                <Tab eventKey="forward" title={<><BsReply className="me-1 mb-1 flip-icon" size={15} /> Forward</>}>
                                    <Comment type="forward" activeKey={activeKey} ticket={ticket} onComplete={handleFetchTicketDetails} />
                                </Tab>
                                <Tab eventKey="note" title={<><BsFileEarmarkText className="me-1 mb-1" size={15} /> Add Note</>}>
                                    <Comment type="note" activeKey={activeKey} ticket={ticket} onComplete={handleFetchTicketDetails} />
                                </Tab>
                            </Tabs>


                            <Tabs
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                 className='mt-3'>
                                <Tab eventKey="replies" title={<><BsChatText className="me-1 mb-1" size={15} /> Conversations</>}>
                                    <Conversations ticket={ticket} type='replies' />
                                </Tab>

                                <Tab eventKey="note" title={<><BsFileEarmarkText className="me-1 mb-1" size={15} /> Notes</>}>
                                    <Conversations ticket={ticket} type='notes' onChange={handleFetchTicketDetails} />
                                </Tab>
                            </Tabs>

                        </Col>
                        <Col sm={12} md={4} xxl={3}>
                            <Accordion defaultActiveKey={['0']} alwaysOpen>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <BsTags className="me-2" size={18} /> Ticket Properties
                                    </Accordion.Header>
                                    <Accordion.Body className='text-start ticket-properties'>
                                        <TicketProperties ticket={ticket} ticketIsLoading={isLoading} onComplete={handleFetchTicketDetails} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <BsClock className="me-2" size={18} /> Time Logs
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <TimerWatch ticket={ticket} setTicket={setTicket} />
                                        <div style={{ maxHeight: 400, overflowY: 'auto', paddingRight: 7 }}>
                                            {(ticket.ticket_timer && ticket.ticket_timer.length > 0) ? ticket.ticket_timer.map(time => <Time setTicket={setTicket} key={time.id} time={time} details={ticket} />) : ''}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <BsTags className="me-2" size={18} /> Claritask
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div style={{ padding: "15px" }}>
                                            {user.activeApp ?
                                                <>
                                                    {(tmpTasks !== undefined && tmpTasks.length == 0) &&
                                                        <>
                                                            <ModalProvider>
                                                                <ModalContext.Consumer>
                                                                    {({ showModal, hideModal }) => (
                                                                        <>
                                                                            <Button className="btn claritask_create" onClick={() => showClaritaskCreate(showModal, ticket)}>
                                                                                <img src={"/images/claritasklogo.svg"} alt="claritask" />
                                                                                Create new task in Claritask
                                                                            </Button>
                                                                            <ModalRoot />
                                                                        </>
                                                                    )}
                                                                </ModalContext.Consumer>
                                                            </ModalProvider>
                                                            <ModalProvider>
                                                                <ModalContext.Consumer>
                                                                    {({ showModal, hideModal }) => (
                                                                        <>
                                                                            <Button className="btn claritask_link" onClick={() => showClaritaskLink(showModal)}>
                                                                                <img src={"/images/claritasklogo.svg"} alt="claritask" />
                                                                                Link to a task in Claritask
                                                                            </Button>
                                                                            <ModalRoot />
                                                                        </>
                                                                    )}
                                                                </ModalContext.Consumer>
                                                            </ModalProvider>
                                                        </>
                                                    }
                                                    <Tasks id={ticket.id} showClaritaskLink={showClaritaskLink} />

                                                    {tmpTasks ? tmpTasks.some(task => task.claritick_status == 1) && <ModalProvider>
                                                        <ModalContext.Consumer>
                                                            {({ showModal, hideModal }) => (
                                                                <>
                                                                    <Button className="btn claritask_create" onClick={() => showClaritaskCreate(showModal, ticket)}>
                                                                        <img src={"/images/claritasklogo.svg"} alt="claritask" />
                                                                        Add new Task
                                                                    </Button>
                                                                    <ModalRoot />
                                                                </>
                                                            )}
                                                        </ModalContext.Consumer>
                                                    </ModalProvider>
                                                        : <img src={null} alt="loader" className="w-100" />
                                                    }
                                                </>
                                                : <Link to="/settings/connect_cc">Integrate</Link>}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            }
        </MainContainer>
    )
    return (
        <MainContainer>
            {(isLoading && !ticket) ?
                <Spinner animation="border" role="status" className='mt-3 ms-auto me-auto' size='xlg' />
                :
                <Container fluid>
                    <Row className="py-3">
                        <Col sm={12} md={8} xxl={9}>
                            <div className="d-flex position-relative">
                                <Conversations ticket={ticket} type='ticket' />
                                <Stack direction="horizontal" className='align-items-start position-absolute top-0 end-0'>
                                    <ActivityModal ticket={ticket} />
                                    <TicketActions reports={reports} ticket={ticket} onComplete={handleFetchTicketDetails} />
                                </Stack>
                            </div>
                            <Tabs defaultActiveKey="reply" className='position-relative' onSelect={handleSelectTab}>
                                <Tab eventKey="reply" title={<><BsReply className="me-1 mb-1" size={15} /> Reply</>}>
                                    <Comment type="reply" activeKey={activeKey} ticket={ticket} onComplete={handleFetchTicketDetails} />
                                </Tab>
                                <Tab eventKey="forward" title={<><BsReply className="me-1 mb-1 flip-icon" size={15} /> Forward</>}>
                                    <Comment type="forward" activeKey={activeKey} ticket={ticket} onComplete={handleFetchTicketDetails} />
                                </Tab>
                                <Tab eventKey="note" title={<><BsFileEarmarkText className="me-1 mb-1" size={15} /> Add Note</>}>
                                    <Comment type="note" activeKey={activeKey} ticket={ticket} onComplete={handleFetchTicketDetails} />
                                </Tab>
                            </Tabs>
                            <Tabs defaultActiveKey="replies" className='mt-3'>
                                <Tab eventKey="replies" title={<><BsChatText className="me-1 mb-1" size={15} /> Conversations</>}>
                                    <Conversations ticket={ticket} type='replies' />
                                </Tab>
                                <Tab eventKey="note" title={<><BsFileEarmarkText className="me-1 mb-1" size={15} /> Notes</>}>
                                    <Conversations ticket={ticket} type='notes' onChange={handleFetchTicketDetails} />
                                </Tab>
                            </Tabs>
                        </Col>
                        <Col sm={12} md={4} xxl={3}>
                            <Accordion defaultActiveKey={['0']} alwaysOpen>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <BsTags className="me-2" size={18} /> Ticket Properties
                                    </Accordion.Header>
                                    <Accordion.Body className='text-start ticket-properties'>
                                        <TicketProperties ticket={ticket} ticketIsLoading={isLoading} onComplete={handleFetchTicketDetails} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <BsClock className="me-2" size={18} /> Time Logs
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <TimerWatch ticket={ticket} setTicket={setTicket} />
                                        <div style={{ maxHeight: 400, overflowY: 'auto', paddingRight: 7 }}>
                                            {(ticket && ticket.ticket_timer && ticket.ticket_timer.length > 0) ? ticket.ticket_timer.map(time => <Time setTicket={setTicket} key={time.id} time={time} details={ticket} />) : ''}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            }
        </MainContainer>
    )
}




export default TicketDetails;
