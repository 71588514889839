import React, {useState} from "react"
import {useHistory} from "react-router";
import axios from "axios";
import {baseURL} from "../../helpers/baseURL";
import Cookies from "js-cookie";
import {fetchDetailTicket} from "../../redux/actions";

const ClaritaskEmail = () => {
    const history = useHistory()
    const [user_email, setEmail] = useState()
    const style = {
        formbox: {
            backgroundColor: '#18aee321',
            padding: '70px'
        }
    }

    const send = (e) => {
        e.preventDefault()
        axios.post(`${baseURL}claritask-email`,{user_email},{headers:{Authorization: `Bearer ${Cookies.get('access_token')}`}})
            .then(res => {
                history.push("/dashboard")
            })
            .catch((error) => console.error(error))
    }

    return(
        <form onSubmit={(e) => send(e)}>
            <section className="center-div">
                <div className="form-box" style={style.formbox}>
                    <h4>Enter Your Email Below</h4>
                    <label>
                        <input type="email" placeholder="Enter Your Email" onChange={(e) => setEmail(e.target.value)}/>
                    </label>
                    <button className="btn register-btn w-100" type="submit">Submit</button>
                </div>
            </section>
        </form>
    )
}
export default ClaritaskEmail
