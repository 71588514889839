
import React from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changedColumns } from '../../../../redux/actions/index';
import { useParams } from 'react-router-dom';



export default function useEchoUpdateTicket() {
    const dispatch = useDispatch();
    const { id: ticketId } = useParams();

    useEffect(() => {
        window.Echo.channel('ticket.' + ticketId).listen('.TicketUpdated', (e) => {
            console.log('i called you from useEchoUpdateTicket', e);
            let b = e?.changedColumns?.reduce((acc, key) => {
                acc[key] = new Date().getTime();
                return acc;
            }, {});
            dispatch(changedColumns(b,e.changedBy));
        });
        return () => {
            window.Echo.leave('ticket.' + ticketId);
        }
    }, []);
}
