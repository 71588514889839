import React from 'react';
import {IoEyeSharp, IoEyeOffOutline} from "react-icons/io5";

class InputTypePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'password',
        }
    }

    handleClickEye = () => {
        this.setState({type: this.state.type === 'text' ? 'password' : 'text' })
    }

    eyeVisibility = (e) => {
       let value = e.target.value
        if(value.length > 0){
            e.target.nextSibling.style.display = "block"
        } else {
            e.target.nextSibling.style.display = "none"
        }
    }

    render() {
        return (
            <div className="position-relative">
                <input type={this.state.type} className="Password_eye" onKeyUp={(e) => this.eyeVisibility(e)}/>
                <span
                    className="d-flex align-items-center"
                    style={{position: "absolute", top: 0, bottom: 0, right: "15px"}}
                    onClick={this.handleClickEye}>
                    {this.state.type === 'text' ? <IoEyeSharp/> : <IoEyeOffOutline/>}</span>
            </div>
        )
    }
}
export default InputTypePassword
