export const companiesReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_COMPANIES':
            return {
                ...state,
                companies: action.payload,
                loading: action.loading ?? false
            }
        default:
            return state
    }
}
