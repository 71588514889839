import React, {useState} from "react"
import logo from "../../../css/img/claritick_tm_dark.svg";
import {Link } from "react-router-dom"
import axios from "axios";
import {baseURL} from "../../helpers/baseURL";
import Cookies from "js-cookie";
import successAlert from "../../helpers/successAlert";
import failAlert from "../../helpers/failAlert";

const ForgotPassword = () => {
    const [email, setEmail] = useState('')

    const resetPassword = (e) => {
        e.preventDefault()
        axios.post(`${baseURL}password/forgot`, {email},{headers:{
                Authorization: `Bearer ${Cookies.get('access_token')}`
            }}).then(res => successAlert('Link is sent successfully')).catch(err => failAlert())
    }
    return(
        <form onSubmit={(e) => resetPassword(e)}>
            <section className="center-div">
                <div className="form-box">
                    <img src={logo} className="new-logo"/>
                    <p className="registration-tx"><strong>Forgot your password?</strong></p>
                    <p className="registration-tx">Receive your unique registration link</p>
                    <input type="email" name="email" placeholder="Your Work Email" onChange={(e) => setEmail(e.target.value)} />
                    <button type="submit" className="w-100 register-btn">Send me the Link</button>
                    <p className="register-tx">Already have an account? <Link to="/login" className="d-inline">Log In </Link></p>
                </div>
            </section>
        </form>
    )
}
export default ForgotPassword
