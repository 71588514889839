import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import logo from "../css/img/claritick_tm_dark.svg";
import Cookies from "js-cookie";
import { fetchUser } from "../js/redux/actions";
import Footer from "./Footer";
import MyDropdown from "../js/components/Dropdown";
import { Navbar, Collapse, Button } from 'react-bootstrap';

const MainPages = (props) => {
	const token = Cookies.get('access_token');
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);
	const user = useSelector((state) => state.userReducer.user);
    const excludeFooter = props?.excludeFooter || false;

	useEffect(() => {
        token && dispatch(fetchUser());
	}, [token]);

	return (
		<div className="main-page-wrapper">
			<header className="main-header" id="navbar">
				<div className="container home-page-container">
					<Navbar expand="lg" className="navbar-light">
						<Navbar.Brand as={NavLink} to="/">
							<img src={logo} alt="Logo" className="logo-main" />
						</Navbar.Brand>
						<Button
							variant=""
							className="navbar-toggler border-0 bg-transparent"
							onClick={() => setShow(!show)}
						>
							<span className="navbar-toggler-icon"></span>
						</Button>
						<Collapse in={show} id="navbarSupportedContent">
							<Navbar.Collapse className="justify-content-end">
								<ul className="navbar-nav mr-auto main-page-navbar">
									<li className="nav-item">
										<NavLink className="nav-NavLink" exact to="/" activeClassName="selectedNavMain" >Home</NavLink>
									</li>
									<li className="nav-item">
										<NavLink className="nav-NavLink" to="/features" activeClassName="selectedNavMain">Features</NavLink>
									</li>
									<li className="nav-item ">
										<NavLink className="nav-NavLink" to="/pricing" activeClassName="selectedNavMain">Pricing</NavLink>
									</li>
									<li className="nav-item ">
										<NavLink className="nav-NavLink" to="/blog" activeClassName="selectedNavMain">Blog</NavLink>
									</li>
								</ul>
								{token ? <><NavLink to="/dashboard" className="register-btn">Dashboard</NavLink><MyDropdown user={user} /></> :
									<form className="form-inline my-2 my-lg-0">
										<div className="account-div">
											<a className="register-btn" href={process.env.MIX_APP_MYDEV_REGISTER_REDIRECT_URL}>
												New Account
											</a>
											<a className="login-btn" href={process.env.MIX_APP_MYDEV_REDIRECT_URL}>
												Sign In
											</a>
										</div>
									</form>
								}
							</Navbar.Collapse>
						</Collapse>
					</Navbar>
				</div>
			</header>
			{props.children}
			{!excludeFooter && <Footer />}
		</div>
	);
};

export default MainPages;
