import React from "react"
// import FilterComponent from "../Filter/FilterComponent";
import { Col, Row } from "react-bootstrap";
// import TicketActivity from "./Ticket_Activity";
// import StatusActivity from "./Status_Activity";
// // import Busiest from "./Busiest";
// import PopularTags from "./Popular_tags";
// import TagTimeSeries from "./tag_timeseries";
// // import ExpensiveCustomers from "./Expensive_Customers";
// import ExpensiveTickets from "./Expensive_Tickets";
import { useSelector, useDispatch } from "react-redux";
import MainReport from "../MainReport";
// import {fetchTicketsReport} from "../../../../redux/actions";
import { useEffect } from "react";
import { fetchDashboard } from "../../../../redux/actions";
import reportTickets from "../../../../../css/img/raport-new.svg"
import DashboardTrends from "../../Dashboard/Trends";
import Tags from "../../Dashboard/Tags";

function firstLetterUppercase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const TicketsReports = () => {
    const dispatch = useDispatch();
    const dates = useSelector(state => state?.getDatesReducer?.dates ?? []);
    const reportSelector = useSelector(state => state?.TicketsReducerReport?.data ?? []);
    const dashboardDataSelector = useSelector(state => state?.dashboardReducer?.dashboard);
    // const { ticketsCount } = dashboardDataSelector || {};
    console.log('dashboardDataSelector:::',dashboardDataSelector);

    if (dashboardDataSelector?.ticketsCount) {
        delete dashboardDataSelector?.ticketsCount['MyTickets'];
    }

    const ticketCountSelector = Object.entries(dashboardDataSelector?.ticketsCount ?? {});
    const ticketsIntervalSelector = dashboardDataSelector?.ticketsInterval;
    const tagsTicketSelector = dashboardDataSelector?.tags;

    useEffect(() => {
        dispatch(fetchDashboard());
    }, []);

    //// Debugging
    // console.log('dashboardDataSelector::', dashboardDataSelector);
    // console.log('reportSelector::', reportSelector);
    //// Debugging

    return (
        <MainReport show={{ filter: false, dates: false }} title={"Tickets Reports"} reqFrom="tickets">
            <Row>
                <Col xs={12} sm={4}>
                    <h4 className="text-left">Status</h4>
                    <div>
                        {
                            //
                            ticketCountSelector.map(([key, value], index) => {
                                return (
                                    <div key={index}
                                        className="d-flex border-bottom align-items-center mb-1 justify-content-between">
                                        <div>{firstLetterUppercase(key)}</div>
                                        <div>{value} <img height="25" src={reportTickets} /></div>
                                    </div>
                                )
                            })
                            //
                        }
                    </div>
                </Col>
                <Col xs={12} sm={4}>
                    <h4 className="text-left">Intervals</h4>
                    <div>
                        <div className="d-flex border-bottom align-items-center mb-1 justify-content-between">
                            <span>Today</span>
                            <span>{ticketsIntervalSelector?.today ?? 0} Tickets</span>
                        </div>
                        <div className="d-flex border-bottom align-items-center mb-1 justify-content-between">
                            <span>Last Month</span>
                            <span>{ticketsIntervalSelector?.last_month ?? 0} Tickets</span>
                        </div>
                        <div className="d-flex align-items-center mb-1 justify-content-between">
                            <span>Last Week</span>
                            <span>{ticketsIntervalSelector?.last_week ?? 0} Tickets</span>
                        </div>
                    </div>
                </Col>

                <Col xs={12} sm={4}>
                    <h4 className="text-left">Tags</h4>

                    {/* <Tags useSampleData={useSampleData} tags={dashboard} /> */}
                    <Tags tags={dashboardDataSelector} />

                </Col>

                <Col xs={12} >
                    <div className="d-flex justify-content-center">
                        {/* {JSON.stringify(dashboardDataSelector?.dailyTickets)} */}
                        {dashboardDataSelector?.dailyTickets && <>
                            <DashboardTrends trends={dashboardDataSelector} />
                        </>}
                    </div>
                </Col>





                {/* {ticketCountSelector.map(([key, value]) => {
                    return (
                        <Col xs={6} md={2} className="report-chart-holder">
                            <div className="reports-ticket-activity">
                                <div className="reports-ticket-activity-header">
                                    <img src={'/images/interacted.svg'} alt="ticket" />
                                    <span>{value}</span>
                                </div>
                                <h6>{key}</h6>
                            </div>
                        </Col>)
                })} */}

                {/* <Col xs={12} >

                    <Row>
                        <Col xs={12} sm={6}>
                            <TicketActivity dates={dates} report={report} />
                        </Col>
                        <Col xs={12} sm={6}>
                            <StatusActivity dates={dates} report={report} />
                        </Col>
                    </Row>
                    {/*<Row>*/}
                {/*    <Col xs={12}>*/}
                {/*        <Busiest dates={dates}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/* <Row>
                        <Col xs={12} sm={6}>
                            <PopularTags dates={dates} report={report} />
                        </Col>
                        <Col xs={12} sm={6}>
                            <TagTimeSeries dates={dates} report={report} />
                        </Col>
                    </Row> */}
                {/* <Row>
                        <Col xs={12}>
                            <ExpensiveTickets dates={dates} report={report} />
                        </Col>
                        {/*<Col xs={12} xl={5}>*/}
                {/*    <ExpensiveCustomers dates={dates}/>*/}
                {/*</Col>*/}
                {/*</Row> */}
                {/*</Col> */}
                {/* <Col xs={12} sm={3}>
                    <FilterComponent url={fetchTicketsReport}/>
                </Col> */}
            </Row>
        </MainReport>
    )
}
export default TicketsReports
