import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { BsActivity } from "react-icons/bs";
import { baseURL } from "../../../../helpers/baseURL";
import { headers } from '../../../../helpers/Requests';
import moment from 'moment';

export default function ActivityModal({ ticket }) {
    const [show, setShow] = useState(false);
    const [activityTimeline, setActivityTimeline] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const refOffset = useRef(0);
    const refContainerList = useRef(null);
    const hasMoreRef = useRef(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchTicketActivity = async (ticketId, offset = 0) => {
        const response = await axios.get(`${baseURL}ticket-activity/${ticketId}?offset=${offset}`, headers);
        return response?.data;
    };

    useEffect(() => {
        if (show) {
            handleLoadMore();
            refContainerList?.current?.addEventListener('scroll', handleScrollContainerList);
            return () => {
                refOffset.current = 0;
                hasMoreRef.current = true;
                setActivityTimeline({});
                refContainerList?.current?.removeEventListener('scroll', handleScrollContainerList);
            }
        }
    }, [show]);

    function handleScrollContainerList() {
        if (hasMoreRef.current && refContainerList.current.scrollTop + refContainerList.current.clientHeight >= refContainerList.current.scrollHeight - 100) {
            handleLoadMore();
        }
    }

    function handleLoadMore() {
        setIsLoading(true);
        fetchTicketActivity(ticket.id, refOffset.current).then((data) => {
            setActivityTimeline((pState) => {
                Object.keys(data).forEach((date) => {
                    if (pState[date]) {
                        pState[date].push(...data[date]);
                    } else {
                        pState[date] = data[date];
                    }
                });
                return {
                    ...pState
                }
            });
            setIsLoading(false);
            let valuesDataFlat = Object.values(data).flat();
            refOffset.current += valuesDataFlat.length;
            if (valuesDataFlat.length < 10) {
                hasMoreRef.current = false;
            }
        });
    }

    return (
        <>
            <Button variant="secondary" className="ms-auto me-1" onClick={handleShow}>
                <BsActivity /> Ticket Activity
            </Button>
            <Modal className="left-aligned-modal d-flex flex-column" show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Activity</Modal.Title>
                </Modal.Header>
                <Modal.Body ref={refContainerList} className="flex-grow-1 overflow-auto px-2 py-3">

                    {!isLoading && Object.keys(activityTimeline).length === 0 && <div style={{ width: '350px' }} className="text-center">No activity found</div>}

                    {Object.keys(activityTimeline).map((date) => {
                        return (
                            <div style={{ width: '350px' }} key={date}>

                                <div className="text-center">{date}</div>
                                {activityTimeline[date].map((activity, index) => {

                                    return (
                                        <div key={index} className="d-flex flex-wrap w-100 align-items-center p-2">
                                            <div className="d-flex align-items-center w-100">
                                                <img src={activity.causer.profile_image} alt="" className="rounded-circle" style={{ width: '30px', height: '30px' }} />
                                                <div className="mx-2 flex-grow-1">
                                                    <div className="d-flex justify-content-between">
                                                        <span className="text-truncate">{activity.causer.name}</span>
                                                        <span className="text-uppercase fw-bold text-truncate">{activity.event}</span>

                                                    </div>
                                                    <div className="text-muted bg-light p-1">
                                                        {activity.description}
                                                        <br/>
                                                        {activity.event === 'merged' && <>
                                                            {activity?.properties?.secondary_ticket_tags_count > 0 && <span className="badge bg-primary">with {activity?.properties?.secondary_ticket_tags_count} Tags</span>}
                                                            {activity?.properties?.secondary_ticket_notes_count > 0 && <span className="badge bg-warning">with {activity?.properties?.secondary_ticket_notes_count} Notes</span>}
                                                            {activity?.properties?.secondary_ticket_comments_count > 0 && <span className="badge bg-info">with {activity?.properties?.secondary_ticket_comments_count} Comments</span>}
                                                        </>}
                                                    </div>
                                                    <div className="fs-7 text-muted text-right">{moment(activity?.created_at).format('HH:mm') ?? ''}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                    {isLoading && <div style={{ width: '350px' }} className="text-center">Loading...</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
