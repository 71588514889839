import React, { useEffect, useState, useRef } from "react";
import Select from 'react-select';
import { Col, Row, Modal, Form, Button, Spinner, Alert } from 'react-bootstrap';
import { useDispatch } from "react-redux";

import Attachments from "../../../components/Attachments"
import CustomEditor from "../../../components/Editor"
import { postRequestCallback } from "../../../helpers/Requests";

import { ucFirst } from '../../../helpers/generic';
import { colorOption, colorValue, colorValueSingle, imageOption } from '../../SelectComponents'
import { BsAsterisk } from "react-icons/bs";
import DatePicker from "../../../helpers/DatePicker";


const TicketModal = ({ auxiliary, reduxRefresh, closeModal }) => {
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const [formData, setFormData] = useState({
		title: '',
		status: null,
		type: null,
		user: null,
		department: null,
		priority: null,
		due_date: null,
		tags: [],
		description: '',
		attachments: [],
	});
	const [attachments, setAttachments] = useState([]);
	const description = useRef('');

	const [errors, setErrors] = useState({
		title: false,
		status: false,
		department: false,
		description: false,
	});

	const [options, setOptions] = useState({
		users: [],
		departments: [],
		priorities: [],
		statuses: [],
		types: [],
		tags: [],
	});

	const parseDate = (value) => {
		const date = new Date(value);
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		return { value: `${year}-${month}-${day}` };
	}

	const handleInputChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};
	const handleSelectChange = (name, value) => {
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const updatedFormData = {
			...formData,
			attachments: attachments.map(attachment => attachment.id),
			description: description.current.getHTML(),
		};
		setFormData(updatedFormData);

		const errors = {
			title: updatedFormData.title.length === 0,
			status: updatedFormData.status === null,
			department: updatedFormData.department === null,
			description: description.current.getText().length === 0,
		};
		setErrors(errors);

		const data = {
			...updatedFormData,
			status_id: updatedFormData.status?.value || null,
			department_id: updatedFormData.department?.value || null,
			priority_id: updatedFormData.priority?.value || null,
			type_id: updatedFormData.type?.value || null,
			user_id: updatedFormData.user?.value || null,
			tags: updatedFormData.tags?.map(tag => tag.value),
		}

		if (!errors.title && !errors.status && !errors.department && !errors.description) {
			setIsLoading(true);
			setErrorMessage('');
            // console.log('data debug::',data);
            let dueDateValue = data.due_date?.value ?? null;
			postRequestCallback('tickets', {
                ...data,
                due_date: dueDateValue
            }, () => {
				reduxRefresh();
				closeModal();
				setIsLoading(false);
			}, () => {
				setErrorMessage('There was an error creating the ticket. Please try again.');
				setIsLoading(false);
			});
		} else {
			setErrorMessage('There are errors in the form.');
		}
	};

	useEffect(() => {
		setOptions({
			users: auxiliary.users.map(user => ({ value: user.id, label: user.name, image: user.profile_image, description: user.email })),
			departments: auxiliary.departments.map(department => ({ value: department.id, label: department.name, color: department.color })),
			priorities: auxiliary.priorities.map(priority => ({ value: priority.id, label: priority.name, color: priority.color })),
			statuses: auxiliary.statuses.map(status => ({ value: status.id, label: ucFirst(status.name), color: status.color })),
			types: auxiliary.types.map(type => ({ value: type.id, label: type.name, color: type.color })),
			tags: auxiliary.tags.map(tag => ({ value: tag.id, label: tag.name, color: tag.color })),
		});
	}, [auxiliary]);

	const predefinedDates = [
		{
		  label: 'Today',
		  value: new Date()
		}, {
		  label: 'Tomorrow',
		  value: new Date(new Date().setDate(new Date().getDate() + 1))
		}, {
		  label: 'Next Week',
		  value: new Date(new Date().setDate(new Date().getDate() + 7))
		}
	]

	return (
		<Form onSubmit={handleSubmit}>
			<Modal show={true} onHide={closeModal} className="tickets-create-modal">
				<Modal.Header closeButton>
					<Modal.Title>New Ticket</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
					<Form.Group controlId="form.title">
						<Form.Label><b>Title</b><BsAsterisk className="required-field" /></Form.Label>
						<Form.Control type="text" value={formData.title} name="title" onChange={handleInputChange} placeholder="Enter title" required isInvalid={errors.title} />
					</Form.Group>
					<Form.Group controlId="form.description" className='mt-3'>
						<Form.Label><b>Description</b><BsAsterisk className="required-field" /></Form.Label>
						<CustomEditor placeholder="Please enter description" ref={description} name="description" isInvalid={errors.description} />
					</Form.Group>
					<Row>
						<Col sm={6}>
							<Form.Group controlId="form.status" className='mt-3'>
								<Form.Label><b>Status</b><BsAsterisk className="required-field" /></Form.Label>
								<Select options={options.statuses} components={{ Option: colorOption, SingleValue: colorValueSingle }} value={formData.status} onChange={(value) => handleSelectChange('status', value)} classNamePrefix="select-component" className={errors.status && "select-component-error"} />
							</Form.Group>
						</Col>
						<Col sm={6}>
							<Form.Group controlId="form.department" className='mt-3'>
								<Form.Label><b>Department</b><BsAsterisk className="required-field" /></Form.Label>
								<Select options={options.departments} components={{ Option: colorOption, SingleValue: colorValueSingle }} value={formData.department} onChange={(value) => handleSelectChange('department', value)} classNamePrefix="select-component" className={errors.department && "select-component-error"} />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							<Form.Group controlId="form.assignee" className='mt-3'>
								<Form.Label><b>Assignee</b></Form.Label>
								<Select options={options.users} components={{ Option: imageOption }} value={formData.user} onChange={(value) => handleSelectChange('user', value)} classNamePrefix="select-component" isClearable={true} />
							</Form.Group>
						</Col>
						<Col sm={6}>
							<Form.Group controlId="form.due_date" className='mt-3'>
								<Form.Label><b>Due Date</b></Form.Label>
								<DatePicker handleDateChange={(value) => handleSelectChange('due_date', parseDate(value)) }/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							<Form.Group controlId="form.type" className='mt-3'>
								<Form.Label><b>Type</b></Form.Label>
								<Select options={options.types} components={{ Option: colorOption, SingleValue: colorValueSingle }} value={formData.type} onChange={(value) => handleSelectChange('type', value)} classNamePrefix="select-component" isClearable={true} />
							</Form.Group>
						</Col>
						<Col sm={6}>
							<Form.Group controlId="form.priority" className='mt-3'>
								<Form.Label><b>Priority</b></Form.Label>
								<Select options={options.priorities} components={{ Option: colorOption, SingleValue: colorValueSingle }} value={formData.priority} onChange={(value) => handleSelectChange('priority', value)} classNamePrefix="select-component" isClearable={true} />
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={6}>
							<Form.Group controlId="form.tags" className='mt-3'>
								<Form.Label><b>Tags</b></Form.Label>
								<Select options={options.tags} components={{ Option: colorOption, MultiValueLabel: colorValue }} value={formData.tags} onChange={(value) => handleSelectChange('tags', value)} classNamePrefix="select-component" isMulti={true} isClearable={true} />
							</Form.Group>
						</Col>
						<Col sm={6}>
							<Form.Group controlId="form.attachments" className='mt-3'>
								<Form.Label><b>Attachments</b></Form.Label>
								<Attachments attachments={attachments} setAttachments={setAttachments} showAll={false} />
							</Form.Group>
						</Col>
					</Row>
				</Modal.Body >
				<Modal.Footer>
					<Button variant="secondary" onClick={closeModal} disabled={isLoading}>
						Cancel
					</Button>
					<Button variant="primary" type="submit" disabled={isLoading} onClick={handleSubmit}>
						{isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Save'}
					</Button>
				</Modal.Footer>
			</Modal >
		</Form>
	);
}

export default TicketModal
