const initialState = {
	user: {},
	workspaces: [],
	loading: false,
};
export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_USER':
			return {
				...state,
				user: action.payload || state.user,
				loading: false
			};
		case 'SET_USER_LOADING':
			return {
				...state,
				loading: action.loading
			};
		case 'ADD_WORKSPACE_TO_USER':
			return {
				...state,
				user: {
					...state.user,
					workspaces: [...state.user.workspaces, action.payload]
				},
				loading: action.loading ?? false
			}
		case 'SET_SEEN_TOUR':
			return {
				...state,
				user: {
					...state.user,
					seen_tour: 1
				},
				loading: action.loading ?? false
			}
		default:
			return state
	}
}