import {toast} from "react-toastify";

toast.configure();
const ToastAlert = (props) => {
    var { text, position, autoClose, type } = props;
    position ??= "bottom-right";
    autoClose ??= 3000;
    type ??= "info";


    const toastOptions = {
        position: position,
        autoClose: autoClose,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    };

    switch (type) {
        case "success":
            toast.success(text, toastOptions);
            break;
        case "alert":
            toast.error(text,   toastOptions);
            break;
        case "info":
        default:
            toast.info(text,    toastOptions);
            break;
    }
};
export default ToastAlert;
