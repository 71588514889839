import React from "react"
import profile from "../../../../../css/img/avatar.png";

const Agents = ({report}) => {
    let agents = report && report.teamPerformance
    return(
        <div className="report-chart-holder mr-0">
            <h4 className="report-chart-titles">Team Performance</h4>
            <div className="table-responsive">
                <table className="table performance-table">
                    <thead>
                        <tr>
                            <th>Agent</th>
                            <th>Replies</th>
                            <th>Ticket Solved</th>
                            {/*<th>Customer Helped</th>*/}
                            {/*<th>RESOLUTION TIME</th>*/}
                            {/*<th>Happiness</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                    {agents ? agents.map(agent => (
                        <tr key={`agent${agent.name}`}>
                            <td>
                                <div>
                                    <img src={profile} alt="user image"/>
                                    <h3>{agent.name}</h3>
                                </div>
                            </td>
                            <td>{agent.replies}</td>
                            <td>{agent.ticket_solved}</td>
                        </tr>
                    )) : 'No data found'}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default Agents

