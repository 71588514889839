import React from "react"
import { components } from 'react-select';
import styles from './SelectComponents.module.css';

export const colorOption = (props) => {
	return (
		<components.Option {...props}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<span className={styles.bullet} style={{ "backgroundColor": props.data.color }}></span><div className={styles.labelWrapper}>{props.data.label} {props.data.description && props.data.description !== '' && <span className={styles.inlineDescription}>{props.data.description}</span>}</div>
			</div>
		</components.Option>
	);
};

export const colorValue = (props) => {
	return (
		<components.MultiValueLabel {...props}>
			{props.data.label} <span className={styles.valueBullet} style={{ "backgroundColor": props.data.color }}></span>
		</components.MultiValueLabel>
	);
}

export const prefixedValueSingle = (props) => {
	return (
		<components.SingleValue {...props}>{props.selectProps.selectedPrefix && <span className={styles.prefixSingle}>{props.selectProps.selectedPrefix}</span>}{props.data.label}</components.SingleValue>
	);
}

export const colorValueSingle = (props) => {
	return (
		<components.SingleValue {...props}><span className={styles.valueBulletSingle} style={{ "backgroundColor": props.data.color }}></span> {props.data.label}</components.SingleValue>
	);
}

export const imageOption = (props) => {
	return (
		<components.Option {...props}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{props.data?.image && <img src={props.data.image} className={styles.image} />}
				<div className={styles.infoContainer}>{props.data.label} {props.data.description && props.data.description !== '' && <span className={styles.description}>{props.data.description}</span>}</div>
			</div>
		</components.Option>
	);
};
