const initialState = {
	statuses: [],
};
export const statusesReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_STATUSES':
			return {
				...state,
				statuses: action.payload,
				loading: action.loading ?? false
			}
		default:
			return state
	}
}
