import React, { useState, useEffect } from 'react';
import { Button, Modal, Spinner, Row, Col, Form, Alert } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import axios from 'axios';
import countries from "./Countries"
import states from "./States"
import { headers } from '../../../helpers/Requests';
import { baseURL } from '../../../helpers/baseURL';

const NewCard = ({ onCreate, showPaymentModal, hidePaymentModal }) => {

	const defaultPaymentMethod = {
		'name_on_card': '',
		'card_number': '',
		'cvv': '',
		'expiration_date': '',
		'country': 'US',
		'city': '',
		'street': '',
		'state': '',
		'zip_code': '',
	}

	const [validated, setValidated] = useState(false);
	const [newPaymentMethod, setNewPaymentMethod] = useState(defaultPaymentMethod);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [errorMessage, setErrorMessage] = useState('');
	const [availableStates, setAvailableStates] = useState(states['US']);

	// handle the change on the form
	useEffect(() => {
		if (newPaymentMethod.country === 'US' || newPaymentMethod.country === 'CA') {
			setAvailableStates(states[newPaymentMethod.country]);
		} else {
			setAvailableStates(null);
		}
	}, [newPaymentMethod.country]);

	const handlePaymentMethodForm = (e) => {
		const { name, value } = e.target;
		setNewPaymentMethod({ ...newPaymentMethod, [name]: value });
	};

	const addPaymentMethod = () => {
		setValidated(false);
		setIsLoading(true);
		let request = axios.post(`${baseURL}payment-methods`, newPaymentMethod, headers);

		request.then(response => {
			onCreate(response.data);
			hideModal();
		}).catch(error => {
			if (error.response && error.response.data) {
				if (error.response.data.errors) {
					setErrors(error.response.data.errors);
				}
				if (error.response.data.message) {
					setErrorMessage(error.response.data.message);
				}
			} else {
				console.error(error);
				setErrorMessage('Something went wrong. Please try again later.');
			}
			setValidated(true);
		}).finally(() => setIsLoading(false));
	};

	const hideModal = () => {
		setErrors({});
		setErrorMessage('');
		setValidated(false);
		setNewPaymentMethod(defaultPaymentMethod);
		hidePaymentModal();
	}

	return (
		<Modal show={showPaymentModal} onHide={() => hidePaymentModal()}>
			<Modal.Header closeButton>
				<Modal.Title>Add New Payment Method</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
					<fieldset disabled={isLoading}>
						<Form.Group controlId='paymentName' className='mb-1'>
							<Form.Label>Cardholder Name:</Form.Label>
							<Form.Control value={newPaymentMethod['name_on_card']} name="name_on_card" onChange={handlePaymentMethodForm} type='text' placeholder='Name on card' required isInvalid={errors.name_on_card} />
							{errors.name_on_card && <Form.Control.Feedback type="invalid">{errors.name_on_card[0]}</Form.Control.Feedback>}
						</Form.Group>
						<Form.Group controlId='paymentNumber' className='pt-2 mb-1'>
							<Form.Label>Card Number:</Form.Label>
							<InputMask value={newPaymentMethod['card_number']} name="card_number" onChange={handlePaymentMethodForm} mask="9999-9999-9999-9999" placeholder="Card number">
								{(inputProps) => (
									<Form.Control {...inputProps} type="text" required isInvalid={errors.card_number} />
								)}
							</InputMask>
							{errors.card_number && <Form.Control.Feedback type="invalid">{errors.card_number[0]}</Form.Control.Feedback>}
						</Form.Group>
						<Row>
							<Col sm='6'>
								<Form.Group controlId='paymentName' className='pt-2 mb-1'>
									<Form.Label>Security Code:</Form.Label>
									<Form.Control value={newPaymentMethod['cvv']} name="cvv" onChange={handlePaymentMethodForm} type='text' placeholder='Enter CVV/CVC code' required isInvalid={errors.cvv} />
									{errors.cvv && <Form.Control.Feedback type="invalid">{errors.cvv[0]}</Form.Control.Feedback>}
								</Form.Group>
							</Col>
							<Col sm='6'>
								<Form.Group controlId='expiryDate' className='pt-2 mb-1'>
									<Form.Label>Expiration Date:</Form.Label>
									<InputMask value={newPaymentMethod['expiration_date']} name="expiration_date" onChange={handlePaymentMethodForm} mask="99/99" placeholder="MM/YY">
										{(inputProps) => <Form.Control {...inputProps} type="text" required isInvalid={errors.expiration_date} />}
									</InputMask>
									{errors.expiration_date && <Form.Control.Feedback type="invalid">{errors.expiration_date[0]}</Form.Control.Feedback>}
								</Form.Group>
							</Col>
						</Row>
						<Form.Group controlId='street' className='pt-2 mb-1'>
							<Form.Label>Street:</Form.Label>
							<Form.Control value={newPaymentMethod['street']} name="street" onChange={handlePaymentMethodForm} type='text' placeholder='Street' required isInvalid={errors.street} />
							{errors.street && <Form.Control.Feedback type="invalid">{errors.street[0]}</Form.Control.Feedback>}
						</Form.Group>
						<Row>
							<Col sm='6'>
								<Form.Group controlId='city' className='pt-2 mb-1'>
									<Form.Label>City:</Form.Label>
									<Form.Control value={newPaymentMethod['city']} name="city" onChange={handlePaymentMethodForm} type='text' placeholder='City' required isInvalid={errors.city} />
									{errors.city && <Form.Control.Feedback type="invalid">{errors.city[0]}</Form.Control.Feedback>}
								</Form.Group>
							</Col>
							<Col sm='6'>
								<Form.Group controlId='state' className='pt-2 mb-1'>
									<Form.Label>State:</Form.Label>
									{availableStates ? (
										<Form.Control as="select" value={newPaymentMethod['state']} name="state" onChange={handlePaymentMethodForm} required isInvalid={errors.state}>
											<option value="">Select a state</option>
											{availableStates.map((state, index) => (
												<option value={state.code} key={index}>{state.name}</option>
											))}
										</Form.Control>
									) : (
										<Form.Control value={newPaymentMethod['state']} name="state" onChange={handlePaymentMethodForm} type='text' placeholder='State' required isInvalid={errors.state} />
									)}
									{errors.state && <Form.Control.Feedback type="invalid">{errors.state[0]}</Form.Control.Feedback>}
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col sm='6'>
								<Form.Group controlId='zip_code' className='pt-2 mb-1'>
									<Form.Label>Zip Code:</Form.Label>
									<Form.Control value={newPaymentMethod['zip_code']} name="zip_code" onChange={handlePaymentMethodForm} type='text' placeholder='Zip Code' required isInvalid={errors.zip_code} />
									{errors.zip_code && <Form.Control.Feedback type="invalid">{errors.zip_code[0]}</Form.Control.Feedback>}
								</Form.Group>
							</Col>
							<Col sm='6'>
								<Form.Group controlId='country' className='pt-2 mb-1'>
									<Form.Label>Country:</Form.Label>
									<Form.Control as="select" value={newPaymentMethod['country']} name="country" onChange={handlePaymentMethodForm} required isInvalid={errors.country}>
										{countries.map((country, index) => (
											<option value={country.code} key={index}>{country.name}</option>
										))}
									</Form.Control>
									{errors.country && <Form.Control.Feedback type="invalid">{errors.country[0]}</Form.Control.Feedback>}
								</Form.Group>
							</Col>
						</Row>
					</fieldset>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='secondary' onClick={() => hidePaymentModal()} disabled={isLoading}>
					Close
				</Button>
				<Button variant='primary' onClick={() => addPaymentMethod()} disabled={isLoading}>
					{isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Save'}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default NewCard
