export const ucFirst = (str) => {
  return str ? str.replace(/(^| )(\w)/g, function (word) {
    return word.toUpperCase();
  }) : '';
};

export const getUserProfileImage = (user) => {
  return user.profile_image ? user.profile_image : 'https://ui-avatars.com/api/?name=' + encodeURIComponent(user.name)
};

export const createMarkup = (htmlContent) => {
  return { __html: htmlContent };
};