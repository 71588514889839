import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, withRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/app.css"
import "../../css/tables.css"
import "../../css/mydevicon-v1.0/style.css"
import Login from "./Pages/Login";
import PrivateRoute from "../helpers/PrivateRoute";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Profile from "./Pages/Profile/Profile";
import SwitchWorkspace from '../modals/Workspaces/SwitchWorkspace';

import Tickets from './Pages/tickets/Tickets';

import Users from "./Pages/Users"
import Departments from "./Pages/Departments";
import Emails from "./Pages/Emails";

import Tags from "./Pages/settings/Tickets/Tags"
import Types from './Pages/settings/Tickets/Types'
import Priority from "./Pages/settings/Tickets/Priority";
import Connection from "./Pages/settings/Connection";

import TicketDetails from "./Pages/tickets/openTickets/TicketDetails";
import Plan from "./Pages/subscription/Plan";
import Payment from "./Pages/subscription/Payment";
import Invoice from "./Pages/subscription/Invoice";
import SignUp from "./Pages/SignUp";

import Overview from "./Pages/reports/Overview/Overview";
import Activity from "./Pages/reports/Activity";
import Team from "./Pages/reports/Team/Team";
import Time from "./Pages/reports/Time/Time";
import TicketsReports from "./Pages/reports/Tickets/Tickets";
// import TestComp from "./Pages/TestComp";

import { Switch, Route, useHistory } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./Store";

import ServerError from "./Pages/ServerError";
import ConfirmPassword from "./Pages/ConfirmPassword";
import ResetPassword from "./Pages/ResetPassword";
import NotFound from "./Pages/NotFound";
import ForgotPassword from "./Pages/ForgotPassword";
import Claritask from "./Pages/Claritask";
import ClaritaskEmail from "./Pages/ClaritaskEmail";
// import RegistrationSuccess from "./Pages/RegistrationSuccess";
// import RegistrationAlreadySuccess from './Pages/RegistrationAlreadySuccess';

import HomePage from "../../main/HomePage";
import Features from "../../main/Features";
import Pricing from "../../main/Pricing";
// import Support from "../../main/Support";
import Blog from '../../main/Blog/Blog';
import BlogDetails from "../../main/Blog/Blog_details";
import { fetchUser } from "../redux/actions";
import VerifyEmail from "./Pages/VerifyEmail";
import DeveloperOption from "./Pages/DeveloperOptions";

function App() {
	const dispatch = useDispatch()
	const loggedIn = useSelector(state => state.loggedReducer);
	useEffect(() => {
		if (loggedIn) {
			dispatch(fetchUser())
		}
	}, [])
	const user = useSelector(state => state?.userReducer?.user);
	return (
		<>
			<div className="App">
				<Switch>
					<Route exact path="/" component={HomePage} />
					<Route exact path="/login" component={Login} />
					<Route exact path="/signup" component={SignUp} />
					<Route exact path="/register/confirm/:id" component={ConfirmPassword} />
					<Route exact path="/password/reset" component={ResetPassword} />
					<Route exact path="/forgot" component={ForgotPassword} />
					<Route exact path="/features" component={Features} />
					<Route exact path="/pricing" component={Pricing} />
					{/* <Route exact path="/support" component={Support}/> */}
					<Route exact path="/blog" component={Blog} />
					<Route exact path="/blog/:id" component={BlogDetails} />
					<Route exact path="/claritask/:id" component={Claritask} />
					<Route exact path="/claritask-email" component={ClaritaskEmail} />

					<PrivateRoute path="/verify-email" authed={loggedIn} component={VerifyEmail} />

					<PrivateRoute path="/switch-workspace" authed={loggedIn} component={SwitchWorkspace} />

					<PrivateRoute path="/dashboard" authed={loggedIn} component={Dashboard} userRole={user?.isAgent} />
					<PrivateRoute path="/profile" authed={loggedIn} component={Profile} userRole={user?.isAgent} />

					<PrivateRoute path="/reports/overview" authed={loggedIn} component={Overview} userRole={user?.isAgent} />
					<PrivateRoute path="/reports/activity" authed={loggedIn} component={Activity} userRole={user?.isAgent} />
					<PrivateRoute path="/reports/tickets" authed={loggedIn} component={TicketsReports} userRole={user?.isAgent} />
					<PrivateRoute path="/reports/team" authed={loggedIn} component={Team} userRole={user?.isAgent} />
					<PrivateRoute path="/reports/time" authed={loggedIn} component={Time} userRole={user?.isAgent} />

					<PrivateRoute exact path="/tickets" authed={loggedIn} component={Tickets} userRole={user?.isAgent} />
					<PrivateRoute exact path="/tickets/:category" authed={loggedIn} component={Tickets} userRole={user?.isAgent} />
					<PrivateRoute path="/ticket/:id(\d+)" authed={loggedIn} component={TicketDetails} userRole={user?.isAgent} />

					<PrivateRoute path="/users" authed={loggedIn} component={Users} userRole={user?.isAdmin} />
					<PrivateRoute path="/departments" authed={loggedIn} component={Departments} userRole={user?.isAdmin} />
					<PrivateRoute path="/emails" authed={loggedIn} component={Emails} userRole={user?.isAdmin} />

					<PrivateRoute path="/subscription/plan" authed={loggedIn} component={Plan} userRole={user?.isAdmin} />
					<PrivateRoute path="/subscription/payment" authed={loggedIn} component={Payment} userRole={user?.isAdmin} />
					<PrivateRoute path="/subscription/invoice" authed={loggedIn} component={Invoice} userRole={user?.isAdmin} />

					<PrivateRoute path="/settings/type" authed={loggedIn} component={Types} userRole={user?.isAdmin} />
					<PrivateRoute path="/settings/priority" authed={loggedIn} component={Priority} userRole={user?.isAdmin} />
					<PrivateRoute path="/settings/tags" authed={loggedIn} component={Tags} userRole={user?.isAdmin} />
					<PrivateRoute path="/settings/connect_cc" authed={loggedIn} component={Connection} userRole={user?.isAdmin} />
                    <PrivateRoute path="/developer/:part?" authed={loggedIn} component={DeveloperOption} userRole={user?.isAdmin} />

					{/* we need a view for general users (role.customer) of each workspace to be able to interact with their tickets */}
					{/* a customer might be in multiple workspaces, so we need to be able to switch between them. */}
					{/* users should be able to create tickets, reply to them, and view a list of thickets */}
					{/* their view should be completely separate from the agent and admin view (different mainContainer component) */}

					<Route path='/404' component={NotFound} />
					<Route exact path="/500" component={ServerError} />
					<Redirect from='*' to='/404' />
				</Switch>
			</div >
		</>
	);
}

export default withRouter(App);

if (document.getElementById('app')) {
	ReactDOM.render(
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>,
		document.getElementById('app')
	);
}
