import React, { useState, useEffect } from 'react';
import { Button, Alert, Container, Row, Col } from 'react-bootstrap';
import { FaReceipt } from "react-icons/fa";
import { headers } from '../../../helpers/Requests';
import { baseURL } from '../../../helpers/baseURL';
import axios from 'axios';
import MainContainer from "../../MainContainer";
import TableView from '../../TableView';

const Invoice = () => {
	const [invoices, setInvoices] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const columns = [
		{ key: 'invoice_no', label: 'Invoice Number' },
		{ key: 'plan', label: 'Plan Name' },
		{ key: 'amount_due', label: 'Amount Due' },
		{ key: 'amount_paid', label: 'Amount Paid' },
		{ key: 'is_paid', label: 'Is Paid' },
		{ key: 'due_date', label: 'Due Date' },
		{ key: '__actions__', label: 'Receipt' }
	];

	const processRows = (rows) => {
		const updatedInvoices = rows.map(row => ({
			...row,
			amount_due: `$${row.amount_due}`,
			amount_paid: `$${row.amount_paid}`,
			is_paid: row.is_paid ? 'Yes' : 'No',
			due_date: row.created_at.replace(' 00:00:00', ''),
			__actions__: () => <Button variant='tertiary' className='small-icon-button' href={row.url} target="_blank"><FaReceipt /></Button>

		}));
		setInvoices(updatedInvoices);
	};

	useEffect(() => {
		fetchInvoices();
	}, []);

	const fetchInvoices = () => {
		setIsLoading(true);
		setErrorMessage('');
		axios.get(`${baseURL}invoice-history`, headers).then(response => {
			processRows(response.data);
		}).catch(error => {
			if (error.response && error.response.status !== 404) {
				console.error(error);
				setErrorMessage('Something went wrong. Please try again later.');
			}
		}).finally(() => setIsLoading(false));
	}

	return (
		<MainContainer>
			<Container fluid="sm">
				<Row className='pt-3 pb-3'>
					<Col className='text-align-left'>
						<h5>Invoice History</h5>
					</Col>
				</Row>
				<Row>
					{errorMessage && <Col><Alert variant="danger">{errorMessage}</Alert></Col>}
					<Col className="overflow-x-auto">
						<TableView rows={invoices} columns={columns} tableClass={'horizontal-line-table'} isLoading={isLoading} />
					</Col>
				</Row>
			</Container>
		</MainContainer>
	);
};

export default Invoice;