function actionButtonsToggle(e, ...icon){
    for(let i = 0; i < icon.length; i++){
        let iconElement = icon[i]
        let isClicked = iconElement.contains(e.target);
        if(isClicked){
            iconElement.nextElementSibling.classList.toggle('d-block')
            iconElement.classList.toggle('colorChange')
        } else {
            iconElement.nextElementSibling.classList.add('d-none');
            iconElement.classList.remove('colorChange')
            iconElement.nextElementSibling.classList.remove('d-block');
        }
    }
}
export default actionButtonsToggle
