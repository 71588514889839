import React, { useEffect } from 'react';
import logo from "../../../main/images/logo.svg";
import "./signup.css"

const Login = () => {
    useEffect(() => {
        window.location.href = process.env.MIX_APP_MYDEV_REDIRECT_URL
    }, []);
    return (
        <>
            <section className="center-div">
                <div className="form-box">
                    <div className="form-box">
                        <img src={logo} className="new-logo" />
                        <p className="credentials">Redirecting to MyDev SSO...</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login