import React, { useEffect, useState } from "react"
import { Col, Row, Container, Button } from "react-bootstrap";
import { BsPencil, BsTrash3 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { fetchDepartments, fetchEmails, fetchUser } from "../../redux/actions";
import MainContainer from "../MainContainer";
import plus from "../../../css/img/plus.svg"
import TableView from '../TableView';
import ItemModal from "../ItemModal";
import { showDeleteConfirm } from "../ItemDelete";
import LimitedNewButton from "../LimitedNewButton";

const Emails = () => {

	const dispatch = useDispatch()
	const [showItemModal, setShowItemModal] = useState(false)
	const [formData, setFormData] = useState({});

	const single = 'Email';
	const itemType = 'emails';
	const prefixAPI = 'emails';

	const defaultFormInputs = {
		id: null,
		name: '',
		email_address: '',
		department: {}
	}

	const columns = [
		{ key: 'name', label: 'Name' },
		{ key: 'email_address', label: 'Email Address' },
		{ key: 'received', label: 'Received Emails' },
		{ key: 'department', label: 'Department', mode: 'badge'},
		{ key: '__actions__', label: '' }
	];

	const processRows = (rows) => {
		if (rows === undefined || Object.keys(rows).length === 0) {
			return [];
		}
		return rows.map(row => ({
			...row,
			__actions__: () => <>
				<Button onClick={() => showFormModal('Edit', row)} variant='tertiary' className='small-icon-button me-2 normal'><BsPencil /></Button>
				<Button onClick={() => showDeleteConfirm(row, single, prefixAPI, reduxRefresh)} variant='tertiary' className='small-icon-button danger'><BsTrash3 /></Button>
			</>
		}));
	};

	const reduxRefresh = () => {
		dispatch(fetchDepartments());
		dispatch(fetchEmails());
		dispatch(fetchUser());
	};

	useEffect(() => {
		reduxRefresh();
	}, []);

	const isLoading = useSelector(state => state.emailsReducer.loading);
	const rows = useSelector(state => {
        let tempEmails = state?.emailsReducer?.email;
        return processRows(tempEmails);
    });

	const showFormModal = (title, item = defaultFormInputs) => {
		setFormData({ title, item })
		setShowItemModal(true);
	};

	return (
		<MainContainer>
			<Container fluid="md">
				<Row className='pt-3 pb-3'>
					<Col sm={6} className='text-align-left'>
						<h5>Incoming Emails</h5>
					</Col>
					<Col sm={6}>
						<Button variant='primary' className='float-end' onClick={() => showFormModal('Add')}>
							<img src={plus} />
							<span className='ms-1'>Add Email</span>
						</Button>
					</Col>
				</Row>
				<Row>
					<Col className="overflow-x-auto">
						<TableView rows={rows} columns={columns} tableClass={'horizontal-line-table bordered-table'} isLoading={isLoading} />
					</Col>
				</Row>
			</Container>
			{
				showItemModal && <ItemModal currentState={formData} itemType={itemType} single={single} prefixAPI={prefixAPI} reduxRefresh={reduxRefresh} closeModal={() => setShowItemModal(false)} />
			}
		</MainContainer>
	);
}
export default Emails
