import React, { useEffect } from 'react';
import logo from "../../../main/images/logo.svg";
import "./signup.css"

const SignUp = () => {
    useEffect(() => {
        window.location.href = process.env.MIX_APP_MYDEV_REGISTER_REDIRECT_URL
    }, []);
    return (
        <>
            <section className="center-div">
                <div className="form-box">
                    <img src={logo} className="new-logo" />
                    <p className="credentials">Redirecting to MyDev SSO...</p>
                </div>
            </section>
        </>
    )
}

export default SignUp