import React, {useEffect, useState} from "react"
import {useHistory} from "react-router";
import axios from "axios";
import {baseURL} from "../../helpers/baseURL";
import Cookies from "js-cookie";
import {IoMdCheckmarkCircle} from "react-icons/io";

const Claritask = ({match}) => {
    const history = useHistory()

    useEffect(() => {
        axios.get(`${baseURL}user`, {headers:{
                Authorization: `Bearer ${Cookies.get('access_token')}`
            }}).then(res => (res.data)).then(user => makeReq(user))
    }, [])

    let makeReq = (user) => {
        let id = match.params.id
        // let email = user.data.email
        axios.post(`${baseURL}claritask/${id}`,{},{headers:{
                Authorization: `Bearer ${Cookies.get('access_token')}`}}).then(() => history.push("/login"))
    }

    const goBackToLogin = () => {
        axios.post(`${baseURL}logout`,{},{headers:{
         Authorization: `Bearer ${Cookies.get('access_token')}`}}).then(() => history.push("/login"))
    }

    const styles = {
        divContainerSuccess:{
            height: '65vh',
            margin: 'auto',
            width: '65vh',
            position: 'absolute',
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: '#4ec54e45',
            border: '1px solid #74a874',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        buttonStyle:{
            width: '170px',
            height: '45px',
            backgroundColor: '#519651',
            color: '#fff',
            marginTop: '30px',
            borderRadius: '30px'
        },
        text:{
            fontSize: '16px',
            fontWeight: 800,
            fontFamily: 'POPPINS'
        },
        icon:{
            fontSize: '75px',
            color: 'green'
        }
    }
    return(
        <div style={styles.divContainerSuccess}>
            <IoMdCheckmarkCircle style={styles.icon}/>
            <h5 style={styles.text}> Linked Successfully with Claritask</h5>
            <button onClick={() => goBackToLogin()} className="btn" style={styles.buttonStyle}>Back</button>
        </div>
    )
}
export default Claritask
