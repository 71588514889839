import React, { useState, useEffect } from 'react';
import { Button, Modal, Card, ListGroup, ListGroupItem, Spinner, Alert, Badge, Container, Row, Col } from 'react-bootstrap';
import MainContainer from "../../MainContainer";
import Cards from './Cards';
import NewCard from './NewCard';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { Link, useHistory } from 'react-router-dom';
import { headers } from '../../../helpers/Requests';
import { baseURL } from '../../../helpers/baseURL';
import axios from 'axios';

const Plan = () => {
	const history = useHistory()
	const [showPlansModal, setShowPlansModal] = useState(false);
	const [planInformation, setPlanInformation] = useState({});
	const [subscriptions, setSubscriptions] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const user = useSelector(state => state.userReducer.user)

	const [showSelectPaymentModal, setShowSelectPaymentModal] = useState(false);
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [newCard, setNewCard] = useState({});
	const [selectedCard, setSelectedCard] = useState({});
	const [selectedPlan, setSelectedPlan] = useState('');
	const [selectedSubscription, setSelectedSubscription] = useState(false);

	const handleLoadingChange = (loadingStatus) => {
		setIsLoading(loadingStatus);
	};

	const handleCardCreated = (card) => {
		setShowPaymentModal(false);
		setNewCard(card);
	};

	const handleSelectSubscription = (subscriptions) => {
		setSelectedSubscription(subscriptions);
		setShowPlansModal(true)
	};

	const handleSelectPlan = (plan) => {
		setSelectedPlan(plan);
		setShowSelectPaymentModal(true);
	};

	const additionalUsersModal = () => {
	}

	useEffect(() => {
		getPlanInformation();
	}, []);

	const getPlanInformation = async () => {
		setIsLoading(true);
		await axios.get(`${baseURL}subscription`, headers).then(response => {
			setPlanInformation(response.data['plan_information']);
			setSubscriptions(response.data['subscriptions'])
		}).catch(error => {
			console.error(error)
			setErrorMessage('Something went wrong. Please try again later.');
		}).finally(() => setIsLoading(false));
	};

	const confirmSelectedCard = () => {
		setShowSelectPaymentModal(false);
		setIsLoading(true);
		setErrorMessage('');
		setSuccessMessage('');
		let request_data = { plan: selectedPlan, card: selectedCard };
		if (selectedSubscription) {
			request_data = { ...request_data, subscription: selectedSubscription };
		}
		axios.patch(`${baseURL}subscription`, request_data, headers)
			.then(() => {
				setSuccessMessage('You have successfully updated your subscription.');
				getPlanInformation()
			})
			.catch(error => {
				if (error.response && error.response.data && error.response.data.message) {
					setErrorMessage(error.response.data.message);
					setShowSelectPaymentModal(false);
					setShowPlansModal(false);
				} else {
					console.error(error);
					setErrorMessage('Something went wrong. Please try again later.');
				}
			}).finally(() => setIsLoading(false));
	}

	const cancelSubscription = (subscription) => {
		confirmAlert({
			title: `Cancel Subscription`,
			message: (subscription['next_billing_date'] != 'N/A' ? `After ${subscription['next_billing_date']} you will not be able to access the features of the platform. ` : ``) + `Are you sure you want to cancel your subscription?`,
			buttons: [
				{
					label: 'Yes',
					onClick: () => {
						setIsLoading(true);
						setErrorMessage('');
						setSuccessMessage('');
						axios.delete(`${baseURL}subscription/${subscription['id']}`, headers)
							.then(() => {
								setSuccessMessage('You have successfully cancelled your subscription.');
								getPlanInformation()
							})
							.catch(error => {
								if (error.response && error.response.data && error.response.data.message) {
									setErrorMessage(error.response.data.message);
								} else {
									console.error(error);
									setErrorMessage('Something went wrong. Please try again later.');
								}
							}).finally(() => setIsLoading(false));
					}
				},
				{
					label: 'No'
				}
			],
			overlayClassName: "confirm-overlay"
		})
	};

	const Subscription = ({ subscription, planInformation }) => {
		return (<ListGroup className='text-align-left mb-3'>
			<ListGroupItem>
				<Row className="align-items-center">
					<Col sm={12}>
						<div>Active Plan: <b>{planInformation.subscription ?? 'Free'}</b></div>
						<div>Workspace: <b>{planInformation.workspace}</b></div>
						<div>{planInformation.payment_status ? "Next Payment" : "Expiration Date"}: <b>{subscription ? subscription.next_billing_date : 'Never'}</b></div>
					</Col>
					<Col sm={12}>
						<Button variant='primary' className='float-end mt-2 ms-2' onClick={() => handleSelectSubscription(subscription)}>
							<span className='ms-1'>Update Subscription</span>
						</Button>
						{
							planInformation.can_cancel && (
								<Button variant='secondary' className='float-end mt-2 ms-2' onClick={() => cancelSubscription(subscription)}>
									<span className='ms-1'>Cancel Subscription</span>
								</Button>
							)
						}
					</Col>
				</Row>
			</ListGroupItem>
		</ListGroup>)
	}

	return (
		<MainContainer>
			<Container fluid="sm">
				<Row className='pt-3 mt-1'>
					<Col className='text-align-left'>
						<h5>Current Plan Information</h5>
					</Col>
				</Row>
				<Row>
					<Col sm={12} className='mt-3'>
						{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
					</Col>
				</Row>
				<Row>
					<Col sm={12} lg={4} className='mt-3'>
						{
							isLoading ? (
								<Spinner as="span" animation="border" size="md" role="status" aria-hidden="true" />
							) : (
								subscriptions?.length > 0 ? (
									subscriptions.map((subscription, index) => (
										<Subscription subscription={subscription} planInformation={planInformation} key={index} />
									))
								) : (
									<Subscription subscription={false} planInformation={planInformation} />
								)
							)
						}
					</Col>
					<Col sm={12} lg={8} className='mt-3'>
						<ListGroup className='text-align-left'>
							<ListGroupItem>
								<Row className="align-items-center">
									<Col sm={4}>
										Users
									</Col>
									<Col sm={8} className='d-flex align-items-center justify-content-between'>
										{isLoading ? (
											<Skeleton />
										) : (
											<>
												<b>{planInformation.users.used} / {planInformation.users.limit}</b>
												<Button className='btn btn-secondary float-end' onClick={additionalUsersModal}>
													<span className='ms-1'>Additional User</span>
												</Button>
											</>
										)}
									</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row className="align-items-center">
									<Col sm={4}>
										Tickets
									</Col>
									<Col sm={8}>
										{isLoading ? (
											<Skeleton />
										) : (
											<>
												<b>{planInformation.tickets.used} / {planInformation.tickets.limit}</b>
											</>
										)}
									</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row className="align-items-center">
									<Col sm={4}>
										Departments
									</Col>
									<Col sm={8}>
										{isLoading ? (
											<Skeleton />
										) : (
											<>
												<b>{planInformation.departments.used} / {planInformation.departments.limit}</b>
											</>
										)}
									</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row className="align-items-center">
									<Col sm={4}>
										Incoming Emails
									</Col>
									<Col sm={8}>
										{isLoading ? (
											<Skeleton />
										) : (
											<>
												<b>{planInformation.emails.used} / {planInformation.emails.limit}</b>
											</>
										)}
									</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row className="align-items-center">
									<Col sm={4}>
										Media Data Storage
									</Col>
									<Col sm={8}>
										{isLoading ? (
											<Skeleton />
										) : (
											<>
												<b>{planInformation.media.used} / {planInformation.media.limit} MB</b>
											</>
										)}
									</Col>
								</Row>
							</ListGroupItem>
							<ListGroupItem>
								<Row className="align-items-center">
									<Col sm={4}>
										Claritask Integration
									</Col>
									<Col sm={8} className='d-flex align-items-center justify-content-between'>
										{isLoading ? (
											<Skeleton />
										) : (
											<>
												{
													planInformation.claritask.used
														?
														<FaCheckCircle className='text-success' />
														:
														<FaExclamationCircle className='text-danger' />
												}
												{planInformation.claritask.limit &&
													<Link className='btn btn-secondary float-end' to="/settings/connect_cc">
														<span className='ms-1'>Connect Claritask</span>
													</Link>
												}
											</>
										)}
									</Col>
								</Row>
							</ListGroupItem>
						</ListGroup>
					</Col>
				</Row>
			</Container>
			<Modal fullscreen="lg-down" className="plans-modal" size="xl" show={showPlansModal} onHide={() => setShowPlansModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Subscription Plans</Modal.Title>
				</Modal.Header>
				<Modal.Body className='pb-0'>
					<Row sm={12} className='ms-1 me-1'>
						{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
					</Row>
					<Row xs={1} md={3} className="text-center">
						{isLoading ?
							<Spinner animation="border" role="status" className='mb-3 ms-auto me-auto' />
							:
							planInformation.update.map((plan) => plan.amount ? (
								<Col key={plan.name}>
									<Card className={"mb-4 rounded-3 shadow-sm" + (plan.name !== planInformation.subscription && plan.name !== "Free" ? " border-primary" : "")}>
										<Card.Header className={"py-3" + (plan.name !== planInformation.subscription && plan.name !== "Free" ? " text-bg-primary" : "")}>
											<h4 className="my-0 fw-normal">{plan.name}</h4>
										</Card.Header>
										<Card.Body>
											<Card.Title className="pricing-card-title">
												${plan.amount}
												<small className="text-muted fw-light">/mo</small>
											</Card.Title>
											<ul className="list-unstyled mt-3 mb-4">
												{[
													{ 'key': 'users', 'name': 'Users', 'type': 'number', 'suffix': '' },
													{ 'key': 'tickets', 'name': 'Tickets', 'type': 'number', 'suffix': '' },
													{ 'key': 'departments', 'name': 'Departments', 'type': 'number', 'suffix': '' },
													{ 'key': 'incoming_email', 'name': 'Incoming Emails', 'type': 'number', 'suffix': '' },
													{ 'key': 'file_uploads', 'name': 'Media Data Storage', 'type': 'number', 'suffix': 'MB' },
													{ 'key': 'support', 'name': 'Full Support', 'type': 'check', 'suffix': '' },
													{ 'key': 'claritask_connect', 'name': 'Claritask Integration', 'type': 'check', 'suffix': '' },
												].map((feature, index) => (
													<li key={index}>
														{
															feature['type'] === 'number' ? (
																<>{feature['name']}: <b>{plan[feature['key']].toLocaleString('en-US')} {feature['suffix']}</b></>
															) : (
																plan[feature['key']] === 1
																	?
																	<>{feature['name']} <FaCheckCircle className='text-success' /></>
																	:
																	<>{feature['name']} <FaExclamationCircle className='text-danger' /></>
															)
														}
														{
															plan['off_limits'].indexOf(feature['key']) !== -1 &&
															<Badge className='badge bg-warning text-dark ms-1'><FaExclamationCircle />Off Limit</Badge>
														}
													</li>
												))}
											</ul>
											<Button disabled={!plan.can_update || plan.name === planInformation.subscription} variant={plan.can_update ? "primary" : "outline-secondary"} size="lg" className='w-100' block onClick={() => handleSelectPlan(plan.name_code)}>
												{
													plan.name === planInformation.subscription ? "Current Plan" : !plan.can_update ? "Usage Exceeded" : "Update"
												}
											</Button>
										</Card.Body>
									</Card>
								</Col>
							) : plan.amount === null ? (<Col key={plan.name}>
								<Card className={"mb-4 rounded-3 shadow-sm"}>
									<Card.Header className={"py-3 text-bg-primary"}>
										<h4 className="my-0 fw-normal">{plan.name}</h4>
									</Card.Header>
									<Card.Body>
										<div className='mt-3'>Need More?</div>
										<div className='h5 pb-3'>Let's Talk.</div>
										<div className='my-3 py-3'>Our team will be glad to assist you.</div>
										<Button variant="primary" href='https://kore.co/contact' size="lg" className='w-100 mt-4' block>Contact Us</Button>
									</Card.Body>
								</Card>
							</Col>
							) : (
								"" // Free Plan
							))
						}
					</Row>
				</Modal.Body>
			</Modal>
			<Modal fullscreen="lg-down" className="plans-modal" size="xl" show={showSelectPaymentModal} onHide={() => setShowSelectPaymentModal(false)}>
				<Modal.Body>
					<Cards viewTitle="Select Payment Method" viewMode="select" onSelect={(card) => setSelectedCard(card)} showCardModal={() => setShowPaymentModal(true)} newCard={newCard} onLoadingChange={handleLoadingChange} />
					<NewCard showPaymentModal={showPaymentModal} onCreate={handleCardCreated} hidePaymentModal={() => setShowPaymentModal(false)} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={() => setShowSelectPaymentModal(false)} disabled={isLoading}>
						Cancel
					</Button>
					<Button variant='primary' onClick={() => confirmSelectedCard()} disabled={isLoading}>
						{isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Make Payment'}
					</Button>
				</Modal.Footer>
			</Modal>
		</MainContainer >
	);
};

export default Plan;