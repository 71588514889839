import {toast} from "react-toastify";

toast.configure();
const failAlert = (props) =>
    toast.error(`${props}`, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
});
export default failAlert
