import React from 'react'

const FormRule = () => {
  return (
    <div style={{
        'borderBottom': '1px solid #eaeef1',
        'margin': '5px 0'
    }}></div>
  )
}

export default FormRule
