import React, { useState } from "react"
import Modal from "../Modal";
import { IoMdClose } from "react-icons/io";
import WorkSpaceRadio from "./WorkSpaceRadio";
import WorkSpaceEntry from "./WorkSpaceEntry";
import plus from "../../../css/img/plus.svg"
import { addWorkspaceToUser, fetchUser } from "../../redux/actions"
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FiAlertTriangle } from "react-icons/fi";

const Workspaces = ({ isOpen, hideModal }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const user = useSelector(state => state.userReducer.user)
	const [showItemModal, setShowItemModal] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [editingItem, setEditingItem] = useState({});
	const [selected, setSelected] = useState(user.current_workspace);
	const [error, setError] = useState('');

	const attachNewWorkspace = (workspace) => {
		dispatch(addWorkspaceToUser(workspace));
	}
	const refreshUser = () => {
		dispatch(fetchUser());
	}

	const handleWorkspaceChange = (workspaceId) => {
		setSelected(workspaceId);
		history.push(`/switch-workspace`);
	};

	const handleAddWorkspace = () => {
		setEditingItem({});
		setIsEditing(false);
		setShowItemModal(true);
	}

	const handleEditWorkspace = (workspace) => {
		setEditingItem(workspace);
		setIsEditing(true);
		setShowItemModal(true);
	}

	const showError = (message) => {
		setError(message);
		setTimeout(() => {
			setError('');
		}, 3000);
	}

    console.log("user", user);

	return (
		isOpen && (
			<Modal>
				<div className="modal d-block" onClick={hideModal} style={{ zIndex: 5 }}>
					<div className="workspace-modal" onClick={(e) => e.stopPropagation()}>
						<div className="modal-header modal-header-workspace">
							<h4>Workspaces</h4>
							<div onClick={hideModal}>
								<IoMdClose />
							</div>
						</div>
						{error != '' && <div className="text-danger d-flex justify-content-center pt-3 px-3"><span className="pe-2 fs14"><FiAlertTriangle /></span> {error}</div>}
						<div className="modal-body p-0">
							<div style={{ padding: "30px 30px 0" }}>
								{user && user?.workspaces && user.workspaces.map((workspace, index) => {
									return (
										<WorkSpaceRadio
											key={index}
											selected={selected}
											onChange={handleWorkspaceChange}
											onError={showError}
											onRemove={refreshUser}
											editItem={() => handleEditWorkspace(workspace)}
											id={workspace.id}
											name={workspace.name}
											accepted={workspace.accepted}
											title={workspace.title}
											isAdmin={workspace.isAdmin}
											active={workspace.active}
											plan={workspace.plan}
										/>
									)
								})}
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn adm-addNew" onClick={handleAddWorkspace}><img src={plus} /> Create Workspace</button>
						</div>
						<WorkSpaceEntry showModal={showItemModal} closeModal={() => setShowItemModal(false)} onAdd={attachNewWorkspace} onEdit={refreshUser} isEditing={isEditing} item={editingItem} />
					</div>
				</div>
			</Modal>
		)
	)
}
export default Workspaces
