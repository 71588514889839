import React, {useState} from "react"
import {Carousel} from "react-bootstrap";
import img from "../../../css/img/test-img.jpg"
import {IoIosArrowDropleftCircle, IoIosArrowDroprightCircle} from "react-icons/io";

const AppCarousel = () => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return(
        <>
            <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                className="carousel-height"
                indicators={false}
                prevIcon={<IoIosArrowDropleftCircle/> }
                nextIcon={<IoIosArrowDroprightCircle/>}
            >
                <Carousel.Item>
                    <img
                        className="d-block w-100 slider-img-app"
                        src={img}
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 slider-img-app"
                        src={img}
                        alt="First slide"
                    />
                </Carousel.Item>
            </Carousel>
        </>
    )
}
export default AppCarousel
