export const tourSteps = [
    {
        selector: ".trends-tickets",
        content: "View your weekly ticket stats here."
    },
    {
        selector: ".notification-header-panel",
        content: 'Your Notification Panel.',
    },
    {
        selector: ".tickets-filter-table",
        content: "A quick overview of your tickets. You can filter and view tickets by status."
    },
    {
        selector: ".tickets-largest-departments",
        content: "See your top departments."
    },
    {
        selector: ".tickets-top-tags",
        content: "Discover your most used tags."
    },
    {
        selector: ".profile-panel-header",
        content: 'Adjust account settings here.',
    }
];

export const fakeDashboard = {
    avgFirstResponseTime: "12 minutes",
    avgResponseTime: "5 minutes",
    closedTickets: 720,
    receivedTickets: 735,
    dailyTickets: {
        monday: 10,
        tuesday: 8,
        wednesday: 12,
        thursday: 15,
        friday: 16,
        saturday: 7,
        sunday: 8
    },
    ticketsCount: {
        open: 32,
        active: 9,
        MyTickets: 42,
        pending: 10,
    },
    ticketsOverview: [{
        id: 1,
        title: "Problem with placing my order",
        status: {
            name: "Open",
            color: "#6bcef8"
        },
        department: {
            name: "Sales",
            color: "#66BB6A"
        },
    }, {
        id: 2,
        title: "The website is slow",
        status: {
            name: "Active",
            color: "#79e275"
        },
        department: {
            name: "IT Support",
            color: "#757575"
        },
        user: {
            name: "Noah Smith",
            profile_image: ""
        }
    }, {
        id: 3,
        title: "The promotional video is not working",
        status: {
            name: "Active",
            color: "#79e275"
        },
        department: {
            name: "Marketing",
            color: "#42A5F5"
        },
        user: {
            name: "Jasmine Kim",
            profile_image: ""
        }
    }, {
        id: 4,
        title: "Payment is pending for my order",
        status: {
            name: "Pending",
            color: "#f9d6b5"
        },
        department: {
            name: "Sales",
            color: "#66BB6A"
        },
        user: {
            name: "Fred Brown",
            profile_image: ""
        }
    }],
    departments: [{
        name: "IT Support",
        color: "#757575",
        tickets_count: 20
    }, {
        name: "Sales",
        color: "#66BB6A",
        tickets_count: 5
    }, {
        name: "Marketing",
        color: "#42A5F5",
        tickets_count: 3
    }],
    tags: [{
        name: "Urgent",
        color: "#F44336",
        tickets_count: 10
    }, {
        name: "Needs Attention",
        color: "#FFA000",
        tickets_count: 26
    }, {
        name: "Under Review",
        color: "#00ACC1",
        tickets_count: 5
    }, {
        name: "Inquiry",
        color: "#5E35B1",
        tickets_count: 2
    }, {
        name: "Feedback",
        color: "#4CAF50",
        tickets_count: 12
    }]
}