import React, {useEffect, useState} from "react";
import {Col, Row, Table} from "react-bootstrap";
import {ModalContext, ModalProvider, ModalRoot} from "react-multi-modal";
import ConnectionModal from "../../../modals/Connection/ConnectionModal";
import claritask from "../../../../css/img/claritask2000 px.svg"
import CustomToggle from "../../../helpers/CustomToggle";
import {IoTrashOutline} from "react-icons/io5";
import {HiOutlineDocumentSearch} from "react-icons/hi";
import {useDispatch, useSelector} from "react-redux";
import {fetchUser, getApps, getTypes} from "../../../redux/actions";
import Loader from "react-loader-spinner";
import axios from "axios";
import {baseURL} from "../../../helpers/baseURL";
import Cookies from "js-cookie";
import {confirmAlert} from "react-confirm-alert";
import {postRequest} from "../../../helpers/Requests";
import MainContainer from "../../MainContainer";
import plus from "../../../../css/img/plus.svg"

const Connection = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getApps())
    },[])

    const connected  = useSelector(state => state.AppsReducer.apps)
    const user = useSelector(state => state.userReducer.user)

    if(user === undefined){
        return(
            <MainContainer>
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 80px)'}}>
                    <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                </div>
            </MainContainer>
        )
    }

    const ShowCreateModal = (showModal) => {
        showModal({
            component: ConnectionModal,
            modalProps: {
                currentState: {
                    title: 'Create',
                    user: user
                },
            }
        })
    }
    const ShowEditModal = (showModal, connected) => {
        showModal({
            component: ConnectionModal,
            modalProps: {
                currentState: {
                    title: 'Edit',
                    connection: connected,
                    user: user
                },
            }
        })
    }

    const connections = [
        {
            id: 1,
            logo: claritask,
            name: "Claritask",
            description: "Clarity in managing projects. Know what anyone in your team is working on. Manage projects across team-based workspaces and keep track of deliverables with confidence and clarity."
        }]

    const updateApp = () => {
        const claritask_active = user.claritask_active === 0 ? 1 : 0
        axios.post(`${baseURL}claritask-active`,{claritask_active},{headers:{Authorization: `Bearer ${Cookies.get('access_token')}`}})
            .then(res => {
                dispatch(fetchUser())
            })
            .catch((err) => console.log(err))
    }

    const deleteApp = () => {

       const company_id = connected.company_details[0].company_id

        confirmAlert({
            title: 'Are you sure you want to delete this app connection?',
            message: 'You will not be able to recover this connection.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () =>  {
                        axios.post(`${baseURL}unlink`,{company_id},{headers:{Authorization: `Bearer ${Cookies.get('access_token')}`}})
                            .then(res => {
                                dispatch(fetchUser())
                            })
                            .catch((err) => console.log(err))
                    }
                },
                {
                    label: 'No'
                }
            ],
            overlayClassName: "confirm-overlay"
        })
    }

    return(
        <MainContainer>
            <div className="section-container pt-3">
                <Row className="align-items-center">
                    <Col xs={12} md={6} lg={8}>
                        <div className="ticket_sys_header">
                            <h4 className="table-name">Apps</h4>
                            {/* <p>Nisl pulvinar maecenas egestas nibh porta tincidunt arcu semper. Et id malesuada quis nec.</p> */}
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <ModalProvider>
                            <ModalContext.Consumer>
                                {({ showModal, hideModal }) => (
                                    <>
                                        <button type="button" className="btn adm-addNew" onClick={() => ShowCreateModal(showModal)}>
                                            <img src={plus}/>
                                            Integrate App
                                        </button>
                                        <ModalRoot />
                                    </>
                                )}
                            </ModalContext.Consumer>
                        </ModalProvider>
                    </Col>
                </Row>
                <Row>
                    <div className="table-responsive">
                        {user.claritask_linked ?
                            <Table className="mt-3">
                                <tbody>
                                {connections && connections.map(connection => (
                                    <tr key={`connectApp${connection.id}`} className="app-details-row">
                                        <td>
                                            <div className="app-logo-table">
                                                <img src={connection.logo} alt="logo"/>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <ModalProvider>
                                                    <ModalContext.Consumer>
                                                        {({ showModal, hideModal }) => (
                                                            <>
                                                                <h2 onClick={() => ShowEditModal(showModal, connected)}>{connection.name}</h2>
                                                                <ModalRoot />
                                                            </>
                                                        )}
                                                    </ModalContext.Consumer>
                                                </ModalProvider>
                                                <p>{connection.description}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="app-actions">
                                                <div>
                                                    <input type="checkbox" id="deactiveApp" name="activate_app" className="custom-checkbox-toggle" checked={user.claritask_active ? true: false} onChange={(e) => updateApp(e)}/>
                                                    <CustomToggle
                                                        on=""
                                                        off=""
                                                        id="deactiveApp"
                                                    />
                                                </div>
                                                <div className="file-details-search">
                                                    <ModalProvider>
                                                        <ModalContext.Consumer>
                                                            {({ showModal, hideModal }) => (
                                                                <>
                                                                    <HiOutlineDocumentSearch onClick={() => ShowEditModal(showModal, connected)}/>
                                                                    <ModalRoot />
                                                                </>
                                                            )}
                                                        </ModalContext.Consumer>
                                                    </ModalProvider>
                                                </div>
                                                <IoTrashOutline onClick={(e) => deleteApp()}/>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            : <div className="noAppIntegrated">
                                <h3>No App is integrated</h3>
                                <p>We’ve made possible for you to integrate your favorite applications.
                                    Browse amazing apps by clicking ‘Integrate App” button.</p>
                                <ModalProvider>
                                    <ModalContext.Consumer>
                                        {({ showModal, hideModal }) => (
                                            <>
                                                <button type="button" className="btn adm-addNew" onClick={() => ShowCreateModal(showModal)}>
                                                    <img src={plus}/>
                                                    Integrate App
                                                </button>
                                                <ModalRoot />
                                            </>
                                        )}
                                    </ModalContext.Consumer>
                                </ModalProvider>
                            </div>
                        }
                    </div>
                </Row>
            </div>
        </MainContainer>
    )
}
export default Connection
