import {Col, Row, Button} from "react-bootstrap";
import {ModalContext, ModalProvider, ModalRoot} from "react-multi-modal";
import AppCarousel from "./appCarousel";
import React from "react";
import ClaritaskLogin from "./ClaritaskLogin";

const AppDetails = ({detailed, linked}) => {

    const showLoginModal = (showModal) => {
        showModal({
            component: ClaritaskLogin,
            modalProps: {
                currentState: {
                    title: 'Create',
                },
            }
        })
    }
    return(
        <Row className="m-0">
            <Col sm={12} md={6} lg={4} xl={3} className="pl-0">
                <div className="category-container-list">
                    <div className="detailed-app-infos">
                        <div className="detailed-app-logo">
                            <img src={detailed && detailed.logo} alt="logo"/>
                        </div>
                        {linked !== 1 ?
                        <ModalProvider>
                            <ModalContext.Consumer>
                                {({ showModal, hideModal }) => (
                                    <>
                                        <Button className="btn details-app-install" onClick={() =>  showLoginModal(showModal)}>Install</Button>
                                        <ModalRoot />
                                    </>
                                )}
                            </ModalContext.Consumer>
                        </ModalProvider>
                        : <span className="already_linked_app">You Are Linked With this app</span>}
                        <div className="detailed-information">
                            <h4>Plans</h4>
                            <p>$3 /per user /monthly</p>
                        </div>
                        <div className="detailed-information">
                            <h4>Languages:</h4>
                            <p>English</p>
                        </div>
                        <div className="detailed-information">
                            <h4>Compactible with:</h4>
                            <p>Claritick</p>
                        </div>
                        <div className="detailed-information">
                            <h4>Availability:</h4>
                            <p>This app is available worldwide</p>
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm={12} md={6} lg={8} xl={9} className="overflow-in-desktop">
                <div className="apps_container">
                    <div className="app-overview">
                        <h1>{detailed.name}</h1>
                        <p>{detailed.description}</p>
                        <div className="app-overview-text">
                            <h4>Overview</h4>
                            {/* <p>Id ut aenean vestibulum enim mauris pellentesque convallis. Sed lorem massa, donec ullamcorper ipsum, placerat neque tellus. Viverra risus at amet dignissim arcu quis.</p>
                            <ul>
                                <li>Erat laoreet turpis interdum nunc blandit dictum eros.</li>
                                <li>Erat laoreet turpis interdum nunc blandit dictum eros.</li>
                                <li>A nibh odio nibh at sed nisl.</li>
                                <li>Amet lorem rhoncus gravida volutpat vitae sed egestas massa. Nec pharetra accumsan morbi urna tempor lectus cursus.</li>
                            </ul> */}
                        </div>
                        <AppCarousel/>
                        <div className="app-overview-text">
                            <h4>Installation</h4>
                            {/* <p>Id ut aenean vestibulum enim mauris pellentesque convallis. Sed lorem massa, donec ullamcorper ipsum, placerat neque tellus. Viverra risus at amet dignissim arcu quis.</p>
                            <ul>
                                <li>Erat laoreet turpis interdum nunc blandit dictum eros.</li>
                                <li>Erat laoreet turpis interdum nunc blandit dictum eros.</li>
                                <li>A nibh odio nibh at sed nisl.</li>
                                <li>Amet lorem rhoncus gravida volutpat vitae sed egestas massa. Nec pharetra accumsan morbi urna tempor lectus cursus.</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    )
}
export default AppDetails
