import React, {useState} from "react"

import {IoAddOutline} from "react-icons/io5";
import axios from "axios";
import Cookies from "js-cookie";

// import {fetchTasks} from "../../../actions/tasks";
// import {fetchTasks} from "../../../actions/tasks";
import { fetchTasks } from "../../../../redux/actions";

import {useDispatch} from "react-redux";
import Form from 'react-bootstrap/Form';
import Modal from "../../../../modals/Modal";
import { baseURL } from "../../../../helpers/baseURL";
import successAlert from "../../../../helpers/successAlert";
import { Button } from "react-bootstrap";


const LinkTask = ({ isOpen, hideModal, currentState}) => {
    const dispatch = useDispatch()
    const [results, setResults] = useState([])
    const [query, setQuery] = useState()
    const [isLoading, setLoading] = useState(false);
    // const [checked, setChecked] = useState()
    const [selectedValue, setSelectedValue] =useState();

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const searchForTasks = (e) => {
        e.preventDefault()
        axios.get(`${baseURL}search-task?search=${query}`,{headers:{Authorization: `Bearer ${Cookies.get('access_token')}`}})
            .then(res => {
               setResults(res.data.data.tasks)
            })
            .catch((err) => console.log(err))

    }
    const linkTicket = () => {
        let claritask_task_id = selectedValue
        let id = currentState.details.id

        setLoading(true)
        axios.post(`${baseURL}link-ticket/${id}`, {claritask_task_id},{headers:{Authorization: `Bearer ${Cookies.get('access_token')}`}})
            .then(res => {
                dispatch(fetchTasks(id))
                hideModal()
                successAlert('Task linked successfully');
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    return(
        isOpen && (
            <Modal>
                <div className="modal d-block" onClick={hideModal}>
                    <section className="create-task-modal" onClick={(e) => e.stopPropagation()}>
                        <div className="px-0 modal-header modal-header-claritask align-items-center">
                            <div className="d-block text-center modal-header-claritask-header">
                                <h5 className="ml-0">Link Task</h5>
                            </div>
                            <IoAddOutline onClick={hideModal} className="modal-header-claritask-x"/>
                        </div>
                        <div className="modal-body px-0">
                            <div className="claritask-create-div">
                                <form onSubmit={(e) => searchForTasks(e)}>
                                    <p className="mb-1 font_main_p">Search tasks in Claritask</p>
                                    <input type="search" placeholder="Enter a task name to search" onChange={(e) => setQuery(e.target.value)}/>
                                    <button className="btn search-task-cs" type="submit">Search</button>
                                </form>
                            </div>
                            {results.length > 0 &&
                            <div className="tasks-results-cs">
                                {results.map(res => {
                                    return(
                                        <div key={res.task_id} className={selectedValue == res.task_id ? 'selected-task' : ""}>
                                            <Form.Label className="d-flex align-items-center">
                                                <Form.Check
                                                    type={"radio"}
                                                    id={`check-api-radio`}
                                                    checked={selectedValue == res.task_id}
                                                    value={res.task_id}
                                                    onChange={handleChange}
                                                    name="radio-button-demo"
                                                    className="me-2"
                                                />
                                                <div>
                                                    {res.task_text.substring(0, 100)}
                                                    {res.task_text.length > 100 ?"..." : res.task_text}
                                                </div>
                                            </Form.Label>
                                        </div>
                                    )
                                })}
                            </div>
                            }
                        </div>
                        <div>
                            <div className="timer-footer-btn" style={{right: 25}}>
                                <Button className="btn cancelLog" onClick={hideModal}>
                                    Cancel
                                </Button>
                                <Button 
                                    className="btn logTime-timer" 
                                    type="submit"
                                    disabled={isLoading}
                                    onClick={!isLoading ? linkTicket : null}
                                >
                                    {isLoading ? 'Linking...' : 'Link Task'}
                                </Button>
                            </div>
                        </div>
                    </section>
                </div>
            </Modal>
        )
    )
}
export default LinkTask
