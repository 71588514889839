import React from "react"
import {Col, Row, Badge} from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import moment from "moment";
import Time from "../../Time";

const style = {
    divEl: {
        display: "flex",
        alignItems: "center"
    },
    h6El: {
        marginRight: 10,
        paddingBottom: 0,
        fontSize: 12
    },
    pEl: {
        marginBottom: 5,
        paddingBottom: 5,
        fontSize: 10
    }
}

const PrintTicket = ({ticket}) => {
    let days = moment.utc(new Date(ticket.created_at).getTime()).local().startOf('seconds').fromNow();
    let time = moment(ticket.created_at).format('MMMM Do YYYY, [at] h:mm');
	const reports = [...ticket.notes, ...ticket.replies].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    return(
        <div className="container-fluid">
            <Row>
                <Col sm={12} className="details-body-ticket bg-white">
                    <div className="details-body-ticket-head">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="ticket-top-infos pb-0">
                                <h3>{ticket.title}</h3>
                                <div className="d-flex align-items-center">
                                    <span>Created By</span>
                                    <h1 style={{marginBottom: 0}}>{ticket?.created_by?.name}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ticket-card mt-4 w-100">
                        <div className="ticket-user position-relative">
                            <img src={`${ticket?.created_by?.profile_image ? ticket.created_by.profile_image : `https://ui-avatars.com/api/?name=${ticket?.created_by?.name}`}`} alt=""/>
                        </div>
                        <div className="ticket-body w-100">
                            <h2>{ticket?.created_by?.name} <span>reported</span></h2>
                            <span>{days}({time})</span>
                            <div style={{marginTop: '30px'}}>
                                <p className="text-ticket" dangerouslySetInnerHTML={{ __html: ticket.description }}/>
                                {
                                    ticket.attachments && ticket.attachments.map(attachment => {
                                        return(
                                            <div key={`print-${attachment.id}`}>
                                                <a href={attachment.public_url} target="_blank" download>
                                                    {attachment.name}
                                                </a>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div style={style.divEl}>
                        <div style={{marginRight: 30}}>
                            <div style={style.divEl}>
                                <h6 style={style.h6El}>Due Date:</h6>
                                <p style={style.pEl}>{ticket?.due_date}</p>
                            </div>
                            <div style={style.divEl}>
                                <h6 style={style.h6El}>Assignee:</h6>
                                <p style={style.pEl}>{ticket?.user?.name}</p>
                            </div>
                            <div style={style.divEl}>
                                <h6 style={style.h6El}>Status:</h6>
                                <p style={style.pEl}>{ticket?.status?.name}</p>
                            </div>
                        </div>
                        <div>
                            <div style={style.divEl}>
                                <h6 style={style.h6El}>Department:</h6>
                                <p style={style.pEl}>{ticket?.department?.name}</p>
                            </div>
                            <div style={style.divEl}>
                                <h6 style={style.h6El}>Priority:</h6>
                                <p style={style.pEl}>{ticket?.priority?.name}</p>
                            </div>
                            <div style={style.divEl}>
                                <h6 style={style.h6El}>Type:</h6>
                                <p style={style.pEl}>{ticket?.type?.name}</p>
                            </div>
                        </div>
                    </div>
                    <div style={style.divEl}>
                        <h6 style={style.h6El}>Tags:</h6>
                        <p style={style.pEl}>{ticket?.tags?.map(tag => tag.name).toString()}</p>
                    </div>
                    {ticket?.total_ticket_timer &&
                        <div style={style.divEl}>
                            <h6 style={style.h6El}>Total tracked time:</h6>
                            <p style={style.pEl}>{ticket?.total_ticket_timer}</p>
                        </div>
                    }
                    <div style={{marginTop: 20, marginBottom: 20}}>
                        {ticket?.ticket_timer.length > 0 ? ticket.ticket_timer.map(time => <Time key={time.id} time={time} details={ticket} hideDelete={true}/>) : ''}
                    </div>
                    {reports.map((report, index) => (
                        <div key={index} className="card-report-row">
                            <div style={{maxWidth:'75px'}}>
                                <img style={{"--bs-border-radius": "50%"}} src={'https://ui-avatars.com/api/?name=' + report.user.name} className="rounded img-thumbnail" />
                            </div>
                            <div className="p-2 flex-grow-1 text-left d-flex flex-column">
                                <div className='d-flex'>
                                    {report.type == 'note' && <span>
                                        <Badge pill bg="secondary" className="ms-1">Note</Badge>
                                    </span>}
                                    {report.type == 'reply' && <span>
                                        <Badge pill bg="primary" className="ms-1">Reply</Badge>
                                    </span>}
                                    <span className="mx-1">by</span>
                                    <span className="text-primary">{report.user.name}</span>
                                </div>
                                <div className="content-notes-ticket">{report.content}</div>
                                <div className="d-flex mt-1 flex-wrap">
                                    {report.attachments?.length > 0 && report.attachments.map((attachment, index) => {
                                        const fileName = attachment.name;
                                        const lastDotIndex = fileName.lastIndexOf(".");
                                        const extension = lastDotIndex >= 0 ? fileName.slice(lastDotIndex + 1) : '';
                                        const shortFileName = fileName.length > 30 ? `${fileName.slice(0, 30)}...${extension}` : fileName;
                                        return (
                                            <a className="me-1" key={index} download={true} href={attachment?.public_url} >
                                                <Badge bg="info" className="py-1">
                                                    {shortFileName}
                                                    {"  "}
                                                    {attachment.size}
                                                    <FaDownload className="ms-1" size={12}/>
                                                </Badge>
                                            </a>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    ))}
                </Col>
            </Row>
        </div>
    )
}
export default PrintTicket
