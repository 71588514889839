import React, { useState } from 'react';
import MainContainer from "../../MainContainer";
import { Container } from 'react-bootstrap';
import Cards from './Cards';
import NewCard from './NewCard';

const Payment = () => {

	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [newCard, setNewCard] = useState({});

	const handleCardCreated = (card) => {
		setShowPaymentModal(false);
		setNewCard(card);
	}

	return (
		<MainContainer>
			<Container fluid="sm">
				<Cards viewMode="list" newCard={newCard} showCardModal={() => setShowPaymentModal(true)} />
			</Container>
			<NewCard showPaymentModal={showPaymentModal} onCreate={handleCardCreated} hidePaymentModal={() => setShowPaymentModal(false)} />
		</MainContainer>
	);
};

export default Payment;