import { postRequestCallback } from "../helpers/Requests";
import { confirmAlert } from "react-confirm-alert";

function showConfirmDialog({ title, message, onConfirm, overlayClassName = "confirm-overlay" }) {
	confirmAlert({
		title,
		message,
		overlayClassName,
		buttons: [
			{ label: 'Yes', onClick: onConfirm },
			{ label: 'No' }
		]
	});
}

function confirmAction(url, data, reduxRefresh, finished) {
	postRequestCallback(url, data, () => {
		reduxRefresh();
		finished();
	}, () => {
		finished();
	});
}

export function showDeleteConfirm(item, single, prefixAPI, reduxRefresh = () => {}, finished = () => {}) {
	const isUser = single === 'User';
	const title = `Are you sure you want to remove this ${single.toLowerCase()}${isUser ? ' from workspace?' : '?'}`;
	const message = isUser ? 'You can add them to the workspace via email later.' : 'You will not be able to recover it.';

	showConfirmDialog({
		title, message, onConfirm: () => {
			confirmAction(`${prefixAPI}-delete/${item.id}`, {}, reduxRefresh, finished)
		}
	});
}

export function showMultipleDeleteConfirm(items, single, plural, prefixAPI, reduxRefresh = () => {}, finished = () => {}) {
	const title = `Are you sure you want to delete ${items.length} ${items.length === 1 ? single : plural}?`;
	const message = 'You may not be able to undo this action.';

	showConfirmDialog({
		title, message, onConfirm: () => {
			confirmAction(`${prefixAPI}-delete`, {items}, reduxRefresh, finished)
		}
	});
}