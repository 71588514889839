import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { BsLayoutThreeColumns, BsCheckSquare, BsSquare } from 'react-icons/bs'

const TicketsLayout = ({ columns, onColumnVisibilityChange }) => {
	return (
		<Dropdown autoClose="outside">
			<Dropdown.Toggle variant="secondary" id="filters-dropdown">
				<BsLayoutThreeColumns />
			</Dropdown.Toggle>
			{
				columns &&
				<Dropdown.Menu style={{ fontSize: '14px' }}>
					<Dropdown.Header>Columns:</Dropdown.Header>
					{
						Object.entries(columns).map(([column, value], index) => {
							return <Dropdown.Item key={index} onClick={() => onColumnVisibilityChange(column)}>
								{value.visible ? <BsCheckSquare /> : <BsSquare />} {value.label}
							</Dropdown.Item>
						})
					}
				</Dropdown.Menu>
			}
		</Dropdown>
	)
}

export default TicketsLayout
