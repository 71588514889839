import {toast} from "react-toastify";

toast.configure();
const successAlert = (props) => toast.success(`${props} successfully!`, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
});
export default successAlert


