import React from "react"
import icon1 from "../main/images/icon1.png"
import icon2 from "../main/images/icon2.png"
import icon3 from "../main/images/icon3.svg"

const ServicesMain = () => {
    return(
        <>
            <section className="services">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="services-box">
                                <div className="services-icon-background">
                                    <img src={icon1}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 center-items">
                            <div className="services-details">
                                <h3><span>Create </span>an account</h3>
                                <p className="p-main">Manage your customer support in an organized manner by creating an account that helps you collect and organize all incoming requests.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 center-items integrate-account">
                            <div className="services-details text-right">
                                <h3><span>Integrate
                                </span>your accounts</h3>
                                <p className="p-main">Helps you manage your work and data by integrating your account with other apps and platforms by giving you a better view of what is happening.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="services-box integrate-image">
                                <div className="services-icon-background">
                                    <img src={icon2}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="services-box">
                                <div className="services-icon-background">
                                    <img src={icon3}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 center-items">
                            <div className="services-details">
                                <h3><span>Be there for </span>your customers</h3>
                                <p className="p-main">An easy system that will work efficiently with all costumer service requests, making it easier for customer service representatives to timely and effectively resolve any potential issues.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ServicesMain
