import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {fetchUser} from "../../../redux/actions";
import ProfileForm from "./Profile-form";
import MainContainer from "../../MainContainer";

const Profile = () => {
    const dispatch = useDispatch()
    const user =  useSelector(state => state.userReducer.user);

    useEffect(() => {
        dispatch(fetchUser())
    }, []);


    return(
        <MainContainer>
            <ProfileForm user={user}/>
        </MainContainer>
    )

}
export default Profile;
