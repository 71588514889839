export const AppsReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_APPS':
            return {
                ...state,
                apps: action.payload,
                loading: action.loading ?? false
            }
        default:
            return state
    }
}
