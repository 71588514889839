import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux";
import {fetchTasks} from "../../redux/actions";
import {IoIosLink ,IoMdTrash} from "react-icons/io";
import moment from "moment"
import folder from "../../../css/img/folder.svg"
import del from "../../../css/img/task-delete.svg"
import img from "../../../css/img/avatar.png";
import {confirmAlert} from "react-confirm-alert";
import axios from "axios";
import {baseURL} from "../../helpers/baseURL";
import Cookies from "js-cookie";
import successAlert from "../../helpers/successAlert";
import failAlert from "../../helpers/failAlert";
import { deleteTask } from "../../redux/actions";
import { Button } from "react-bootstrap";
import { ModalProvider, ModalContext,ModalRoot } from 'react-multi-modal';

const Tasks = ({id, showClaritaskLink}) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchTasks(id))
    }, [])

    const tasks = useSelector(state => state.allTasks?.tasks?.tasks);

    const deleteTaskFunc = (claritask_task_id) => {
        confirmAlert({
            title: 'Are you sure you want to delete this task',
            message: 'This task will be deleted in Claritask too',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios.post(`${baseURL}delete-ticket-task/${id}`, {claritask_task_id}, {headers:{
                                Authorization: `Bearer ${Cookies.get('access_token')}`
                            }}).then(res => dispatch(deleteTask(claritask_task_id))).then(successAlert('Status updated')).catch(failAlert)
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ],
            overlayClassName: "confirm-overlay"
        })
    }

    const unlinkTask = (claritask_task_id) => {
        confirmAlert({
            title: 'Are you sure you want to unlink this task',
            message: 'This task will be removed in Claritask too',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios.post(`${baseURL}unlink-ticket/${id}`, {claritask_task_id}, {headers:{
                                Authorization: `Bearer ${Cookies.get('access_token')}`
                            }}).then(res => dispatch(deleteTask(claritask_task_id))).then(successAlert('Status updated')).catch(failAlert)
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }
            ],
            overlayClassName: "confirm-overlay"
        })
    }

    return(
        <div className="tasks-created-container">
            {tasks &&
                tasks.map(task => {
                    if(task.claritick_status == 1){
                        return(
                            <div key={`task${task.task_id}`} className="each-task-div">
                                <div className="each-task-div-header">
                                    <img src={folder} alt="folder"/>
                                    <h4>{task.workspace_name}<span> &gt; </span><span>{task.project_name}</span></h4>
                                </div>
                                <div className="user-assigned-task">
                                    {task.task_people_assigns && task.task_people_assigns.map(user => {
                                        return(
                                            <div key={`user${user.user_id}`}>{`${user.first_name} ${user.last_name}`}</div>
                                        )
                                    })}
                                </div>
                                <p>{task.task_text}</p>
                                <div  className="each-task-div-body">
                                    <div>
                                        <ModalProvider>
                                            <ModalContext.Consumer>
                                            {({ showModal, hideModal}) => (
                                                <>
                                                    <Button className="btn link-task" onClick={() =>showClaritaskLink(showModal)}><IoIosLink/></Button>
                                                    <ModalRoot />
                                                </>
                                            )}
                                            </ModalContext.Consumer>
                                        </ModalProvider>
                                       
                                        <Button className="btn delete-task" onClick={() => deleteTaskFunc(task.task_id)}>
                                            <img src={del} alt="delete"/>
                                        </Button>
                                    </div>
                                    <span>{moment.unix(task.task_due_date).format("MMM Do, YYYY")}</span>
                                </div>
                            </div>
                        )
                    } else {
                        return(
                          <div key={`task${task.task_id}`} >
                              <div className="linked-task-container">
                                  <span>Linked to task</span>
                                  <h4>{task.task_text}</h4>
                              </div>
                              <Button className="btn unlink-task-claritask" onClick={() => unlinkTask(task.task_id)}>
                                  <IoIosLink/>
                                  UnLink
                              </Button>
                              <Button className="btn delete-task-on-claritask" onClick={() => deleteTaskFunc(task.task_id)}>
                                  <IoMdTrash/>
                                  Delete
                              </Button>
                          </div>
                        )
                    }
                })
            }
        </div>
    )
}
export default Tasks
