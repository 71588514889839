import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Spinner, Form, InputGroup, Alert } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { CirclePicker, ChromePicker } from "react-color";
import { BsEyedropper, BsTrash3 } from "react-icons/bs";
import Select from 'react-select';
import styles from './ItemModal.module.css';
import { baseURL } from "../helpers/baseURL";
import { headers } from '../helpers/Requests';
import { showDeleteConfirm } from './ItemDelete';
import FormRule from './FormRule';
import { colorOption, colorValue, colorValueSingle, imageOption } from './SelectComponents';

function ItemModal({ closeModal, currentState, itemType, single, reduxRefresh, prefixAPI }) {
	const [isLoading, setIsLoading] = useState(false);
	const [colorPicker, setColorPicker] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const colorPickerRef = useRef(null);

	// form data
	const [formData, setFormData] = useState({
		name: '',
		color: 'transparent',
		description: '',
		members: [],
		departments: [],
		department: null,
		role: '',
		title: '',
		workspace_active: true,
		email: '',
		email_prefix: '',
	});

	useEffect(() => {
		if (currentState?.title === 'Edit' && currentState?.item) {
			const { name, color, email, email_prefix, title, description, members, departments, department, role, workspace_active } = currentState.item;
			setFormData({
				name: name ?? '',
				color: color ?? 'transparent',
				email: email ?? '',
				email_prefix: email_prefix ?? '',
				title: title ?? '',
				description: description ?? '',
				members: members?.map(member => ({ value: member.id, label: member.name })) ?? [],
				departments: departments?.map(department => ({ value: department.id, label: department.name, color: department.color })) ?? [],
				department: department ? { value: department.id, label: department.name, color: department.color } : null,
				role: role ?? '',
				workspace_active: workspace_active ?? true,
			});
		}
	}, [currentState]);

	// select options
	const optionsDepartments = useSelector(state => state.departmentsReducer.departments?.map(dept => ({
		value: dept.id,
		label: dept.name,
		description: dept.description,
		color: dept.color
	})) || []);
	const optionsUsers = useSelector(state => state.usersReducer.users?.map(user => ({
		value: user.id,
		label: user.name,
		description: user.email,
		image: user.profile_image
	})) || []);
	const optionsRoles = [
		{ value: 'Admin', label: 'Admin' },
		{ value: 'Agent', label: 'Agent' },
		{ value: 'Customer', label: 'Customer' },
	];

	// handle input changes
	const handleInputChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};
	const handleSelectChange = (name, value) => {
		setFormData({ ...formData, [name]: value });
	};
	const handleColorChange = (color) => {
		setFormData({ ...formData, color: color.hex });
	};
	const handleCloseColorPicker = (event) => {
		if (colorPickerRef && !colorPickerRef?.current?.contains(event.target)) {
			setColorPicker(false);
		}
	};
	useEffect(() => {
		document.addEventListener("mousedown", handleCloseColorPicker);
		return () => {
			document.removeEventListener("mousedown", handleCloseColorPicker);
		};
	}, [colorPickerRef])

	// handle actions
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (formData.name.length < 3) {
			setErrorMessage('Please fill in the name field with a minimum of 3 characters');
			return;
		}
		// prepare data
		let data = { name: formData.name };
		if (['tags', 'types', 'priorities', 'departments'].includes(itemType)) {
			data.color = formData.color;
		}
		if (['types', 'priorities', 'departments'].includes(itemType)) {
			data.description = formData.description;
		}
		if (['departments'].includes(itemType)) {
			data.members = formData.members.map(member => member.value);
		}
		if (['users'].includes(itemType)) {
			if (formData.email === '') {
				setErrorMessage('Please fill in the email field');
				return;
			}
			if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
				setErrorMessage('Please enter a valid email address');
				return;
			}
			data.email = formData.email;
		}
		if (['users'].includes(itemType)) {
			data.title = formData.title;
			data.role = formData.role;
			data.workspace_active = formData.workspace_active;
			data.departments = formData.departments.map(department => department.value);
		}
		if (['emails'].includes(itemType)) {
			if (formData.email_prefix.length < 3) {
				setErrorMessage('Please fill in the email field with a minimum of 3 characters');
				return;
			}
			if (formData.department === null) {
				setErrorMessage('Please select a department');
				return;
			}
			data.department_id = formData.department.value
			data.email_prefix = formData.email_prefix;
		}

		// handle request
		setIsLoading(true);
		try {
			const url = baseURL + (currentState?.title === 'Add' ? prefixAPI : `${prefixAPI}-update/${currentState.item.id}`);
			const response = await axios.post(url, data, headers);
			reduxRefresh();
			closeModal();
		} catch (error) {
			console.error(error);
			if (error.response.data.errors) {
				setErrorMessage(Object.values(error.response.data.errors)[0].message);
			}else{
				setErrorMessage(error.response.data.message || 'Something went wrong. Please try again later.');
			}
		} finally {
			setIsLoading(false);
		}
	};

	const renderFormFields = () => {
		const fields = [];
		fields.push(
			<Form.Group key="name" controlId="form.name">
				<Form.Label><b>Name</b></Form.Label>
				<Form.Control type="text" value={formData.name} name="name" onChange={handleInputChange} placeholder="Enter name" disabled={itemType == 'users' && currentState?.title === 'Edit'} required />
			</Form.Group>
		);

		if (['departments'].includes(itemType)) {
			fields.push(
				<Form.Group key="users" className='mt-3' controlId="form.users">
					<Form.Label><b>Users</b></Form.Label>
					<Select isMulti options={optionsUsers} components={{ Option: imageOption }} value={formData.members} onChange={(value) => handleSelectChange('members', value)} classNamePrefix="select-component" />
				</Form.Group>
			);
		}

		if (['users'].includes(itemType)) {
			fields.push(
				<Form.Group key="email" className='mt-3' sm={6} controlId="form.email">
					<Form.Label><b>Email</b></Form.Label>
					<Form.Control type="email" value={formData.email} name="email" onChange={handleInputChange} placeholder="Enter email" required disabled={currentState?.title === 'Edit'} />
				</Form.Group>,
				<Form.Group key="title" className='mt-3' sm={6} controlId="form.title">
					<Form.Label><b>Title</b></Form.Label>
					<Form.Control type="text" value={formData.title} name="title" onChange={handleInputChange} placeholder="e.g. Senior Developer" />
				</Form.Group>,
				<Form.Group key="departments" className='mt-3' controlId="form.departments">
					<Form.Label><b>Departments</b></Form.Label>
					<Select isMulti options={optionsDepartments} components={{ Option: colorOption, MultiValueLabel: colorValue }} value={formData.departments} onChange={(value) => handleSelectChange('departments', value)} classNamePrefix="select-component" />
				</Form.Group>,
				<Form.Group key="role" className='mt-3' controlId="form.role">
					<Form.Label><b>Role</b></Form.Label>
					{optionsRoles.map((option) => (
						<div key={option.value} className="mb-3">
							<Form.Check
								type="checkbox"
								id={`role-${option.value}`}
								label={option.label}
								checked={formData.role === option.value}
								onChange={(e) => handleSelectChange('role', e.target.checked ? option.value : '')}
							/>
							<Form.Text className="text-muted">
								{option.value === 'admin' ? 'Admins have full access to the system.' : 'Agents access to tickets and reports.'}
							</Form.Text>
						</div>
					))}
				</Form.Group>,
				<FormRule key="hr" />,
				<Form.Group key="workspace_active" className='mt-3' controlId="form.workspace_active">
					<Form.Label><b>System Access</b></Form.Label>
					<Form.Check
						type="checkbox"
						id="active"
						label='Active'
						checked={formData.workspace_active}
						onChange={(e) => handleSelectChange('workspace_active', e.target.checked)}
					/>
					<Form.Text className="text-muted">
						{formData.workspace_active ? 'User is active and can access the system.' : 'User is inactive and cannot access the system.'}
					</Form.Text>
				</Form.Group>
			);
		}

		if (['emails'].includes(itemType)) {
			fields.push(
				<Form.Group key="email_prefix" className='mt-3' controlId="form.email_prefix">
					<Form.Label><b>Email Address</b></Form.Label>
					<InputGroup>
						<Form.Control type="email" value={formData.email_prefix} name="email_prefix" onChange={handleInputChange} placeholder="Prefix (e.g. Acme-support)" required />
						<InputGroup.Text>{`@${process.env.MIX_EMAIL_DOMAIN}`}</InputGroup.Text>
					</InputGroup>
				</Form.Group>,
				<Form.Group key="department" className='mt-3' controlId="form.department">
					<Form.Label><b>Department</b></Form.Label>
					<Select options={optionsDepartments} components={{ Option: colorOption, SingleValue: colorValueSingle }} value={formData.department} onChange={(value) => handleSelectChange('department', value)} classNamePrefix="select-component" />
				</Form.Group>
			);
		}

		if (['types', 'priorities', 'departments'].includes(itemType)) {
			fields.push(
				<Form.Group key="description" className='mt-3' controlId="form.description">
					<Form.Label><b>Description</b></Form.Label>
					<Form.Control type="text" value={formData.description} name="description" onChange={handleInputChange} placeholder="Enter description" required />
				</Form.Group>
			);
		}

		if (['tags', 'types', 'priorities', 'departments'].includes(itemType)) {
			fields.push(
				<Form.Group key="color" className={styles.colorPicker} controlId="form.color">
					<Form.Label><b>Color</b></Form.Label>
					<div className="color-preview" style={{ backgroundColor: formData.color }}></div>
					<div className={styles.colorPickerWrapper}>
						<Button variant="light" className={styles.colorPickerButton} onClick={() => setColorPicker(!colorPicker)}><BsEyedropper /></Button>
						<CirclePicker color={formData.color} onChangeComplete={handleColorChange} />
					</div>
					{colorPicker && (
						<div className="color-picker-popover" ref={colorPickerRef}>
							<div className="color-picker-cover" onClick={handleCloseColorPicker} />
							<ChromePicker color={formData.color} onChange={handleColorChange} />
						</div>
					)}
				</Form.Group>
			);
		}
		return fields;
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Modal show={true} onHide={closeModal}>
				<Modal.Header closeButton>
					<Modal.Title>{currentState?.title === 'Edit' ? 'Edit' : 'New'} {single}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
					{renderFormFields()}
				</Modal.Body>
				<Modal.Footer>
					{currentState?.title === 'Edit' && (
						<Button variant="danger" className='mr-auto' onClick={() => showDeleteConfirm(currentState.item, single, prefixAPI, reduxRefresh, closeModal)} disabled={isLoading}><BsTrash3 /> Delete</Button>
					)}
					<Button variant="secondary" onClick={closeModal} disabled={isLoading}>
						Cancel
					</Button>
					<Button variant="primary" type="submit" disabled={isLoading} onClick={handleSubmit}>
						{isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Save'}
					</Button>
				</Modal.Footer>
			</Modal>
		</Form>
	);
}

export default ItemModal;
