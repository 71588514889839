import React from "react";
import {useSelector} from "react-redux";

const TeamPerformance = () => {
    const teamPerformanceSelector = useSelector(state => state?.OverviewReducer?.data?.teamPerformance);

    return(
        <div className="report-chart-holder mr-0">
            <h4 className="report-chart-titles">Team Performance</h4>
            <div className="table-responsive">
                <table className="table performance-table">
                    <thead>
                    <tr>
                        <th>Agent</th>
                        <th>Replies</th>
                        <th>Solved</th>
                        {/*<th>Happiness</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {(teamPerformanceSelector && teamPerformanceSelector.length > 0) ? teamPerformanceSelector.map(perform => (
                        <tr key={perform.id}>
                            <td>
                                <div>
                                    {/* <span>{report.data.teamPerformance.indexOf(perform) + 1}</span>
                                    <img src={perform.profile} alt="user image"/>
                                     */}
                                     <img src={perform.profile} alt="user image"/>
                                     <h3>{perform.name}</h3>
                                </div>
                            </td>
                            <td>
                                {perform.replies} Replies
                            </td>
                            <td>
                                {perform.solved}  Solved
                            </td>
                            {/*<td>*/}
                            {/*    {perform.happiness}*/}
                            {/*</td>*/}
                        </tr>
                    )) : <tr><td></td><td className="text-center">Nothing to show</td><td></td></tr>}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default TeamPerformance
