import React, { useEffect, useState } from 'react';
import { ModalContext, ModalProvider, ModalRoot } from "react-multi-modal";
import Button from 'react-bootstrap/Button';
import axios from "axios";
import TimerModal from "../../../../modals/Timer/Timer";
import failAlert from "../../../../helpers/failAlert";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import successAlert from "../../../../helpers/successAlert";
import { baseURL } from '../../../../helpers/baseURL';

function formatTime(ms) {
    const hours = Math.floor(ms / 3600000);
    const minutes = Math.floor((ms % 3600000) / 60000);
    const seconds = Math.floor(((ms % 3600000) % 60000) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

function getTimeString(date) {
    if(!date) return null;
    const hours = date.getHours().toString().padStart(2, '0');
 
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}

function TimerWatch({ ticket, setTicket }) {
    const dispatch = useDispatch();
    const [isRunning, setIsRunning] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [endTime, setEndTime] = useState(null)

    useEffect(() => {
        let interval;
        if(isRunning && startTime){
            interval = setInterval(() => {
                setElapsedTime(Date.now() - startTime);
            }, 1000)
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isRunning, startTime])

    useEffect(() => {
        if(ticket && ticket.running_timer){
            let startDt = new Date(ticket.running_timer.date_start);
            
            setStartTime(startDt - elapsedTime - (ticket.running_timer.raw_time_duration * 1000));
            setElapsedTime(ticket.running_timer.raw_time_duration * 1000);
            let isRunning = !!ticket?.running_timer?.is_running;
            
            setIsRunning(!!ticket?.running_timer?.is_running);
            // let tmpSetSeconds = startTime + ticket.running_timer.raw_time_duration;
            // let tmpEndTime = startDt.setSeconds(tmpSetSeconds);
            if(!isRunning){
                setEndTime(startDt.getTime());
            }
            // setEndTime(tmpEndTime);
        }
    }, [ticket])

    const startTimer = () => {
        setIsRunning(true);
        setStartTime(Date.now() - elapsedTime);

        let data = {ticket_id: ticket.id}
        axios.post(`${baseURL}start-ticket-timer`, data,{
            headers: {
                Authorization: `Bearer ${Cookies.get('access_token')}`
            }
        })
        .catch(failAlert)
    }

    const pauseTimer = () => {
        stopTimer()
        let data = {ticket_id: ticket.id}
        axios.post(`${baseURL}pause-ticket-timer`, data, {
            headers: {
                Authorization: `Bearer ${Cookies.get('access_token')}`
            }
        }).then(res => {

        }).catch(err => console.log(err))
    }

    const stopTimer = () => {
        setIsRunning(false);
        setEndTime(new Date().getTime())
    }

    const resetState = () => {
        setStartTime(null)
        setEndTime(null)
        setElapsedTime(0)
    }

    

    function showModalLog(showModal) {
        setIsRunning(false);
        showModal({
            component: TimerModal,
            modalProps: {
                currentState: {
                    id: ticket.id,
                    title: 'Create',

                    date_start: startTime ? new Date(startTime) : new Date(),
                    date_end: endTime ? new Date(endTime) : new Date(),

                    startTime: startTime ? new Date(startTime) : null,
                    endTime: endTime || (startTime ? new Date() : null),

                    startTimeConverted: startTime ? getTimeString(new Date(startTime)) : null,
                    // startTimeConverted: getTimeString(startTime && new Date(startTime)) ?? getTimeString(new Date()),
                    endTimeConverted: endTime ? getTimeString(new Date(endTime)) : (startTime ? getTimeString(new Date()) : null),
                    // endTimeConverted: getTimeString(endTime && new Date(endTime)) ?? getTimeString(new Date()),
                    user_id: ticket.user ? ticket.user.id : null,
                    resetState: resetState,
                    setTicket: setTicket
                },
            }
        })
    }
    return (
        <>
        <div className="d-flex justify-content-between align-items-center timer-container-watch mb-2">
            <>
                <div className="timer-container-holder">
                    <span>{formatTime(elapsedTime)}</span>
                </div>
                <div className="d-flex align-items-center ">
                    {isRunning ?
                        <Button variant="primary" className="btn" onClick={() => pauseTimer()}>Pause</Button>
                        : elapsedTime == 0 ?
                        <Button variant="primary" className="btn" onClick={() => startTimer()}>Start</Button>
                        :
                        <Button variant="primary" className="btn" onClick={() => {
                            startTimer()
                            setEndTime(null)
                        }}>Resume</Button>
                    }
                    <ModalProvider>
                        <ModalContext.Consumer>
                            {({ showModal, hideModal }) => (
                                <>
                                    <Button variant="primary" className="btn" onClick={() => {
                                        showModalLog(showModal)}
                                    }>Log Time</Button>
                                    <ModalRoot />
                                </>
                            )}
                        </ModalContext.Consumer>
                    </ModalProvider>
                </div>
            </>

        </div>
        <div className="each-timer mb-3">
            <div className={`ticket-timer-container ${ticket.ticket_timer.length > 0 ? 'timerBlueBg' : ''}`}>
                {ticket.ticket_timer.length > 0 ?
                    <div>
                        <span>Total tracked time</span>
                        <p>{ticket.total_ticket_timer}</p>
                    </div> :
                    <p>Track the time you spend resolving this ticket</p>
                }
            </div>
        </div>
        </>

    );
}
export default TimerWatch
