// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pN8Ue6Dfc7GZFnEh1C65aw\\=\\={border-right:1px solid #ccc;display:flex;gap:6px;margin-right:6px;padding-right:12px}", "",{"version":3,"sources":["webpack://./resources/js/components/Pages/tickets/TicketsAction.module.css"],"names":[],"mappings":"AAAA,4BAKI,2BAA4B,CAJ5B,YAAa,CACb,OAAQ,CACR,gBAAiB,CACjB,kBAEJ","sourcesContent":[".container{\r\n    display: flex;\r\n    gap: 6px;\r\n    margin-right: 6px;\r\n    padding-right: 12px;\r\n    border-right: 1px solid #ccc;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "pN8Ue6Dfc7GZFnEh1C65aw=="
};
export default ___CSS_LOADER_EXPORT___;
