import { Dropdown } from 'react-bootstrap';
import PrintComponents from "react-print-components";
import PrintTicket from "./PrintTicket";
import React from 'react';
import { BsPrinter, BsTrash3, BsThreeDots } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { showDeleteConfirm } from '../../../ItemDelete';
import MergeView from './MergeView';

export function TicketActions({ ticket, onComplete ,reports}) {
    const history = useHistory();
    const handleDelete = () => {
		showDeleteConfirm(ticket, 'ticket', 'ticket', () => {}, () => history.replace('/tickets'))
	};

    return (
        <div className="d-inline-flex align-items-center">
            <Dropdown>
                <Dropdown.Toggle variant="secondary" className="dropdown-no-arrow" id="dropdown-basic">
                    <BsThreeDots />
                </Dropdown.Toggle>
				<Dropdown.Menu style={{ fontSize: '14px' }}>
                    <MergeView onComplete={onComplete} ticket={ticket}/>
                    <Dropdown.Item onClick={handleDelete}><BsTrash3 /> Delete</Dropdown.Item>
                    <Dropdown.Item>
                        <PrintComponents trigger={<div>
                            <BsPrinter /> Print
                        </div>}>
                            <PrintTicket reports={reports} ticket={ticket} />
                        </PrintComponents>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}
