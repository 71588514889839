import React, {useRef, useEffect, useState} from "react"
import { Calendar } from "react-date-range"
import { formatDate } from "./formatDate"
import { IoCalendarNumberOutline } from "react-icons/io5";


const style= {
    window:  {
        boxShadow: '0 6px 15px rgba(37,49,60,.16)',
        fontFamily: "Poppins-Regular",
        position: 'absolute',
        right: 0,
        top: "39px",
        zIndex: 9999
    },
    wrapper: {
        position: 'relative'
    },
    display: {
        display: "flex",
        alignItems: "center",
        color: "#1675e0",
        justifyContent: "flex-end",
        cursor: "pointer"
    },
    icon: {
        fontSize: "16px",
        marginRight: "5px",
        color: "grey"
    },
    space: {
        marginRight: "5px"
    },
    input: {
        color: "#575757",
        border:" 1px solid #e5e5ea",
        width: "100%",
        height: "38px",
        borderRadius: "6px",
        display: "flex",
        alignItems: "center",
        padding: "10px",
        cursor: "pointer",
        justifyContent: "space-between"
    }
}

const DatePicker = ({handleDateChange, selected}) => {
    const wrapperRef = useRef()
    const [selectedDate, setSelectedDate] = useState(null)
    const [showCalendar, setShowCalendar] = useState(false)

    useEffect(() => {
        if(selected){
            setSelectedDate(selected)
        }
    }, [selected])

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowCalendar(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    return(
        <div style={style.wrapper} ref={wrapperRef}>
            <div style={style.display} onClick={() => setShowCalendar(!showCalendar)}>
                <div style={style.input}>
                    {selectedDate ? formatDate(selectedDate) : "Select Date"}
                    <IoCalendarNumberOutline/>
                </div>   
            </div>
            {showCalendar &&
                <div className="date-range-picker" style={style.window}>
                    <Calendar
                        date={selectedDate}
                        onChange={item => {
                            // console.log(item)
                            setSelectedDate(item)
                            setShowCalendar(false)
                            handleDateChange(item)
                        }}
                    />
                </div>
            }
    </div>
    )
}
export default DatePicker