import React, { useEffect, useState } from "react"
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import { Badge, Spinner } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsExclamationCircle, BsFiletypeCsv, BsFiletypePdf, BsSave } from 'react-icons/bs';
import { baseURL } from "../../../helpers/baseURL";
import { headers } from "../../../helpers/Requests";
import failAlert from "../../../helpers/failAlert";

const TicketsExport = ({ columns }) => {

	const [isLoading, setIsLoading] = useState(false);

	const handleDownload = (format) => {
		if (isLoading) return;
		let params = new URLSearchParams(window.location.search);
		params.delete('page');
		params.delete('per_page');
		setIsLoading(true);
		axios({
			method: "GET",
			url: `${baseURL}tickets?export=true&${params.toString()}`,
			...headers,
		}).then(response => {
			download(format, response.data);
		}).catch(error => {
			console.error(headers, error)
			failAlert('Something went wrong. Please try again later.')
		}).finally(() => {
			setIsLoading(false);
		});
	}

	const getHead = (format) => {
		return [
			"ID",
			(format === "PDF" && !columns.title.visible) ? null : "Title",
			(format === "PDF") ? null : "Description",
			(format === "PDF" && !columns.user.visible) ? null : "Assignee",
			(format === "PDF" && !columns.user.visible) ? null : "Assignee's Email",
			(format === "PDF" && !columns.department.visible) ? null : "Department",
			(format === "PDF" && !columns.status.visible) ? null : "Status",
			(format === "PDF" && !columns.priority.visible) ? null : "Priority",
			(format === "PDF" && !columns.type.visible) ? null : "Type",
			(format === "PDF" && !columns.tags.visible) ? null : "Tags",
			(format === "PDF" && !columns.replies.visible) ? null : "Replies",
			(format === "PDF" && !columns.due_date.visible) ? null : "Due Date",
			(format === "PDF" && !columns.created_at.visible) ? null : "Created At",
			(format === "PDF" && !columns.replied_at.visible) ? null : "Replied At",
		].filter(row => row !== null);
	}

	const getBody = (format, data) => {
		return data.map(ticket => [
			ticket.ticket_id,
			(format === "PDF" && !columns.title.visible) ? null : (ticket.title ?? ''),
			(format === "PDF") ? null : (ticket.description ?? ''),
			(format === "PDF" && !columns.user.visible) ? null : (ticket.user?.name ?? ''),
			(format === "PDF" && !columns.user.visible) ? null : (ticket.user?.email ?? ''),
			(format === "PDF" && !columns.department.visible) ? null : (ticket.department?.name ?? ''),
			(format === "PDF" && !columns.status.visible) ? null : (ticket.status?.name ?? ''),
			(format === "PDF" && !columns.priority.visible) ? null : (ticket.priority?.name ?? ''),
			(format === "PDF" && !columns.type.visible) ? null : (ticket.type?.name ?? ''),
			(format === "PDF" && !columns.tags.visible) ? null : (ticket.tags?.map(tag => tag.name).join(" - ") ?? ''),
			(format === "PDF" && !columns.due_date.visible) ? null : (ticket.due_date ?? ''),
			(format === "PDF" && !columns.created_at.visible) ? null : (ticket.created_at ?? ''),
			(format === "PDF" && !columns.replied_at.visible) ? null : (ticket.replied_at ?? ''),
			(format === "PDF" && !columns.replies.visible) ? null : (ticket.replies ?? '')
		].filter(row => row !== null));
	}

	const download = (format, data) => {

		const head = getHead(format);
		const body = getBody(format, data);
		const fileName = `tickets-${new Date().toLocaleDateString()}`;

		if (format === 'CSV') {
			const csvContent = [
				head.join(","),
				...body.map(e => e.join(","))
			].join("\n");
			downloadFile({
				data: csvContent,
				fileName: `${fileName}.csv`,
				fileType: 'text/csv',
			});
		} else {
			const doc = new jsPDF();
			doc.autoTable({
				head: [head],
				body: body,
			});
			doc.save(`${fileName}.pdf`);
		}
	}

	const downloadFile = ({ data, fileName, fileType }) => {
		const blob = new Blob([data], { type: fileType })
		const a = document.createElement('a')
		a.download = fileName
		a.href = window.URL.createObjectURL(blob)
		const clickEvt = new MouseEvent('click', {
			view: window,
			bubbles: true,
			cancelable: true,
		})
		a.dispatchEvent(clickEvt)
		a.remove()
	}

	return (
		<Dropdown style={{ fontSize: '14px' }}>
			<Dropdown.Toggle variant="secondary" disabled={isLoading}>
				{
					isLoading
						? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
						: <BsSave />
				}
			</Dropdown.Toggle>
			<Dropdown.Menu style={{ fontSize: '14px' }}>
				<Dropdown.Header>
					Export as:
				</Dropdown.Header>
				<Dropdown.Item onClick={() => handleDownload('CSV')}><BsFiletypeCsv /> CSV File</Dropdown.Item>
				<Dropdown.Item onClick={() => handleDownload('PDF')}><BsFiletypePdf /> PDF File</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default TicketsExport
