import React, { useRef, useEffect, useState } from "react"
import './profile-upload.css'
import { IoIosCamera } from "react-icons/io";
import { IoTrashOutline } from "react-icons/io5";
import { FaPen } from "react-icons/fa";
import { useSelector } from "react-redux"
import axios from "axios";
import { baseURL } from "../../../helpers/baseURL";
import Cookies from "js-cookie";
import { fetchUser } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';
import successAlert from "../../../helpers/successAlert"
import failAlert from "../../../helpers/failAlert";
import 'react-toastify/dist/ReactToastify.css';


const ProfileUpload = (props) => {
	const dispatch = useDispatch();
	const [showExtras, setShowExtras] = useState(false)
	const [mouseInside, setMouseInside] = useState(false)
	const fileInputRef = useRef(null);
	const dropzoneRef = useRef(null);
	const extraActionsRef = useRef(null);

	const file = useSelector(state => state.uploadFileReducerProfile.file)

	let profile_image = props.user.profile_image ? props.user.profile_image : 'avatar.png'
	let previewElementClass = props.file === undefined ? '' : 'preview';

	const handleClickOutside = (event) => {
		if (extraActionsRef.current && !extraActionsRef.current.contains(event.target)) {
			setShowExtras(false);
		}
	};

	const handleMouseEnter = () => {
		setMouseInside(true);
	};

	const handleMouseLeave = () => {
		setMouseInside(false);
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (dropzoneRef.current) {
			dropzoneRef.current.addEventListener("mouseenter", handleMouseEnter);
			dropzoneRef.current.addEventListener("mouseleave", handleMouseLeave);
			return () => {
				dropzoneRef.current.removeEventListener("mouseenter", handleMouseEnter);
				dropzoneRef.current.removeEventListener("mouseleave", handleMouseLeave);
			};
		}
	}, [showExtras]);

	const handleFileClick = () => {
		fileInputRef.current.click();
	};

	const onActionShow = () => {
		setShowExtras(!showExtras)
	}

	function handleChangeImage(event) {
		let fileInput = event.target;
		let file = fileInput.files[0];
		if (!file) { return; }
		const file_data = new FormData();
		file_data.append('file', file);
		axios({
			method: "POST",
			url: `${baseURL}media-library/image`,
			headers: {
				Authorization: `Bearer ${Cookies.get('access_token')}`,
			},
			data: file_data,
		}).then(res => {
			dispatch(fetchUser());
			successAlert('Image Uploaded.');
		}).catch(err => {
			failAlert(err.response?.data?.message || 'Upload Failed.');
		}).finally(() => {
			fileInput.value = '';
			setShowExtras(false);
		});
	}

	function handleDeleteImageProfile() {
		axios({
			method: "DELETE",
			url: `${baseURL}media-library/image`,
			headers: {
				Authorization: `Bearer ${Cookies.get('access_token')}`,
			}
		}).then(res => {
			dispatch(fetchUser());
			successAlert('Image Deleted.')
		}).catch(err => {
			failAlert(err.response?.data?.message || 'Action Failed.');
		}).finally(() => {
			setShowExtras(false);
		});
	}

	return (
		<div ref={dropzoneRef} className="dropzone-title">
			<h4>Profile Image</h4>
			<div className="dropzone">
				<div className="edit-pen-container" onClick={() => onActionShow()}>
					<FaPen />
				</div>
				<div ref={extraActionsRef} className={`extra-actions ${showExtras && mouseInside ? 'd-block' : 'd-none'}`}>
					<button className="btn" onClick={handleFileClick}>
						<input
							ref={fileInputRef}
							onChange={handleChangeImage}
							type="file"
							hidden
						/>
						<IoIosCamera />
						<span>Upload</span>
					</button>
					<button className="btn" onClick={handleDeleteImageProfile}>
						<IoTrashOutline />
						<span>Delete</span>
					</button>
				</div>
				<img src={file === undefined ? `${profile_image}` : file} className={`${previewElementClass} preview w-100 h-100`} />
			</div >
			<div className="dropzone-below-info">
				<h6>{props.user && props.user.first_name} {props.user && props.user.last_name}</h6>
				<p>{props.user && props.user.email}</p>
			</div>
		</div>
	)
}
export default ProfileUpload