import React, { useRef, useState, useEffect } from 'react';
import { Button, Form, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import { BsReply, BsExclamationCircle, BsFileEarmarkText, BsChevronDown, BsChevronUp } from 'react-icons/bs';

import styles from './Comment.module.css';
import Attachments from '../../../Attachments';
import CustomEditor from '../../../Editor';
import { baseURL } from "../../../../helpers/baseURL";
import { headers } from '../../../../helpers/Requests';
import { ucFirst } from "../../../../helpers/generic";
import { imageOption } from '../../../SelectComponents';
import { resetChangedColumns } from "../../../../redux/actions";

const Comment = ({ type, activeKey, ticket, onComplete }) => {
	const { id } = useParams();
    const dispatch = useDispatch();
	const editor = useRef('');
	const [attachments, setAttachments] = useState([]);
	const [expanded, setExpanded] = useState(true);
	const [isInvalid, setIsInvalid] = useState(false);
	const [to, setTo] = useState(null);
	const [requestResponse, setRequestResponse] = useState({
		isLoading: false,
		success: null,
		message: ''
	});
	const users = useSelector(state => state.usersReducer.users);

    const changedColumnsTaskSelector = useSelector(state => {
        return state?.UpdatesTaskReducer?.changedColumns;
    });
    // console.log('in the absence of space the world is a mess without you',type,changedColumnsTaskSelector);
    // console.log('in this endless space is nothing without you',type,changedColumnsTaskSelector);
    useEffect(() => {
        if(["notes"].some(key => changedColumnsTaskSelector[key]) && type === 'note'){
            onComplete && onComplete();
            dispatch(resetChangedColumns());
        }
    },[changedColumnsTaskSelector]);


	useEffect(() => {
		if (activeKey === type) setExpanded(true)
	}, [activeKey])

	useEffect(() => {
		if (requestResponse.success !== null) {
			const timer = setTimeout(() => {
				setRequestResponse({
					isLoading: false,
					success: null,
					message: ''
				});
			}, 5000);
			return () => clearTimeout(timer);
		}
	}, [requestResponse.success]);

	const formatUserOptions = (users) => {
		return users.map(row => ({
			value: row.email,
			label: ucFirst(row.name),
			image: row.profile_image,
			description: row.email
		}));
	};

	const handleFormSubmit = async () => {
		if (editor.current.getText().length === 0) {
			setIsInvalid(true);
			setRequestResponse({
				isLoading: false,
				success: false,
				message: 'Please enter a message!'
			});
			return;
		}
		if (type === 'forward' && !to) {
			setRequestResponse({
				isLoading: false,
				success: false,
				message: 'Please select a recipient!'
			});
			return;
		}

		const content = editor?.current?.getHTML();
		const endpoint = type === 'forward' ? 'forward' : 'comments';
		const data = { type, content, attachments, to: type === 'forward' ? to : null };

		setIsInvalid(false);
		setRequestResponse({ isLoading: true, success: null, message: '' });
		try {
			const response = await axios.post(`${baseURL}${endpoint}/${id}`, data, headers);
			setRequestResponse({
				isLoading: false,
				success: true,
				message: response.data?.message ?? 'Posted successfully!'
			});
			editor.current.reset();
			setAttachments([]);
			onComplete();
		} catch (error) {
			const errorMessage = error?.response?.data?.message ?? 'Something went wrong!';
			setRequestResponse({
				isLoading: false,
				success: false,
				message: errorMessage
			});
		}
	};

	if (type === 'reply' && !ticket?.created_by?.email) {
		return <Alert variant='danger' className='mt-3'>You cannot reply to this ticket since its origin is unknown.</Alert>
	}

	return (
		<div className={styles.container + (expanded ? ` ${styles.expanded}` : '')}>
			{requestResponse.isLoading && <Alert variant="info">Loading...</Alert>}
			{requestResponse.success !== null && <Alert variant={requestResponse.success ? "success" : "danger"}>{requestResponse.message}</Alert>}
			<Button className={styles.tabToggle} variant="link" onClick={() => setExpanded(prev => !prev)}>{expanded ? <BsChevronUp /> : <BsChevronDown />}</Button>
			<Form className={styles.form}>
				{type === 'note' && <div className={styles.header}><BsExclamationCircle className='me-2' /> Notes are marked as <span className='px-1'>private</span> and can be seen only by staff members!</div>}
				{type === 'reply' && <div className={styles.header}>To: <u className='ms-2'>{ticket.created_by.email}</u></div>}
				{type === 'forward' && (
					<>
						<div className={styles.header}><BsExclamationCircle className='me-2' /> This forwarded message will remain <span className='px-1'>private</span> and not be displayed in the conversation.</div>
						<div className="d-flex align-items-center mt-2">
							<span className="me-2">To:</span>
							<CreatableSelect
								isClearable
								onChange={(option) => setTo(option ? option.value : '')}
								options={formatUserOptions(users)}
								components={{ Option: imageOption }}
								className="flex-grow-1"
								classNamePrefix="select-component"
								placeholder="Type email address or select a recipient"
								value={to ? { label: to, value: to } : null}
								formatCreateLabel={userInput => `Forward to: ${userInput}`}
							/>
						</div>
					</>
				)}
				<Form.Group controlId="form.content" className='mt-2'>
					<CustomEditor id={'editor-' + type} placeholder="Please add your text here" isInvalid={isInvalid} ref={editor} name="content" />
				</Form.Group>
				<Col className='d-flex justify-content-between mt-2'>
					<Attachments attachments={attachments} setAttachments={setAttachments} showAll={false} />
					<Button onClick={handleFormSubmit} disabled={requestResponse.isLoading}>
						{type === 'reply' && <><BsReply className="me-1 mb-1" size={15} /> Post Reply</>}
						{type === 'forward' && <><BsReply className="me-1 mb-1 flip-icon" size={15} /> Forward</>}
						{type === 'note' && <><BsFileEarmarkText className="me-1 mb-1" size={15} /> Save Note</>}
					</Button>
				</Col>
			</Form>
		</div>
	);
};

export default Comment;
