import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, } from "react-bootstrap";
import { BsPencil, BsTrash3 } from "react-icons/bs";
import ItemModal from "./ItemModal";
import { useDispatch, useSelector } from "react-redux";
import { getTags, getTypes, getPriorities } from "../redux/actions";
import { showDeleteConfirm } from "./ItemDelete";
import plus from "../../css/img/plus.svg"

const LabelsView = ({ itemType }) => {
	const dispatch = useDispatch()
	const [showItemModal, setShowItemModal] = useState(false)
	const [selectedLabel, setSelectedLabel] = useState(null)

	const items = useSelector(state => itemType == 'tags' ? state.tagsReducer.tags : itemType == 'types' ? state.typesReducer.types : state.prioritiesReducer.priorities)
	const isLoading = useSelector(state => itemType == 'tags' ? state.tagsReducer.loading : itemType == 'types' ? state.typesReducer.loading : state.prioritiesReducer.loading)
	const single = itemType == 'tags' ? 'Tag' : itemType == 'types' ? 'Type' : 'Priority';
	const plural = itemType == 'tags' ? 'Tags' : itemType == 'types' ? 'Types' : 'Priorities';
	const prefixAPI = itemType == 'tags' ? 'tag' : itemType == 'types' ? 'ticket-type' : 'ticket-priority';
	const reduxAction = itemType == 'tags' ? getTags : itemType == 'types' ? getTypes : getPriorities;

	const labelModal = (title, item = {}) => {
		setSelectedLabel({ title, item })
		setShowItemModal(true)
	}

	const reduxRefresh = () => {
		dispatch(reduxAction())
	};

	useEffect(() => {
		reduxRefresh()
	}, [])

	return (
		<>
			<div className="section-container pt-3">
				<Row className="align-items-center">
					<Col xs={12} md={6} lg={8}>
						<div className="ticket_sys_header">
							<h4 className="table-name">Ticket {plural}</h4>
						</div>
					</Col>
					<Col xs={12} md={6} lg={4}>
						<button type="button" className="btn adm-addNew" onClick={() => labelModal('Add')}>
							<img src={plus} />
							Add {single}
						</button>
					</Col>
				</Row>
				<Row className="mt-3">
					{isLoading ? <Col xs={12}><div className="labels-container-div justify-content-center"><Spinner animation="border" role="status" /></div></Col> : (
						items?.length > 0 ?
							items.map(item => {
								return (
									<Col md={12} lg={6} xl={4} key={item.id} className="mb-2">
										<div className="labels-container-div">
											<div className="label-color" style={{ backgroundColor: item.color ? item.color : '#c1c1c1' }}></div>
											<div className="label-name">{item.name}{item.order && <span className="label-order">#{item.order}</span>}</div>
											<div className="actions-icons-holder t-edit" onClick={() => labelModal('Edit', item)}>
												<BsPencil />
											</div>
											<div className="actions-icons-holder ml-3 t-delete" onClick={() => showDeleteConfirm(item, single, prefixAPI, reduxRefresh, dispatch)}>
												<BsTrash3 />
											</div>
										</div>
									</Col>
								)
							}) : <Col xs={12}><div className="labels-container-div justify-content-center">No Items Found</div></Col>
					)}
				</Row>
			</div>
			{
				showItemModal && <ItemModal currentState={selectedLabel} itemType={itemType} single={single} prefixAPI={prefixAPI} reduxRefresh={reduxRefresh} closeModal={() => setShowItemModal(false)} />
			}
		</>
	)
}
export default LabelsView
