import React from "react"
import FilterComponent from "../Filter/FilterComponent";
import {Col, Row} from "react-bootstrap";
import CustomerHelped from "./CusotmersHelped";
import TicketsAssigned from "./TicketsAssigned";
import AverageReplies from "./AverageReplies";
import Agents from "./Agents";
import MainReport from "../MainReport";
import {useSelector} from "react-redux";
import {fetchTeamReport} from "../../../../redux/actions";

const Team = () => {
    const dates = useSelector(state => state.getDatesReducer.dates)
    const report = useSelector(state => state.TeamReducerReport.data)

    return(
        <MainReport title="Customers Helped"  reqFrom="team">
            <Row>
                <Col xs={12} sm={9}>
                    {/*<Row>*/}
                    {/*    <Col xs={12}>*/}
                    {/*        <CustomerHelped/>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row>
                        <Col xs={12} sm={6}>
                            <TicketsAssigned dates={dates} report={report}/>
                        </Col>
                        <Col xs={12} sm={6}>
                            <AverageReplies dates={dates} report={report}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Agents report={report}/>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={3}>
                    <FilterComponent url={fetchTeamReport}/>
                </Col>
            </Row>
        </MainReport>
    )
}
export default Team
