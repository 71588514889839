import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {getReportTags, getTags} from "../../../../redux/actions";
import {IoIosClose} from "react-icons/io";
import Select, {components} from "react-select";
import {CirclePicker, SketchPicker} from "react-color";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import {IoSearchOutline} from "react-icons/io5";

const Tags = () => {
    const [selectedTags, setSelectedTags] = useState([])
    const [value, setValue] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getTags())
    }, [])
    useEffect(() => {
        dispatch(getReportTags(value.toString()))
    },[value])
    const tags = useSelector(state => state.tagsReducer.tags)

    let tagsSelect = tags && tags.map(tag => {
        return  { value: tag.id, label: tag.name, color: tag.color }
    })

    const selectTag = (e) => {
        let check = selectedTags.some(tag => tag.value === e.value)
        if(check){
            return false
        }
        setSelectedTags([...selectedTags, e])
        setValue([...value, e.value])
    }

    const deSelect = (tag) => {
        setSelectedTags(selectedTags.filter(tagObj => tagObj !== tag))
        setValue(value.filter(tagObj => tagObj !== tag.value))
    }

    const style = {
        ul:{
            listStyle: "none",
            padding: 0,
            alignItems: "center",
            flexWrap: "wrap"
        },
        remove: {
            cursor: "pointer"
        }
    }

    const Option = props => {
        return (
            <components.Option {...props}>
                <div className="d-flex align-items-center">
                    <div className="dotTag" style={{background: props.data.color ? props.data.color : '#f1f1f1'}}></div>
                    <div style={{ display: "inline-block"}}>
                        {props.data.label}
                    </div>
                </div>
            </components.Option>
        );
    };

    return(
        <div>
            <ul style={style.ul}>
                {selectedTags.length > 0 && selectedTags.map(tag => (
                    <li key={`agentFiler-${tag.value}`} style={
                        {
                            borderRadius: "5px",
                            padding: "5px 10px",
                            fontSize: "14px",
                            fontWeight: 300,
                            fontFamily: "Poppins",
                            marginRight: "10px",
                            marginBottom: "10px",
                            backgroundColor: tag.color ? tag.color : '#bfb7b7',
                            color: '#fff',
                            width: "fit-content"
                        }
                    }>
                        <IoIosClose style={style.remove} onClick={() => deSelect(tag)}/>
                        {tag.label}
                    </li>
                ))}
            </ul>
            <div className="position-relative search-input-style" style={{marginBottom: "15px"}}>
                <IoSearchOutline style={{zIndex: 1}}/>
                <Select
                    value={selectedTags.length === 0 ? '' : selectedTags}
                    placeholder="Search Tag"
                    name="user-select"
                    id="ticketSelect-agents"
                    options={tagsSelect}
                    components={
                        {Option: Option,}
                    }
                    onChange={(e) => selectTag(e)}
                />
            </div>
        </div>
    )
}
export default Tags
