import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { baseURL } from "../../helpers/baseURL";
import axios from 'axios';
import { headers } from '../../helpers/Requests';
import { Alert } from 'react-bootstrap';

const WorkSpaceEntry = ({ showModal, closeModal, onAdd, onEdit, isEditing = false, item = {} }) => {

	const [name, setName] = useState('');
	const [title, setTitle] = useState('');
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handleNameChange = (e) => setName(e.target.value);
	const handleTitleChange = (e) => setTitle(e.target.value);

	useEffect(() => {
		setError('')
	}, [showModal]);

	useEffect(() => {
		if (isEditing) {
			setName(item.name);
			setTitle(item.title);
		}
	}, [isEditing, item]);

	const handleCreateWorkspace = async (e) => {
		e.preventDefault();
		setError('');

		if (!name.trim()) {
			setError('Workspace name cannot be empty.');
			return;
		}

		if (!title.trim()) {
			setError('Your title cannot be empty.');
			return;
		}

		setIsLoading(true);
		const url = isEditing ? `${baseURL}workspace/${item.id}` : `${baseURL}workspace`;
		const method = isEditing ? 'put' : 'post';
		const request = axios[method](url, { name, title }, headers);

		request.then(response => {
			if (isEditing) {
				onEdit()
			} else {
				onAdd(response.data.data);
			}
			closeModal();
			setTitle('');
			setName('');
		}).catch(error => {
			if (error.response && error.response.data) {
				if (error.response.data.errors) {
					setError(error.response.data.errors);
				}
				if (error.response.data.message) {
					setError(error.response.data.message);
				}
			} else {
				console.error(error);
				setError('Something went wrong. Please try again later.');
			}
		}).finally(() => setIsLoading(false));
	};

	return (
		<Modal show={showModal} onHide={closeModal} style={{ zIndex: 1055, pointerEvents: 'all' }}>
			<Form onSubmit={handleCreateWorkspace}>
				<Modal.Header closeButton>
					<Modal.Title>{ isEditing ? "Edit Workspace" : "Create Workspace" }</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{error && <Alert variant="danger">{error}</Alert>}
					<Form.Group controlId="workspaceTitle" className='mb-3'>
						<Form.Label>Workspace Title</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter workspace title"
							value={name}
							onChange={handleNameChange}
							isInvalid={!!error}
						/>
					</Form.Group>
					<Form.Group controlId="workspaceTitle" className='mb-3'>
						<Form.Label>Your Title</Form.Label>
						<Form.Control
							type="text"
							placeholder="e.g. Project Manager"
							value={title}
							onChange={handleTitleChange}
							isInvalid={!!error}
						/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={closeModal}>
						Close
					</Button>
					<Button variant="primary" type="submit">
						{isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Save'}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default WorkSpaceEntry;
