import React from 'react';
import NoteActions from './NoteActions';
import ConversationAttachments from './ConversationAttachments';
import styles from './Conversations.module.css';
import { getUserProfileImage, createMarkup } from '../../../../helpers/generic';

const UserProfile = ({ user }) => (
    <div className={styles.user}>
        <img src={getUserProfileImage(user)} alt="profile" style={{ width: 50, height: 50, borderRadius: '50%' }} />
        <div>{user.name}</div>
    </div>
);

const ConversationContent = ({ content, attachments, date }) => (
    <div className={styles.wrapper}>
        <div className={styles.content} dangerouslySetInnerHTML={createMarkup(content)} />
        <ConversationAttachments attachments={attachments} />
        <div className={styles.date}>{new Date(date).toLocaleString()}</div>
    </div>
);

const TicketHeader = ({ ticket }) => (
    <div className="d-flex flex-column align-items-start">
        <b>{ticket?.title}</b>
        <span className='opacity-75'>{`#${ticket?.ticket_id.toString()}`}</span>
    </div>
);

const Conversations = ({ ticket, type, onChange = () => { } }) => {
    const renderConversations = (conversations, isNote) => (
        conversations.length > 0 ? conversations.map((conversation) => (
            <div
                key={conversation.id}
                className={styles.boxContainer}
                data-role={conversation.role === 'customer' ? 'customer' : 'agent'}
                data-type={isNote ? 'note' : 'reply'}
            >
                <div className={styles.header}>
                    <UserProfile user={conversation.user} />
                    <ConversationContent content={conversation.content} attachments={conversation.attachments || []} date={conversation.created_at} />
                    {isNote && <NoteActions note={conversation} onChange={onChange} />}
                </div>
            </div>
        )) : <div className='d-flex justify-content-center my-2'><div className='text-muted'>No {type} yet</div></div>
    );

    return (
        <div className={styles.container} data-type={type}>
            {type === 'ticket' ? (
                <div className={styles.boxContainer}>
                    <div className={styles.header}>
                        {ticket?.created_by && <UserProfile user={ticket.created_by} />}
                        <div className={styles.wrapper}>
                            <TicketHeader ticket={ticket} />
                            <ConversationContent content={ticket?.description} attachments={ticket?.attachments} date={ticket?.created_at} />
                        </div>
                    </div>
                </div>
            ) : renderConversations(ticket[type], type === 'notes')}
        </div>
    );
};

export default Conversations;