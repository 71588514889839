import React, { useEffect, useState } from "react"
import "./pricing.css"
import MainPages from "./MainPages";
import bluetick from  "../main/images/bluetick.svg"
import orangetick from  "../main/images/orangetick.svg"
import cross from "../main/images/cross.svg"
import left from  "../main/images/pricing-calculator-left.svg"
import right from  "../main/images/pricing-calculator-right.png"
import { fetchUser } from '../js/redux/actions/index'
import { useDispatch, useSelector } from "react-redux";
import SignUp from "./SignUp";

const Pricing = () => {
    const [clicked, setClicked] = useState(false)
    const [additionalUsers, setAdditionalUsers] = useState(5)

    const dispatch = useDispatch()
    useEffect(() => {
        if(clicked){
            dispatch(fetchUser())
        }
    }, [clicked])
    const checkUser = () => {
        setClicked(true)
    }
    const updateChange = (input) => {
        let width = (input.value.length + 1) * 10
        let money = input.value * 5
        input.style.width = `${width}px`
        setAdditionalUsers(money.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
    }
    const user =  useSelector(state => state.userReducer.user);
    return(
        <MainPages>
            <section className="pricing-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-md-3">
                            <div className="pricing-div">
                                <h3>Pricing plans for <span>everyone</span></h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 d-flex">
                            <div className="pricing-box card card-body flex-fill">
                                <h4>Free</h4>
                                <p>Get started with our free plan:</p>
                                <ul>
                                    <li><img src={bluetick} /> 1 User</li>
                                    <li><img src={bluetick} /> 5 Tickets </li>
                                    <li><img src={bluetick} /> 1 Department</li>
                                    <li><img src={bluetick} /> 1 Incoming Email</li>
                                    <li><img src={bluetick} /> $5/additional user</li>
                                    <li><img src={bluetick} /> 200 MB File Storage</li>
                                    <li><img src={cross} /> Limited Support</li>
                                    <li><img src={cross} /> No Connection with Claritask</li>
                                </ul>
                                <a href="#"
                                    className="pricing-submit">
                                        Select
                                        <span>
                                            $0<i> mo</i>
                                        </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex">
                            <div className="pricing-box standart-box card card-body flex-fill">
                                <span className="most-popular">Most popular</span>
                                <h4>Standard</h4>
                                <p>Elevate collaboration with advanced ticketing:</p>
                                <ul>
                                    <li><img src={orangetick} />3 User</li>
                                    <li><img src={orangetick} />1000 Tickets</li>
                                    <li><img src={orangetick} />5 Departments</li>
                                    <li><img src={orangetick} />3 Incoming Email</li>
                                    <li><img src={orangetick} />$5/additional user</li>
                                    <li><img src={orangetick}/>2 GB File Storage</li>
                                    <li><img src={orangetick} />Full Support</li>
                                    <li><img src={orangetick} />Connection with Claritask</li>
                                </ul>
                                <a href={user &&`${process.env.MIX_APP_IPAYMER_URL}/portal?cus=${user.ipaymer_customer_id}&pl=Cti-1000&returnUrl=${process.env.MIX_APP_RETURN_URL}`}
                                    className="pricing-submit" onClick={() => checkUser()}>
                                    Select <span>$10<i> mo</i></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex">
                            <div className="pricing-box card card-body flex-fill">
                                <h4>Premium</h4>
                                <p>Unleash the power of enterprise-level ticket management:</p>
                                <ul>
                                    <li><img src={bluetick} />6 Users</li>
                                    <li><img src={bluetick} />2500 Tickets</li>
                                    <li><img src={bluetick} />12 Departments</li>
                                    <li><img src={bluetick} />10 Incoming Email</li>
                                    <li><img src={bluetick} />$5/additional user</li>
                                    <li><img src={bluetick} />4 GB File Storage</li>
                                    <li><img src={bluetick} />Full Support</li>
                                    <li><img src={bluetick} />Connection with Claritask</li>
                                </ul>
                                <a  href={user &&`${process.env.MIX_APP_IPAYMER_URL}/portal?cus=${user.ipaymer_customer_id}&pl=Cti-1001&returnUrl=${process.env.MIX_APP_RETURN_URL}`}
                                    className="pricing-submit" onClick={() => checkUser()}>
                                        Select <span>$20<i> mo</i></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 d-flex">
                            <div className="pricing-box card card-body flex-fill">
                                <h4>Enterprise</h4>
                                <div className="contact-us-enterprise">
                                    <p>Ideal for large companies/product teams</p>
                                    <h6>Let's Talk</h6>
                                    <p>Our team will be glad to assist</p>
                                </div>
                                <a href="https://kore.co/contact" className="pricing-submit text-center">Contact Us</a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="pricing-box pricing-calculator">
                                <div className="pricing-calculator-div">
                                    <h3>Pricing Calculator <span>${additionalUsers}/<i>month</i></span></h3>
                                    <p>Number of additional users</p>
                                    <div className="type-user-num">
                                        <input type="number" onKeyUp={(e) => updateChange(e.target)} defaultValue={1}/>
                                        <span>users</span>
                                    </div>
                                </div>
                                <img src={left} className="pricing-calculator-left"/>
                                <img src={right} className="pricing-calculator-right"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SignUp/>
        </MainPages>
    )
}
export default Pricing
