export  const reportsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GENERATE_REPORTS':
            return{
                ...state,
                title: action.payload.type,
                data: action.payload.users,
                loading: action.loading ?? false
            }
        case 'GENERATE_REPORTS_CLEAR':
            return{
                title: 'Filter Type',
                data: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const OverviewReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_OVERVIEW' :
            return{
                ...state,
                data: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}
export const ActivityReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_ACTIVITY' :
            return{
                ...state,
                data: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}

export const TicketsReducerReport = (state = {}, action) => {
    switch (action.type) {
        case 'GET_TICKETS_REPORT' :
            return{
                ...state,
                data: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}

export const TeamReducerReport = (state = {}, action) => {
    switch (action.type) {
        case 'GET_TEAM_REPORT' :
            return{
                ...state,
                data: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}

export const TimeReducerReport = (state = {}, action) => {
    switch (action.type) {
        case 'GET_TIME_REPORT' :
            return{
                ...state,
                data: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}
