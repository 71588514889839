export const generalReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_GENERAL_SETTINGS' :
            return{
                ...state,
                general_settings: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}

