export const ticketsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_TICKETS':
            return{
                ...state,
                tickets: action.payload.tickets,
                total: action.payload.total,
                loading: action.loading ?? false
            }
        case 'SEARCH':
            return {
                ...state,
                searched: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}

export const detailsTicket = (state = [], action) =>{
    switch (action.type) {
        case 'GET_DETAIL_TICKET':
            return{
                ...state,
                details: action.payload,
                loading: action.loading ?? false
            }
        case 'CLEAR':
            return{
                ...state,
                details: undefined,
                loading: action.loading ?? false
            }
        default:
            return {
                ...state,
            }
    }
}

export const newTickets = (state = [], action) => {
    switch (action.type) {
        case 'GET_NOTIFICATION_TICKET':
            return{
                ...state,
                new_tickets: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            };
    }
}

export const newComments = (state = [], action) => {
    switch (action.type) {
        case 'GET_COMMENTS':
            return{
                ...state,
                comments: action.payload,
                loading: action.loading ?? false
            }
        default:
            return {
                ...state
            };
    }
}

