import { dateRange } from "../../helpers/date-range";

export const getDatesReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_DATE':
            return {
                ...state,
                dates: dateRange(action.payload),
                date: action.payload,
                loading: action.loading ?? false
            }
        default:
            return state
    }
}
