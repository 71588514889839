import React from "react"
import FilterComponent from "./Filter/FilterComponent";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import avatar from "../../../../css/img/avatar.png"
import MainReport from "./MainReport";
import {useSelector} from "react-redux";
import moment from "moment";
import {fetchActivity} from "../../../redux/actions";

const Activity = () => {
    const dates = useSelector(state => state.getDatesReducer.dates)
    const activities = useSelector(state => state.ActivityReducer.data)

    return(
        <MainReport title="Activity" reqFrom="activity">
            <Row>
                <Col sm={12} xl={9}>
                    <div className="report-chart-holder pb-4">
                        <div className="activity-panel mt-0" style={{minHeight: "calc(100vh - 285px)"}}>
                            <div className="activity-panel-item text-left">
                                {activities ?
                                    Object.keys(activities).map((act, index) => {
                                        return(
                                            <div className="report-activities-holder" key={index}>
                                                <h3>{act}</h3>
                                                <div className="circle-activity">
                                                    <span></span>
                                                </div>
                                                <ul className="p-0">
                                                    {activities[act].map((ac) => {
                                                        return(
                                                            <li key={ac.id}>
                                                                <div className="ml-0 activity-user-img">
                                                                    <img src={avatar} alt=""/>
                                                                </div>
                                                                <div>
                                                                    <h4>{ac.causer.name && ac.causer.name}</h4>
                                                                    <span>{moment(ac.created_at).format('MMMM Do YYYY, [at] h:mm')}</span>
                                                                </div>
                                                                <div className="report-activity-container">
                                                                    <Link to={`/ticket/${ac.id}`}>#{ac.properties.name}</Link>
                                                                    {/* <p>{ac.properties.description}</p> */}
                                                                    <p dangerouslySetInnerHTML={{ __html: ac.properties.description}}></p>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    }) : 'Filter to see some activities'}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={12} xl={3}>
                    <FilterComponent url={fetchActivity}/>
                </Col>
            </Row>
        </MainReport>
    )
}
export default Activity
