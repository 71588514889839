import moment from "moment";
export function dateRange(dates){
    let startDate = moment(dates[0]).format("MM/DD/YYYY")
    let endDate = moment(dates[1]).format("MM/DD/YYYY")
    let betweenDates = []
    let now = moment(startDate);
    while(now.isSameOrBefore(endDate)){
        betweenDates.push(now.format('MMM DD'));
        now.add(1, 'days')
    }
    return betweenDates
}
