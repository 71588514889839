import React, {Component} from 'react'


class CustomToggle extends Component{
    constructor(props) {
        super(props);
        this.state = {
            toggle: true,
            active: false
        }
    }

    onChangeToggle = () => {
        this.setState({toggle: !this.state.toggle})
        this.setState({active: !this.state.active})
    }

    render(){
        const stateOfToggle = this.state.toggle === true ? this.props.on : this.props.off
        return(
            <>
                <label htmlFor={this.props.id} className="add-checked-mark" style={{order: `${this.props.second}`}}>
                    <span className="checkcircle"></span>
                </label>
                <span className="mx-3" style={{order: `${this.props.first}`}}>{stateOfToggle}</span>
            </>
        )
    }
}
export default CustomToggle
