import React from "react"
import shape3 from "./images/shape3.png"
import {Link} from "react-router-dom"

const HappyCustomers = () => {
    return(
        <>
            <section className="happy-customers">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="happy-customers-div">
                                {/*<h4>EXCELLENT SUPPORT</h4>*/}
                                <h3>Build a strong relationship with your client</h3>
                                <Link to="/signup">Sign up</Link>
                            </div>
                        </div>
                    </div>
                    <img src={shape3}/>
                </div>
            </section>
        </>
    )
}
export default HappyCustomers
