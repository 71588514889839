import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { BsInfoCircle } from 'react-icons/bs';
import { ucFirst } from '../../../helpers/generic';

const TicketCategoryInfo = ({ category }) => {

	const [description, setDescription] = useState('');
	const statuses = useSelector(state => state.statusesReducer.statuses);

	const findDescription = (category, statuses) => {
		if (category == 'unassigned') {
			return 'Tickets that are not assigned to any user.';
		} else if (category == 'assigned') {
			return 'Tickets that are assigned to a user.';
		} else if (category == 'my') {
			return 'Tickets that are assigned to you.';
		} else if (category == 'deleted') {
			return 'Tickets that are deleted.';
		}
		const status = statuses.find(status => status.name === category);
		return status?.description ?? '';
	}

	useEffect(() => {
		setDescription(findDescription(category, statuses));
	}, [category, statuses]);

	const iconStyle = {
		fontSize: '14px',
		marginLeft: '5px',
		cursor: 'pointer',
		verticalAlign: 'text-top'
	};

	return (
		<div>
			{ucFirst(category)} Tickets
			{description !== '' && <OverlayTrigger overlay={<Tooltip>{description}</Tooltip>} placement='bottom'>
				<span style={iconStyle}><BsInfoCircle /></span>
			</OverlayTrigger>}
		</div>
	)
}

export default TicketCategoryInfo
