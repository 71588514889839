import React, {useEffect} from 'react';
import { Link, withRouter } from 'react-router-dom';
import img404 from "../../../css/img/404.svg"
import claritick from "../../../css/img/claritick-img.svg"
import claritickShape1 from "../../../css/img/claritick-shape-1.svg"
import claritickShape2 from "../../../css/img/claritick-shape-2.svg"
import claritickIcon1 from "../../../css/img/claritick-icon-1.svg"
import claritickIcon2 from "../../../css/img/claritick-icon-2.svg"
import claritickIcon3 from "../../../css/img/claritick-icon-3.svg"

const ServerError = () => {
    return(
        <div style={{position: "fixed", top: "0", right: "0", left: "0", bottom: "0", backgroundColor: "#fff", zIndex: 99999}}>
            <section className="claritick-404">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 claritick-align-center">
                            <div className="claritick-404-div">
                                <img src={img404}/>
                                <h3>INTERNAL SERVER ERROR</h3>
                                <Link to="/dashboard">Go back</Link>
                            </div>
                        </div>
                        <div className="col-md-6 offset-md-1">
                            <div className="claritick-404-div">
                                <img src={claritick}/>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={claritickShape1} className="claritick-shape-1"/>
                <img src={claritickShape2} className="claritick-shape-2"/>
                <img src={claritickIcon1} className="claritick-icon-1"/>
                <img src={claritickIcon2} className="claritick-icon-2"/>
                <img src={claritickIcon3} className="claritick-icon-3"/>
            </section>
        </div>
    )
}
export default ServerError;
