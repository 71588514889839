import React, {useRef, useEffect, useState} from "react"
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { formatDate } from "./formatDate";
import { IoCalendarNumberOutline } from "react-icons/io5";

const style= {
    window:  {
        boxShadow: '0 6px 15px rgba(37,49,60,.16)',
        fontFamily: "Poppins-Regular",
        position: 'absolute',
        right: 0,
        top: "45px",
        zIndex: 9999
    },
    wrapper: {
        position: 'relative'
    },
    display: {
        display: "flex",
        alignItems: "center",
        color: "#1675e0",
        justifyContent: "flex-end",
        cursor: "pointer"
    },
    icon: {
        fontSize: "16px",
        marginRight: "5px",
        color: "grey"
    },
    space: {
        marginRight: "5px"
    }
}

const DateRange = ({handleDateChange, startDate, endDate}) => {
    const [showRange, setShowRange] = useState(false)
    const [selectionRange, setSelectionRange] =  useState(
        {
            startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            endDate: new Date,
            key: 'selection'
        }
    );

    useEffect(() => {
        if(!startDate || !endDate){
            return
        }
        setSelectionRange(prevObj => ({...prevObj, startDate, endDate}))
    }, [startDate, endDate])

    const wrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowRange(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);
    
    const handleChange = (item) => {
        console.log(item)
        setSelectionRange(item.selection)
        handleDateChange([item.selection.startDate, item.selection.endDate])
    }

    return(
        <div style={style.wrapper} ref={wrapperRef}>
            <div style={style.display} onClick={() => setShowRange(!showRange)}>
                {
                    selectionRange.startDate ?
                    <>
                        <IoCalendarNumberOutline style={style.icon}/>
                        <div style={style.space}>{formatDate(selectionRange.startDate.toString())}</div> 
                        <span style={style.space}>~</span> 
                        <div>{selectionRange.endDate  ? formatDate(selectionRange.endDate.toString()) : 'to'}</div>
                    </>
                    : <span style={{width: "100px"}}>All Time</span>
                }
                
            </div>
            {showRange &&
                <div className="date-range-picker" style={style.window}>
                    <DateRangePicker   
                        startDatePlaceholder="from"
                        endDatePlaceholder="to"
                        editableDateInputs={true}
                        ranges={[selectionRange]}
                        onChange={handleChange}
                        moveRangeOnFirstSelection={false}
                        direction={"vertical"}
                    />
                </div>
            }
        </div>
    )
}
export default DateRange