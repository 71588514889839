import React, {useEffect, useState} from 'react'
import NavBar from "./NavBar";
import Sidebar from "./Sidebar/Sidebar";
import {useSelector} from "react-redux";

const MainContainer = ({children}) => {
	const loggedIn = useSelector(state => state.loggedReducer);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    // const excludeSideBar = excludeSideBar || false;
	const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
        localStorage.setItem('sidebarCollapsed', !isSidebarCollapsed);
    };
	useEffect(() => {
        const savedState = localStorage.getItem('sidebarCollapsed');
        if (savedState !== null) {
            setIsSidebarCollapsed(savedState === 'true');
        }
    }, []);
	return(
		<div className="dashBoard-container">
			<NavBar isLogged={loggedIn} collapsed={isSidebarCollapsed}/>
			<div className={`main-container ${isSidebarCollapsed ? 'collapsed' : ''}`}>
				{children}
			</div>
            <Sidebar toggleSidebar={toggleSidebar} isCollapsed={isSidebarCollapsed} />
		</div>
	)
}
export default MainContainer
