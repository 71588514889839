let initialState = {tasks: []}

export const allTasks = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TASKS':
            return{
                ...state,
                tasks: action.payload,
                loading: action.loading ?? false
            }
        case 'DELETE_TASK':
            const id = action.payload;
            return{
                ...state,
                tasks: {tasks: state.tasks.tasks.filter(task => task.task_id != id)},
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}
