export  const subscriptionReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_SUBSCRIPTION':
            return{
                ...state,
                data: action.payload,
                loading: action.loading ?? false
            }
        default:
            return{
                ...state
            }
    }
}
