export const imagesReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_IMAGES':
            return {
                ...state,
                images: action.payload,
                loading: action.loading ?? false
            }
        case 'GET_IMAGES_STORAGE':
            return {
                ...state,
                storage: action.payload,
                loading: action.loading ?? false
            }
        default:
            return state
    }
}
