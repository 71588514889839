import React, {useState} from "react"
import {Col, Row} from "react-bootstrap";
import claritask from "../../../css/img/claritask2000 px.svg";

const AllApps = ({setDetailed, setRoot, change}) => {
    const [active, setActive] = useState(1)
    const list = [
        {id: 1, text: "All apps"},
    ]

    const apps = [
        {id: 1, logo: claritask, name: "Claritask", description: "Clarity in managing projects"},
    ]
    const showDetailedApp = (app) => {
        change("")
        setDetailed(app)
        setRoot(app.name)
    }
    return(
        <Row className="m-0">
            <Col sm={12} md={4} lg={3} xl={3} className="pl-0">
                <div className="category-container-list">
                    <ul>
                        {list.map(li => (
                            <li key={`list${li.id}`} onClick={() => setActive(li.id)} className={active === li.id ? "active" : ""}>
                                {li.text}
                            </li>
                        ))}
                    </ul>
                </div>
            </Col>
            <Col sm={12} md={8} lg={8} xl={9}>
                <div className="apps_container">
                    <div className="row">
                        {apps.map(app => (
                            <Col sm={12} lg={6} key={`app${app.id}`}>
                                <div className="each-appContainer mb-2" onClick={() => showDetailedApp(app)}>
                                    <div className="app-logo">
                                        <img src={app.logo} alt="logo"/>
                                    </div>
                                    <div className="app-infos">
                                        <h4>{app.name}</h4>
                                        <p>{app.description}</p>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </div>
                </div>
            </Col>
        </Row>
    )
}
export default AllApps
