import Cookies from "js-cookie";
const token = Cookies.get('access_token')
const status = token === undefined ? false : token
export const loggedReducer = (state = status, action) => {
    switch (action.type) {
        case 'LOGGED':
            return {
                state,
            }
        default:
            return state;
    }
}
